import React, {useCallback, useEffect, useRef, useState} from "react";
import {Button, Col, Form, Image, Row} from "react-bootstrap";
import {withTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as ReactGA from "react-ga";
import {Formik} from "formik";
import {mixed, object, setLocale, string} from "yup";
import {profilePictureActions} from "../../actions/profilePicture";
import {userActions} from "../../actions/user";
import {URL} from "../../constants/API";
import ReactCrop from 'react-image-crop';
import DeleteModal from "../Modal/DeleteModal";
import PropTypes from "prop-types";
import Header from "../User/PrivateProfile/Header";
import {
    BillingInput,
    ButtonContainer,
    EditImgButtons,
    EditProfileImg,
    FormRegularRow,
    ImgEditor,
    SettingInput,
    SettingLabel,
} from "../../styled-components/S_Setting";
import {PrimaryButton} from "../../styled-components/Buttons";
import ValidateFromApi from "../ValidateFromApi";
import {Terms} from "../../styled-components/S_Registration";

const EditProfile = ({t, i18n, user, errorState}) => {
    const dispatch = useDispatch();

    const profilePicture = useSelector(state => state.profilePicture);

    const FILE_SIZE = 4500 * 1024;//todo calculate size approx 5m
    const SUPPORTED_FORMATS = [
        "image/jpg",
        "image/jpeg",
        "image/gif",
        "image/png"
    ];

    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const onLoad = useCallback((img) => {
        imgRef.current = img;
    }, []);

    useEffect(() => {
        if (!profilePicture.completedCrop || !previewCanvasRef.current || !imgRef.current) {
            return;
        }

        const image = imgRef.current;
        const canvas = previewCanvasRef.current;
        const crop = profilePicture.completedCrop;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext("2d");

        canvas.width = crop.width * (300 / crop.width);
        canvas.height = crop.height * (300 / crop.height);

        ctx.setTransform(300 / crop.width, 0, 0, 300 / crop.height, 0, 0);
        ctx.imageSmoothingQuality = "high";

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        canvas.toDataURL();
    }, [profilePicture.completedCrop]);

    setLocale({
        string:
            {url: 'This must be a valid URL (https://site.com)'}
    });

    const schema = object({
        email: string().min(2).max(50).email('Email is invalid').required('Email is required'),
        username: string().min(2).max(50).required('Username is required'),
        bio: string(),
        file: mixed().when('validateImage', {
            is: true,
            then: mixed()
                .required("A file is required")
                .test(
                    "fileSize",
                    "File too large",
                    value => value && value.size <= FILE_SIZE
                )
                .test(
                    "fileFormat",
                    "Unsupported Format",
                    value => value && SUPPORTED_FORMATS.includes(value.type)
                ),
            otherwise: mixed(),
        })
    });

    const [modalDeleteShow, setModalDeleteShow] = useState(false);
    // const [slider, setSlider] = useState(50);
    const slider = 50

    return (
        <div>
            <Header>Edit Profile</Header>

            <EditProfileImg>
                <Formik
                    validationSchema={schema}
                    validateOnMount={true}
                    onSubmit={(values, {setSubmitting, resetForm}) => {
                        ReactGA.event({
                            category: 'UserSettings',
                            action: 'Update user settings'
                        });
                        setSubmitting(true);
                        dispatch(userActions.update(user.id, values))
                        if (values.validateImage) {
                            ReactGA.event({
                                category: 'UserSettings',
                                action: 'Upload Profile Picture'
                            });
                            setSubmitting(true);
                            dispatch(profilePictureActions.create(
                                user.id,
                                previewCanvasRef.current.toDataURL("image/png", 1)
                            ))
                        }
                        setSubmitting(false);
                        // resetForm({values: values})
                    }}
                    enableReinitialize={true}
                    initialValues={{
                        bio: user.bio ?? '',
                        email: user.email ?? '',
                        username: user.username ?? '',
                        validateImage: false,
                        file: '',
                        marketingEmails: user.marketing_emails ?? false
                    }}>
                    {({
                          handleSubmit,
                          handleChange,
                          handleReset,
                          handleBlur,
                          values,
                          dirty,
                          touched,
                          isValid,
                          isSubmitting,
                          errors,
                          setFieldValue,
                          setFieldTouched,
                      }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <EditProfileImg>
                                <div>
                                    <ImgEditor progress={slider}>
                                        {profilePicture.originalFile.url !== undefined
                                            ? <canvas

                                                ref={previewCanvasRef}
                                                className={'rounded-circle'}
                                                style={{
                                                    display: "none",
                                                    width: 150,
                                                    height: 150
                                                }}
                                            />
                                            : null
                                        }
                                        {profilePicture.originalFile.url === undefined
                                            ? (user.profile_picture !== null && user.profile_picture !== undefined) && user.profile_picture.url !== null ?
                                                <Image style={{width: 150, height: 150, margin: "auto"}}
                                                       src={URL.concat(user.profile_picture.url)
                                                       } roundedCircle/>
                                                :
                                                <FontAwesomeIcon color={'black'} icon={'user'} size={'2x'}/>
                                            :
                                            <Row>
                                                <Col>
                                                    <ReactCrop
                                                        src={profilePicture.originalFile.url}
                                                        onImageLoaded={onLoad}
                                                        keepSelection={true}
                                                        minWidth={30}
                                                        minHeight={30}
                                                        crop={profilePicture.crop}
                                                        circularCrop={true}
                                                        onChange={(c) => dispatch(profilePictureActions.crop(c))}
                                                        onComplete={(c) => dispatch(profilePictureActions.cropComplete(c))}
                                                    />
                                                </Col>
                                            </Row>
                                        }
                                        {/*<img src={EditImgPng} alt=""/>*/}
                                        {/*<input*/}
                                        {/*    type="range"*/}
                                        {/*    value={slider}*/}
                                        {/*    // onChange={(e) => {*/}
                                        {/*    //     setSlider(e.target.value);*/}
                                        {/*    // }}*/}
                                        {/*    min="0"*/}
                                        {/*    max="100"*/}
                                        {/*    step="1"*/}
                                        {/*/>*/}
                                    </ImgEditor>
                                    <div>
                                        <BillingInput gap="5rem">
                                            <FormRegularRow>
                                                <SettingLabel htmlFor={'username'}>
                                                    Username
                                                </SettingLabel>
                                                <SettingInput
                                                    id={'username'}
                                                    name={`username`}
                                                    onBlur={e => {
                                                        handleBlur(e)
                                                    }}
                                                    onChange={e => {
                                                        handleChange(e)
                                                    }}
                                                    value={values.username}
                                                    placeholder="Name"
                                                    isValid={touched.username && !errors.username}
                                                    isInvalid={!!errors.username}
                                                />
                                                <Form.Control.Feedback type="invalid" className={'tooltip text-danger'}>
                                                    {errors.username}
                                                </Form.Control.Feedback>
                                            </FormRegularRow>
                                            <FormRegularRow>
                                                <SettingLabel htmlFor={'email'}>
                                                    Email
                                                </SettingLabel>
                                                <SettingInput
                                                    id={'email'}
                                                    name={`email`}
                                                    onBlur={e => {
                                                        handleBlur(e)
                                                    }}
                                                    onChange={e => {
                                                        handleChange(e)
                                                    }}
                                                    value={values.email}
                                                    placeholder="email@guickg.com"
                                                    isValid={touched.email && !errors.email}
                                                    isInvalid={!!errors.email}
                                                />
                                                <Form.Control.Feedback type="invalid" className={'tooltip text-danger'}>
                                                    {errors.email}
                                                </Form.Control.Feedback>
                                            </FormRegularRow>
                                        </BillingInput>
                                        <BillingInput>
                                            <FormRegularRow>
                                                <SettingLabel htmlFor={'bio'}>
                                                    Bio
                                                </SettingLabel>
                                                <SettingInput
                                                    as="textarea"
                                                    rows={2}
                                                    id={'bio'}
                                                    name={`bio`}
                                                    onBlur={e => {
                                                        handleBlur(e)
                                                    }}
                                                    onChange={e => {
                                                        handleChange(e)
                                                    }}
                                                    value={values.bio}
                                                    placeholder="Bio"
                                                    isValid={touched.bio && !errors.bio}
                                                    isInvalid={!!errors.bio}
                                                />
                                                <Form.Control.Feedback type="invalid" className={'tooltip text-danger'}>
                                                    {errors.bio}
                                                </Form.Control.Feedback>
                                            </FormRegularRow>
                                        </BillingInput>
                                        <BillingInput>
                                            <FormRegularRow>
                                                <SettingLabel htmlFor={'file'}>
                                                    Profile picture
                                                </SettingLabel>
                                                <Form.Control
                                                    type={'file'}
                                                    id={'file'}
                                                    name={`file`}
                                                    onBlur={e => {
                                                        handleBlur(e)
                                                    }}
                                                    onChange={e => {
                                                        handleChange(e)
                                                        setTimeout(() => {
                                                            handleBlur(e)
                                                        }, 0);
                                                        let file = e.target.files[0];
                                                        if (file) {
                                                            dispatch(profilePictureActions.load(file))
                                                            setFieldValue('file', file)
                                                            setFieldValue('validateImage', true)
                                                        }
                                                        setTimeout(() => {
                                                            if (dirty && isValid) {
                                                                handleSubmit(e)
                                                            }
                                                        }, 0);

                                                    }}
                                                    placeholder="Name"
                                                    isValid={touched.file && !errors.file}
                                                    isInvalid={!!errors.file}
                                                />
                                                <Form.Control.Feedback type="invalid" className={'tooltip text-danger'}>
                                                    {errors.file}
                                                </Form.Control.Feedback>
                                            </FormRegularRow>
                                        </BillingInput>
                                        <Terms>
                                            <Form.Group controlId="registerForm.marketingEmails">
                                                <Form.Check
                                                    custom
                                                    name="marketingEmails"
                                                    checked={values.marketingEmails}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value="false"
                                                    type='checkbox'
                                                    label={`I'm interested in updates from Quickg and partners`}
                                                    isInvalid={!!errors.marketingEmails}
                                                />
                                                <Form.Control.Feedback type="invalid" className={'tooltip text-danger'}>
                                                    {errors.marketingEmails}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Terms>
                                        {/*  todo QG-T-120 nice file upload */}
                                        {/*<FileContainer>*/}
                                        {/*    <FontAwesomeIcon icon={'file-arrow-up'}/>*/}
                                        {/*    <FileInfo>*/}
                                        {/*        <p>*/}
                                        {/*            File Name here <span> - 128kb</span>*/}
                                        {/*        </p>*/}
                                        {/*        <FontAwesomeIcon icon={'times'}/>*/}
                                            {/*    </FileInfo>*/}
                                            {/*</FileContainer>*/}

                                            {/*<AddButton icon={<FontAwesomeIcon icon={'file-arrow-up'}/>}>*/}
                                            {/*    File Upload*/}
                                            {/*</AddButton>*/}
                                    </div>
                                </div>
                                <EditImgButtons>
                                    <Button
                                        type={"button"}
                                        variant={'danger'}
                                        onClick={() => setModalDeleteShow(true)}
                                    ><FontAwesomeIcon icon={'trash'}/> Delete Account</Button>
                                    <ButtonContainer>
                                        <PrimaryButton
                                            outline
                                            type={"button"}
                                            variant={'danger'}
                                            disabled={!dirty || isSubmitting || profilePicture.isSending || profilePicture.isLoading}
                                            onClick={() => {
                                                handleReset()
                                                dispatch(profilePictureActions.clear())
                                                dispatch(userActions.clearFormErrors())
                                            }}
                                        >Reset</PrimaryButton>
                                        <PrimaryButton
                                            type={"submit"}
                                            disabled={!dirty || isSubmitting || profilePicture.isSending || profilePicture.isLoading}
                                        >Submit</PrimaryButton>
                                    </ButtonContainer>
                                </EditImgButtons>
                            </EditProfileImg>
                            <ValidateFromApi errors={errorState}/>
                        </Form>
                    )}
                </Formik>
            </EditProfileImg>
            <DeleteModal title={'modal.delete.account'} userId={() => dispatch(userActions.delete(user.id))}
                         show={modalDeleteShow} onHide={() => setModalDeleteShow(false)}/>
        </div>
    )
};

EditProfile.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
    user: PropTypes.shape({
        id: PropTypes.string,
        bio: PropTypes.string,
        email: PropTypes.string,
        username: PropTypes.string,
        //todo use shape
        billing_info: PropTypes.object,
        //todo use shape
        profile_picture: PropTypes.object,
        marketing_emails: PropTypes.bool,
    }),
    errorState: PropTypes.object,
};

export default withTranslation()(EditProfile);
