import React, {useEffect} from "react";
import {AddButton, PrimaryButton} from "../../styled-components/Buttons";

import {
    DropdownContainer,
    FormText,
    LinkContainer,
    Links,
    PageHeader,
    PaymentName,
    Progresses,
    RecentSupporter,
    SettingInput,
    SettingLabel,
    SettingsContainer,
    SettingsWrapper,
    SubHeader,
    SupportMore,
} from "../../styled-components/S_Setting";
import User from "../../components/User/PrivateProfile/User";
import ContentManager from "../../components/User/PrivateProfile/ContentManager";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useDispatch} from "react-redux";
import {useGetCurrentUserQuery} from "../../services/userService";
import Loading from "../../components/General/Loading";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import ToggleSwitch from "../../components/User/PrivateProfile/ToggleSwitch";
import Header from "../../components/User/PrivateProfile/Header";
import {
    enabledEmailSubscribe,
    enabledLinks,
    enabledStripeCharges,
    enabledStripePayouts,
    enabledSupportMe,
    hasStripe,
    showRecentSupportMe,
    stripeDetailsSubmitted,
} from "../../helpers/userSettingsHelper";
import {userActions} from "../../actions/user";
import {linkActions} from "../../actions/link";
import LinkItem from "../../components/User/PrivateProfile/LinkItem";
import AddSocial from "../../components/User/PrivateProfile/AddSocial";
import {Link} from "react-router-dom";
import * as ReactGA from "react-ga";
import {Form} from "react-bootstrap";
import {Formik} from "formik";
import {object, string} from "yup";
import Hashtag from "../../components/User/PrivateProfile/Hashtag";
import Progress from "../../components/User/Progress";
// import Affiliate from "../../components/User/PrivateProfile/Affiliate";


const UserPrivateProfile = ({t, i18n}) => {
    const dispatch = useDispatch();

    const {
        data,
        isLoading
    } = useGetCurrentUserQuery()

    useEffect(() => {
        document.title = 'Quickg - '.concat(data?.username);
    }, [data]);

    if (isLoading) {
        return (
            <Loading/>
        )
    }

    const schema = object({
        message: string(),
    });

    return (
        <>
            <User user={data}/>
            <SettingsContainer>
                <SettingsWrapper>
                    <PageHeader>
                        <Header>Edit profile</Header>
                    </PageHeader>
                    <ContentManager user={data}/>
                    <AddSocial user={data}/>
                    <Hashtag user={data}/>

                    <div>
                        <div>
                            <Header
                                status={t((enabledEmailSubscribe(data) ? 'user.profile.active' : 'user.profile.inactive'))}
                                danger={!enabledEmailSubscribe(data)}
                            >
                                {t('emailSubscribe.title')}
                            </Header>
                            <ToggleSwitch
                                text={['user.profile.inactive', 'user.profile.active']}
                                value={enabledEmailSubscribe(data)}
                                action={() => dispatch(userActions.changeProfileSettings(data, {
                                    'emailSubscribe': {
                                        ...data.profile_settings?.emailSubscribe,
                                        enabled: !enabledEmailSubscribe(data)
                                    }
                                }))}
                            />
                        </div>
                        <Formik
                            validationSchema={schema}
                            validateOnMount={true}
                            onSubmit={(values, {setSubmitting, resetForm}) => {
                                ReactGA.event({
                                    category: 'Profile links',
                                    action: 'Edit Links'
                                });
                                setSubmitting(true);
                                dispatch(
                                    userActions.changeProfileSettings(data, {
                                        'emailSubscribe': {
                                            ...data.profile_settings?.emailSubscribe,
                                            message: values.message
                                        }
                                    })
                                )

                                //todo extract somewhere (api normalization to send null instead of '')
                                setSubmitting(false);
                                resetForm({values: values})
                            }}
                            enableReinitialize={true}
                            initialValues={{
                                message: data.profile_settings?.emailSubscribe?.message ?? '',
                            }}>
                            {({
                                  handleSubmit,
                                  handleChange,
                                  handleReset,
                                  handleBlur,
                                  values,
                                  dirty,
                                  touched,
                                  isValid,
                                  isSubmitting,
                                  errors,
                                  setFieldValue,
                                  setFieldTouched,
                              }) => (<Form>
                                    <Form.Group>
                                        <SettingLabel htmlFor={'message'}>
                                            Message
                                            {/*{t('user.edit.postal_code')}*/}
                                        </SettingLabel>
                                        <SettingInput
                                            style={{'width': '100%'}}
                                            as="textarea"
                                            rows={3}
                                            name={'message'}
                                            value={values.message}
                                            disabled={isLoading}
                                            onBlur={e => {
                                                handleBlur(e)
                                                setTimeout(() => {
                                                    if (dirty && isValid && !isLoading)
                                                        handleSubmit(e)
                                                }, 0);
                                            }}
                                            onChange={handleChange}
                                            isValid={touched.message && !errors.message}
                                            isInvalid={!!errors.message}
                                        />
                                        <FormText id="passwordHelpBlock" muted>
                                            Let your fans know what would they gain by subscribing to your email list.
                                        </FormText>
                                        <Form.Control.Feedback type="invalid" className={'tooltip text-danger'}>
                                            {errors.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Form>
                            )}
                        </Formik>
                        <SupportMore>
                            <Link to={'/emails'}>
                                <PrimaryButton>SHOW EMAIL SUBSCRIPTIONS</PrimaryButton>
                            </Link>
                        </SupportMore>
                    </div>

                    <div>
                        <div>
                            <Header
                                status={t(enabledStripeCharges(data)
                                    ? (enabledSupportMe(data) ? 'user.profile.active' : 'user.profile.inactive')
                                    : 'user.profile.disabled'
                                )}
                                disabled={!enabledStripeCharges(data)}
                                danger={!enabledSupportMe(data)}
                            >Support Me</Header>
                            <SubHeader>
                                {!enabledStripeCharges(data)
                                    ? !hasStripe(data) ? t('supportMe.disabledPayoutsMessage') :
                                        t('supportMe.disabledMessage')
                                    : null
                                }
                            </SubHeader>
                            <ToggleSwitch
                                text={['user.profile.inactive', 'user.profile.active']}
                                value={enabledSupportMe(data)}
                                action={() => dispatch(userActions.changeProfileSettings(data, {'supportMe': {enabled: !enabledSupportMe(data)}}))}
                                disabled={!enabledStripeCharges(data)}
                            />
                        </div>
                        <PaymentName>
                            <FontAwesomeIcon color={'stripe'} icon={['fab', 'stripe']}/>
                            <p>( Stripe Account )</p>
                        </PaymentName>
                        <Progresses>
                            <Progress value={stripeDetailsSubmitted(data)} disabled={!hasStripe(data)}>
                                Details Submitted
                            </Progress>
                            <Progress value={enabledStripeCharges(data)} disabled={!hasStripe(data)}>
                                Charges
                            </Progress>
                            <Progress value={enabledStripePayouts(data)} disabled={!hasStripe(data)}>
                                Payouts
                            </Progress>
                        </Progresses>
                    </div>

                    <RecentSupporter>
                        <div>
                            <Header
                                status={t((showRecentSupportMe(data) ? 'user.profile.active' : 'user.profile.inactive'))}
                                danger={!showRecentSupportMe(data)}
                            >
                                Recent Supporters
                            </Header>
                            <ToggleSwitch
                                text={['user.profile.inactive', 'user.profile.active']}
                                value={showRecentSupportMe(data)}
                                action={() => dispatch(userActions.changeProfileSettings(data, {'recentSupportMe': {enabled: !showRecentSupportMe(data)}}))}
                            />
                        </div>
                        {/*todo more config*/}
                        <SupportMore>
                            {/*    <div>*/}
                            {/*        <Text>Sort By Date Or Amount </Text>*/}
                            {/*        <Dropdown>Date</Dropdown>*/}
                            {/*    </div>*/}
                            {/*    <div>*/}
                            {/*        <Text>Show Items</Text>*/}
                            {/*        <Dropdown>5</Dropdown>*/}
                            {/*    </div>*/}
                            {/*    <div>*/}
                            {/*        <Text>Message</Text>*/}
                            {/*        <Dropdown>Hide</Dropdown>*/}
                            {/*    </div>*/}
                            {/*    <div>*/}
                            {/*        <Text>Amount</Text>*/}
                            {/*        <Dropdown>Show</Dropdown>*/}
                            {/*    </div>*/}
                            <Link to={'/supporters'}>
                                <PrimaryButton>SHOW SUPPORTERS</PrimaryButton>
                            </Link>
                        </SupportMore>

                        {/*<SupporterView>*/}
                        {/*    <Text size="1.4rem">Supporters show like this:</Text>*/}
                        {/*    <div>*/}
                        {/*        <img src="" alt=""/>*/}
                        {/*        <PreviewContent>*/}
                        {/*            <div>*/}
                        {/*                <p>Long Title here…</p>*/}
                        {/*                <span>21 June, 2021</span>*/}
                        {/*            </div>*/}
                        {/*            <Text>*/}
                        {/*                Use my discount code AYKON20 to get 20% o on amazon.com*/}
                        {/*            </Text>*/}
                        {/*        </PreviewContent>*/}
                        {/*        <p>$ 237</p>*/}
                        {/*    </div>*/}
                        {/*</SupporterView>*/}
                    </RecentSupporter>

                    {/*todo store*/}
                    {/*<Store>*/}
                    {/*    <div>*/}
                    {/*        <Header danger={true} status={"Hide"}>*/}
                    {/*            Store*/}
                    {/*        </Header>*/}
                    {/*        <ToggleSwitch/>*/}
                    {/*    </div>*/}

                    {/*    <StoreFilter>*/}
                    {/*        <table cellSpacing={0}>*/}
                    {/*            <thead>*/}
                    {/*            <tr>*/}
                    {/*                <th>*/}
                    {/*                    <Text>Show Status</Text>*/}
                    {/*                </th>*/}
                    {/*                <th>*/}
                    {/*                    <Text>Show Items</Text>*/}
                    {/*                </th>*/}
                    {/*            </tr>*/}
                    {/*            </thead>*/}

                    {/*            <tbody>*/}
                    {/*            <tr>*/}
                    {/*                <td>*/}
                    {/*                    <Text>Show Status</Text>*/}
                    {/*                    <Dropdown>Show</Dropdown>*/}
                    {/*                </td>*/}
                    {/*                <td>*/}
                    {/*                    <Text>Show Items</Text>*/}
                    {/*                    <Dropdown>4</Dropdown>*/}
                    {/*                </td>*/}
                    {/*                <td>*/}
                    {/*                    <PrimaryButton>Show MY STORE</PrimaryButton>*/}
                    {/*                </td>*/}
                    {/*            </tr>*/}
                    {/*            </tbody>*/}
                    {/*        </table>*/}
                    {/*    </StoreFilter>*/}
                    {/*    <div>*/}
                    {/*        {[1, 2, 3].map(() => {*/}
                    {/*            return (*/}
                    {/*                <Product>*/}
                    {/*                    <Text>Filter Products</Text>*/}
                    {/*                    <div>*/}
                    {/*                        <Dropdown/>*/}
                    {/*                        <div>*/}
                    {/*                            <FontAwesomeIcon className={'trash'} icon={'trash'}/>*/}
                    {/*                            <PrimaryButton padding outline>*/}
                    {/*                                Show Selected Product*/}
                    {/*                            </PrimaryButton>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                </Product>*/}
                    {/*            );*/}
                    {/*        })}*/}
                    {/*    </div>*/}
                    {/*</Store>*/}

                    {/* links */}
                    <Links>
                        <div>
                            <Header
                                status={t((enabledLinks(data) ? 'user.profile.active' : 'user.profile.inactive'))}
                                danger={!enabledLinks(data)}
                            >
                                Links
                            </Header>
                            <ToggleSwitch
                                text={['user.profile.inactive', 'user.profile.active']}
                                value={enabledLinks(data)}
                                action={() => dispatch(userActions.changeProfileSettings(data, {'link': {enabled: !enabledLinks(data)}}))}
                            />
                        </div>

                        <LinkContainer>
                            {data?.links.map((link, index) => {
                                return <LinkItem key={index} index={index} link={link}/>;
                            })}

                            <AddButton action={e => {
                                dispatch(linkActions.create({name: '', url: '', enabled: false}))
                            }}>Add New Link</AddButton>
                        </LinkContainer>
                    </Links>

                    {/*<div>*/}
                    {/*    <div>*/}
                    {/*        <Header*/}
                    {/*            status={t((enabledAffiliate(data) ? 'user.profile.active' : 'user.profile.inactive'))}*/}
                    {/*            danger={!enabledAffiliate(data)}*/}
                    {/*        >*/}
                    {/*            Affiliate links and discount codes*/}
                    {/*        </Header>*/}
                    {/*        <ToggleSwitch*/}
                    {/*            text={['user.profile.inactive', 'user.profile.active']}*/}
                    {/*            value={enabledAffiliate(data)}*/}
                    {/*            action={() => dispatch(userActions.changeProfileSettings(data, {'affiliate': {enabled: !enabledAffiliate(data)}}))}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*    <Affiliate user={data}/>*/}
                    {/*</div>*/}

                    {/*todo Affiliate*/}
                    {/*<div>*/}
                    {/*    <div>*/}
                    {/*        <Header danger={true} status={"Hide"}>*/}
                    {/*            Affiliate links and discount codes*/}
                    {/*        </Header>*/}
                    {/*        <ToggleSwitch/>*/}
                    {/*    </div>*/}
                    {/*    <Affiliate>*/}
                    {/*        <div>*/}
                    {/*            <Text>Global Description</Text>*/}
                    {/*            <SettingInput*/}
                    {/*                as={"textarea"}*/}
                    {/*                placeholder="Enter Description"*/}
                    {/*                value={*/}
                    {/*                    "I have affiliate links section on my profile and then I want to write some message above all of my affiliate links (Please use this link to support my work…) and then under that you have all that cards which you displayed."*/}
                    {/*                }*/}
                    {/*                type="text"*/}
                    {/*            />*/}
                    {/*        </div>*/}
                    {/*        <LinkRow>*/}
                    {/*            <Text>Name</Text>*/}
                    {/*            <LinkInput>*/}
                    {/*                <SettingInput placeholder="Enter your name" type="text"/>*/}
                    {/*                <IconInput>*/}
                    {/*                    <div>*/}
                    {/*                        <FontAwesomeIcon icon={'ticket'}/>*/}
                    {/*                        <input type="text" placeholder="Link past here…"/>*/}
                    {/*                    </div>*/}
                    {/*                </IconInput>*/}
                    {/*            </LinkInput>*/}
                    {/*        </LinkRow>*/}
                    {/*        <SettingInput*/}
                    {/*            as={"textarea"}*/}
                    {/*            value={""}*/}
                    {/*            placeholder="Enter Description"*/}
                    {/*            type="text"*/}
                    {/*        />*/}
                    {/*        <IconInput hasValue>*/}
                    {/*            <div>*/}
                    {/*                <FontAwesomeIcon icon={'link'}/>*/}
                    {/*                <input type="text" placeholder="Link past here…"/>*/}
                    {/*            </div>*/}
                    {/*        </IconInput>*/}
                    {/*    </Affiliate>*/}

                    {/*    <Preview>*/}
                    {/*        <Text>Preview</Text>*/}
                    {/*        {[1, 2].map((value) => {*/}
                    {/*            return (*/}
                    {/*                <div>*/}
                    {/*                    <PreviewContentContainer>*/}
                    {/*                        <img src="" alt=""/>*/}
                    {/*                        <PreviewContent>*/}
                    {/*                            <p>Long Title here…</p>*/}
                    {/*                            <Text>*/}
                    {/*                                Use my discount code AYKON20 to get 20% o on*/}
                    {/*                                amazon.com*/}
                    {/*                            </Text>*/}
                    {/*                            <Text>3k4b5kj34l32</Text>*/}
                    {/*                        </PreviewContent>*/}
                    {/*                    </PreviewContentContainer>*/}

                    {/*                    <PreviewControls>*/}
                    {/*                        <UnderLineLink>*/}
                    {/*                            <FontAwesomeIcon icon={'clipboard'}/>*/}
                    {/*                            COPY LINK*/}
                    {/*                        </UnderLineLink>*/}
                    {/*                        <UnderLineLink color="#f05252">*/}
                    {/*                            <FontAwesomeIcon icon={'trash'}/>*/}
                    {/*                            DELETE*/}
                    {/*                        </UnderLineLink>*/}
                    {/*                        <div>*/}
                    {/*                            <Text size=".8rem">Disable</Text>*/}
                    {/*todo use regular form see links*/}

                    {/*                            <InputSwitch/>*/}
                    {/*                        </div>*/}
                    {/*                    </PreviewControls>*/}
                    {/*                </div>*/}
                    {/*            );*/}
                    {/*        })}*/}
                    {/*        <Text size=".9rem">*/}
                    {/*            It is a long established fact that a reader will be distracted*/}
                    {/*            by the readable content of a page when looking at its layout.*/}
                    {/*            The point of using Lorem Ipsum is that it has a more-or-less*/}
                    {/*            normal distribution of letters, as opposed to using ‘Content*/}
                    {/*            here, content here’, making it look like readable English.*/}
                    {/*        </Text>*/}
                    {/*    </Preview>*/}
                    {/*</div>*/}
                </SettingsWrapper>
            </SettingsContainer>
        </>
    );
}

UserPrivateProfile.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
};

export default withTranslation()(UserPrivateProfile);

export function Dropdown({children}) {
    return (
        <DropdownContainer>
            <p>{children || "Product name here 1"}</p>
            <FontAwesomeIcon icon={'caret-down'}/>
        </DropdownContainer>
    );
}

Dropdown.propTypes = {
    children: PropTypes.element.isRequired,
};
