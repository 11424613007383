import React, {useEffect} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import Header from "../components/Header";

const NotFound = ({t, i18n, message = null}) => {
    useEffect(() => {
        document.title = 'Quickg Not Found'
        //todo add sentry log, add data to analytics
        // ReactGA.pageview('/not-found');
    }, []);

    return (
        <>
            <Header header={404}/>

            <Container fluid className='mt-5 pt-4 pb-4'>
                <Row className={'text-center'}>
                    <Col>
                        <h1>Page not found!</h1>
                        <h3>{message}</h3>
                    </Col>
                </Row>
            </Container>
        </>

    )
};

NotFound.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
    message: PropTypes.string,
};

export default withTranslation()(NotFound);
