import {Flex, Text} from "@chakra-ui/react";
import React from "react";

interface HeadingProps {
    title: string;
    titleColored?: string;
    description: string;
}

const Heading: React.FC<HeadingProps> = ({
                                             title,
                                             titleColored,
                                             description,
                                         }) => {
    return (
        <Flex direction="column" justify="center">
            <Flex width="100%" justify="center">
                <Text
                    textStyle="h2"
                    mb={{
                        base: 2,
                        lg: 4,
                    }}
                >
                    {title}
                </Text>
                {titleColored && (
                    <Text ml={2} textStyle="h2" color="brand.dark">
                        {titleColored}
                    </Text>
                )}
            </Flex>
            <Text textAlign="center" textStyle="h5">
                {description}
            </Text>
        </Flex>
    );
};

export default Heading;
