import {Box, Container, Flex} from "@chakra-ui/react";
import Heading from "../../../../components/Heading";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import IntroItem from "./IntroItem";
import React from "react";

const introItems = [
    {
        icon: <FontAwesomeIcon color={'#c33664'} icon={'link'} size={'1x'}/>,
        title: "1 Link, Infinite Possibilities",
        desc: "Consolidate all your important links in one place for easy audience access.",
    },
    {
        icon: <FontAwesomeIcon color={'#c33664'} icon={'address-card'} size={'1x'}/>,

        title: "Own Your Online Image",
        desc: "Create a personal page that reflects your unique style and enhances your online presence.",
    },
    {
        icon: <FontAwesomeIcon color={'#c33664'} icon={'envelope'} size={'1x'}/>,

        title: "Email Subscriptions",
        desc: "Grow your audience with our easy-to-use email subscription feature.",
    },
    {
        icon: <FontAwesomeIcon color={'#c33664'} icon={'heart'} size={'1x'}/>,
        title: "Support Me",
        desc: "Receive direct support from your fans through your Quickg page",
    },
    {
        icon: <FontAwesomeIcon color={'#c33664'} icon={'robot'} size={'1x'}/>,
        title: "AI-Enhanced Posts",
        desc: "Generate engaging content with the help of AI technology",
    },
];

const introItemss = [
    {
        icon: <FontAwesomeIcon color={'#c33664'} icon={'link'} size={'1x'}/>,
        title: "One Link, Infinite Possibilities",
        desc: "Streamline your digital footprint. Share all your crucial links in one place, simplifying connection for your audience.",
    },
    {
        icon: <FontAwesomeIcon color={'#c33664'} icon={'address-card'} size={'2x'}/>,

        title: "Own Your Online Image",
        desc: "Make your 'link in bio' more than a link. Craft a personal page that mirrors your personality and amplifies your online.",
    },
    {
        icon: <FontAwesomeIcon color={'#c33664'} icon={'envelope'} size={'2x'}/>,

        title: "Email Subscriptions",
        desc: "Connect, engage, and grow. Empower your visitors to become long-term subscribers with our intuitive email subscription.",
    },
    {
        icon: <FontAwesomeIcon color={'#c33664'} icon={'envelope'} size={'2x'}/>,
        title: "Support Me",
        desc: "Let your audience fuel your creativity and passion. Receive support from your fans directly through your Quickg page.",
    },
    {
        icon: <FontAwesomeIcon color={'#c33664'} icon={'envelope'} size={'2x'}/>,
        title: "AI-Enhanced Posts",
        desc: "Streamline your content creation with the power of AI. Quickg's cutting-edge technology helps you generate compelling posts, tailored to your audience for maximum engagement. Achieve more, while doing less.",
    },
];

const IntroductionSection = () => {
    return (
        <Box position="relative">
            {/*<SearchBox/>*/}
            <Container maxW="1080px" py={"60px"}>
                <Heading
                    title="What Is"
                    titleColored="Quickg?"
                    description="Build a stunning bio site tailored to your unique style, where you can seamlessly integrate social media, share affiliate links, and showcase your remarkable skills and portfolio. Take control of your online persona with Quickg."
                />
                {/*<Center>*/}
                {/*    <Grid*/}
                {/*        mt={"64px !important"}*/}
                {/*        templateColumns={{base: "repeat(1, 1fr)", lg: "repeat(3, 1fr)"}}*/}
                {/*        gap={12}*/}
                {/*    >*/}
                {/*        {introItems.map(({icon, title, desc}) => (*/}
                {/*            <GridItem key={title}>*/}
                {/*                <IntroItem icon={icon} title={title} desc={desc}/>*/}
                {/*            </GridItem>*/}
                {/*        ))}*/}
                {/*    </Grid></Center>*/}
                <Box p={4}>
                    <Container maxW={'5xl'} mt={12}>
                        <Flex flexWrap="wrap" gridGap={6} justify="center">
                            {introItems.map(({icon, title, desc}) => (
                                <IntroItem
                                    key={title}
                                    heading={title}
                                    icon={icon}
                                    description={desc}
                                    href={'#'}
                                />
                            ))}
                        </Flex>
                    </Container>
                </Box>
            </Container>
        </Box>
    );
};

export default IntroductionSection;
