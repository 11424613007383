import {Box, Button, Container, Grid, GridItem, Image, Text, VStack,} from "@chakra-ui/react";
import React from "react";
import {useNavigate} from "react-router-dom";

const persons = [
    {
        image: "/images/jane.png",
        name: "Jane Doe",
        desc: "\"Being Quickg's demo user is a blast! I don't eat or sleep, but hey, I'm a content machine! The platform is so easy, even a bot like me can use it. If you're a creator who enjoys a good laugh and a great platform, Quickg's your ticket!\" - Jane Doe",
        url: "janedoe"
    },
    {
        image: "/images/martin.png",
        name: "Martin Janosik",
        desc: "\"Building and running Quickg has been an incredible journey. It's amazing to see the platform come to life and serve as a creative hub for content creators. As the owner, I'm not just behind the scenes. I use Quickg too! Join us at Quickg!\" - Martin Janosik",
        url: "aykonsvk"
    },
    // {
    //     image: "/images/elisa.png",
    //     name: "Elisaveta Bunduche",
    //     desc: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
    //     url: "bunduchee"
    // },
    {
        image: "/images/you.png",
        name: "You!",
        desc: "Ever thought of being a star on Quickg? Well, we're saving a spot just for you! With Quickg, it's not just about bio page, it's about building a community. So, why wait? Your potential is limitless. Hop on board and let's create something amazing together!\" - You!",
    },
];

const TestimonialSection = () => {
    const navigate = useNavigate();

    return (
        <Box
            bg="brand.dark"
            // display={{ base: "none", lg: "block" }}
            // TODO BOOTSTRAP
            mt={20}
        >
            <Container py={"60px"} maxW="1080px">
                <VStack>
                    <Text textStyle="h2" color="white">
                        Who is using Quickg?
                    </Text>

                    <Text w="90%" textAlign="center" textStyle="p1" color="white">
                        From Instagram influencers to freelancers, businesses to creatives, Quickg is the go-to platform
                        for anyone looking to boost their digital presence and growth. Join our community today and
                        start your journey to digital success.
                    </Text>
                </VStack>
                {/*use when 4*/}
                {/*<Grid w="full" gridTemplateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(3, 1fr)']} gap={8}>*/}
                <Grid w="full" gridTemplateColumns={['repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(3, 1fr)']} gap={8}>
                    {persons.map((person, i) => (
                        <GridItem key={i}>
                            <VStack
                                minW="full"
                                bg="rgba(255, 255, 255, 0.14)"
                                borderRadius="lg"
                                border="1px solid"
                                borderColor="white"
                                boxShadow="md"
                                padding={3}
                            >
                                <Box>
                                    <Image borderRadius='full' src={person.image} height="84px" width="84px"/>
                                </Box>
                                <Text textStyle="h6" color="white">
                                    {person.name}
                                </Text>
                                <Text
                                    textAlign="center"
                                    textStyle="p2"
                                    fontSize="sm"
                                    color="white"
                                >
                                    {person.desc}
                                </Text>
                                {person.url === undefined
                                    ? <Button
                                        color="white"
                                        fontSize="sm"
                                        textDecoration="underline"
                                        variant="link"
                                        onClick={() => navigate('register')}
                                    >
                                        JOIN US!
                                    </Button>
                                    : <Button
                                        color="white"
                                        fontSize="xs"
                                        textDecoration="underline"
                                        variant="link"
                                        onClick={() => navigate(`/${person.url}`)}
                                    >
                                        VIEW PROFILE
                                    </Button>
                                }
                            </VStack>
                        </GridItem>
                    ))}
                </Grid>
            </Container>
        </Box>
    );
};

export default TestimonialSection;
