import {Box} from "@chakra-ui/react";
import Content from "./Content";
import React from "react";

interface HeroSectionProps {
  toggleMenu: () => void;
}

const HeroSection: React.FC<HeroSectionProps> = ({toggleMenu}) => {
  return (
      <Box
          // h={{ base: "100vh", lg: "80vh" }}
          minH="50rem"
          bg="linear-gradient(150.61deg, #BC3665 6.89%, #272770 111.46%)"
          clipPath={{base: "none", lg: "ellipse(100% 100% at 50% 0%)"}}
          pt={10}
      >
        {/*todo BOOTSTRAP*/}
        {/*<Navbar toggleMenu={toggleMenu} />*/}
        <Content/>
      </Box>
  );
};

export default HeroSection;
