import React, {useEffect} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {withTranslation} from "react-i18next";
import Header from "../components/Header";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";

const Privacy = ({t, i18n}) => {
    useEffect(() => {
        document.title = 'Quickg - Privacy';
    });

    return (
        <Container fluid>
            <Row className={'mb-5'}>
                <Header header='Privacy policy'/>
            </Row>
            <Row>
                <Col>
                    <Container>
                        <Row>
                            <Col>
                                <p>
                                    Quickg is a platform for creators to help content creators with their work
                                    and accept support from their audience and share exclusive content or sell exclusive
                                    items. Quickg is not responsible for added content or sold items through Quickg. At
                                    Quickg, your privacy is important to us, and we want you to feel confident
                                    that your personal information is secure when using our products and our platform.
                                    The services are provided by Martive s.r.o. (parent company and
                                    hereinafter referred to as “Quickg”), a Slovak company with a registered address at
                                    Hlinská 2614/12, 010 01, Žilina, Žilinský kraj, Slovakia . It is Quickg’s policy
                                    to respect your privacy regarding any information we may collect while operating our
                                    website.
                                </p>
                                <h4>Website Visitors</h4>
                                <p>
                                    Like most website operators, Quickg collects non-personally-identifying
                                    information of the sort that web browsers and servers typically make available, such
                                    as the browser type, language preference, referring site, and the date and time of
                                    each visitor request. Quickg&apos;s purpose in collecting non-personally
                                    identifying information is to better understand how Quickg&apos;s visitors use
                                    its website. From time to time, Quickg may release
                                    non-personally-identifying information in the aggregate, e.g., by publishing a
                                    report on trends in the usage of its website.
                                    Quickg also collects potentially personally-identifying information like
                                    Internet Protocol (IP) addresses for logged-in users and for users making payments
                                    on quickg.eu. Quickg only discloses logged in user and commenter IP
                                    addresses under the same circumstances that it uses and discloses
                                    personally-identifying information as described below, except that payee IP
                                    addresses and email addresses are visible and disclosed to the administrators of
                                    Quickg and is handled by payment processors at the time of processing the payments.
                                </p>
                                <h4>Payments</h4>
                                <p>
                                    To make a payment to a creator on Quickg, you have to provide our payment
                                    processor (Stripe) with your payment information. They provide us with a token that
                                    represents your account, your card’s expiration date, card type, and the last four
                                    digits of your credit card. If you provide them with a name and email address then
                                    they also provide us with that information.
                                    We collect and process information about the creators you support, the level at
                                    which you support them, what rewards you receive, and how often you support them.
                                    Moreover, we collect and process information about the creators and which items are
                                    you buying.
                                </p>
                                <h4>Gathering of Personally-Identifying Information</h4>
                                <p>
                                    Certain visitors to Quickg&apos;s websites choose to interact with Quickg in ways
                                    that
                                    require Quickg to gather personally-identifying
                                    information. The amount and type of information that Quickg gathers depend
                                    on the nature of the interaction. For example, we ask visitors who sign up at
                                    quickg.eu to provide a username and email address. Those who engage in
                                    transactions with Quickg are asked to provide additional information,
                                    including as necessary the personal and financial information required to process
                                    those transactions. In each case, Quickg collects such information only
                                    insofar as is necessary or appropriate to fulfill the purpose of the visitor&apos;s
                                    interaction with Quickg. Quickg does not disclose
                                    personally-identifying information other than as described below. And visitors can
                                    always refuse to supply personally-identifying information, with the caveat that it
                                    may prevent them from engaging in certain website-related activities.
                                </p>
                                <h4>Aggregated Statistics</h4>
                                <p>
                                    Quickg may collect statistics about the behavior of visitors to its
                                    websites. Quickg may display this information publicly or provide it to
                                    others. However, Quickg does not disclose personally-identifying
                                    information other than as described below.
                                </p>
                                <h4>Payments</h4>
                                <p>
                                    Protection of Certain Personally-Identifying Information
                                    Quickg discloses potentially personally-identifying and
                                    personally-identifying information only to those of its employees, contractors, and
                                    affiliated organizations that (i) need to know that information in order to process
                                    it on Quickg&apos;s behalf or to provide services available at Quickg&apos;s
                                    websites, and (ii) that have agreed not to disclose it to others. Some of those
                                    employees, contractors, and affiliated organizations may be located outside of your
                                    home country; by using Quickg&apos;s websites, you consent to the transfer of
                                    such information to them. Quickg will not rent or sell potentially
                                    personally-identifying and personally-identifying information to anyone. Other than
                                    to its employees, contractors and affiliated organizations, as described above,
                                    Quickg discloses potentially personally-identifying and personally-identifying
                                    information only in response to a subpoena, court order, or another governmental
                                    request, or when Quickg believes in good faith that disclosure is
                                    reasonably necessary to protect the property or rights of Quickg, third
                                    parties or the public at large. If you are a registered user of a Quickg
                                    website and have supplied your email address, Quickg may occasionally send
                                    you an email to tell you about new features, solicit your feedback, or just keep you
                                    up to date with what&apos;s going on with Quickg and our products. If you send
                                    us a request (for example via email or via one of our feedback mechanisms), we
                                    reserve the right to publish it in order to help us clarify or respond to your
                                    request or to help us support other users. Quickg takes all measures
                                    reasonably necessary to protect against the unauthorized access, use, alteration, or
                                    destruction of potentially personally-identifying and personally-identifying
                                    information.
                                </p>
                                <h4>Cookies</h4>
                                <p>
                                    A cookie is a string of information that a website stores on a visitor&apos;s
                                    computer,
                                    and that the visitor&apos;s browser provides to the website each time the visitor
                                    returns. For more information, visit All About Cookies.
                                    Quickg uses cookies to help Quickg identify and track visitors,
                                    their usage of Quickg website, and their website access preferences. Some
                                    cookies expire after a certain amount of time, or upon logging out (session
                                    cookies), others remain on your computer or terminal device for a longer period
                                    (persistent cookies). Our Site uses first-party cookies (cookies set directly by
                                    Anchor) as well as third-party cookies, as described below
                                    Strictly Necessary Cookies: Used to provide Users with the Services available
                                    through our Site and to use some of their features, such as the ability to log-in
                                    and access to secure areas. These cookies are served by Anchor and are essential for
                                    using and navigating the Site. Without these cookies, the basic functions of our
                                    Site would not work. Because these cookies are strictly necessary to deliver the
                                    Site and the Services, you cannot refuse them.
                                    Analytics/Performance: Used to better understand the behavior of the Users on our
                                    Site and improve our Site accordingly, for example by making sure users are finding
                                    what they need easily. The Site uses Google Analytics, a web analytics service
                                    provided by Google Inc. (“Google”). The information collected by Google (including
                                    your IP address) will be transmitted to and stored by Google on servers in the
                                    United States (Google is certified to the Privacy Shield for data transfers). How
                                    long a Google Analytics cookie remains on your computer or device depends on what it
                                    is and what it is used for. Some Google Analytics cookies expire at the end of your
                                    browser session, whilst others can remain for up to two years. You can prevent your
                                    data from being collected by Google Analytics on our Site by downloading and
                                    installing the Google Analytics Opt-out Browser Add-on for your current web browser.
                                    For more information on Google Analytics privacy practices, read here.
                                    Quickg visitors who do not wish to have cookies placed on their computers
                                    should set their browsers to refuse cookies before using Quickg&apos;s websites,
                                    with the drawback that certain features of Quickg&apos;s websites may not
                                    function properly without the aid of cookies.
                                </p>
                                <h4>Business Transfers</h4>
                                <p>
                                    If Quickg, or substantially all of its assets, were acquired, or in the
                                    unlikely event that Quickg goes out of business or enters bankruptcy, user
                                    information would be one of the assets that is transferred or acquired by a third
                                    party. You acknowledge that such transfers may occur, and that any acquirer of
                                    Quickg may continue to use your personal information as outlined in this
                                    policy.
                                </p>
                                <h4>Ads</h4>
                                <p>
                                    We do not run any external advertisements on Quickg. However, we use ad
                                    networks such as Twitter Ads to collect retargeting information to compile
                                    information about our users. This Privacy Policy covers the use of cookies by Quickg
                                    and does not cover the use of cookies by any advertisers.
                                </p>
                                <h4>International Users</h4>
                                <p>
                                    Quickg is based in the Slovak republic. If you are accessing our Services
                                    from the European Union or other regions with laws governing data collection and
                                    use, please note that your Personal Data will be transmitted to our servers in the
                                    European Union and the data may be transmitted to our service providers supporting
                                    our business operations (described above). The European Union may have data
                                    protection laws less stringent than or otherwise different from the laws in effect
                                    in the country in which you are located. Where we transfer your Personal Data we
                                    will take steps to ensure that your Personal Data receives an adequate level of
                                    protection where it is processed and your rights continue to be protected (GDPR).
                                    <Link to='/gdpr'> https://quickg.eu/gdpr</Link>
                                    By providing your information to the Services you agree to the transfer of your
                                    information to the EU and processing globally in accordance with this Privacy
                                    Policy.
                                </p>
                                <h4>Privacy Policy Changes</h4>
                                <p>
                                    Although most changes are likely to be minor, Quickg may change its Privacy
                                    Policy from time to time, and in Quickg&apos;s sole discretion. Quickg
                                    encourages visitors to frequently check this page for any changes to its Privacy
                                    Policy. If you have a quickg.eu account, you might also receive an alert
                                    informing you of these changes. Your continued use of this site after any change in
                                    this Privacy Policy will constitute your acceptance of such change.
                                </p>
                                <h4>Other Terms and Conditions</h4>
                                <p>
                                    Your access to and use of the Quickg is subject to any additional terms
                                    applicable to such Services that may be posted on the Terms from time to time,
                                    including without limitation, Quickg’s Terms of Use available at
                                    <Link to='/terms'> https://quickg.eu/term</Link>
                                    <br/>
                                    By agreeing to our privacy policy you are also agreeing to the Google&apos;s privacy
                                    policy for the services which we use like Google Sign In, Recaptcha, Analytics, etc.
                                    which can be found here: https://policies.google.com/privacy?hl=en-US
                                </p>
                                <h4>Contact us if you have Privacy policy related questions</h4>
                                <p>
                                    Please feel free to contact us if you have any questions about Quickg’s
                                    Privacy Policy or the information practices.
                                    You may contact us as follows:
                                </p>

                                <ul>
                                    <li>Website: <Link to='/contact'>https://quickg.eu/contact</Link></li>
                                    <li>Email: <a href="mailto:info@quickg.eu?Subject=Privacy"
                                                  target="_top">info@quickg.eu</a></li>
                                </ul>
                                {/*Phone: Phone Number*/}
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Container>
    )
}

Privacy.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
};

export default withTranslation()(Privacy);
