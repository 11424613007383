import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import {ProgressItem} from "../../styled-components/S_Setting";

const Progress = ({value = true, children, disabled = false}) => {
    return (
        <ProgressItem color={value.toString()} disabled={disabled}>
            <FontAwesomeIcon icon={disabled ? 'ban' : value ? 'circle-check' : 'circle-exclamation'}/>
            <p>{children}</p>
        </ProgressItem>
    );
}

Progress.propTypes = {
    value: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    disabled: PropTypes.bool,
};

export default Progress;
