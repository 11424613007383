import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
// import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
// import translation_sk from "./translations/sk/translation.json";
import translation_en from "./translations/en/translation.json";

const fallbackLng = 'en';
const availableLanguages = ['en', 'sk'];

i18n
    // load translation using xhr -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-xhr-backend
    // .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)

    .use(initReactI18next)


    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: fallbackLng,
        debug: !process.env.NODE_ENV || process.env.NODE_ENV === 'development',
        saveMissing: true,
        whitelist: availableLanguages,
        resources: {
            en: {
                translation: translation_en               // 'common' is our custom namespace
            },
            // cs: {
            //     translation: translation_cs
            // },
        },

        // interpolation: {
        //     escapeValue: false, // not needed for react as it escapes by default
        // }
    });

export default i18n;
