import React, {useEffect} from "react";
import {withTranslation} from "react-i18next";
import {Navigate} from "react-router-dom";
import NotFound from "../NotFound";
import PropTypes from "prop-types";
import {useGetDiscoveryUserQuery} from "../../services/userService";
import Loading from "../../components/General/Loading";
import {useCreateMetricMutation} from "../../services/metricService";

const Discover = ({t, i18n}) => {
    const {
        data,
        isFetching,
        isError
    } = useGetDiscoveryUserQuery({}, {refetchOnMountOrArgChange: true})

    const [submitMetric] = useCreateMetricMutation()

    useEffect(() => {
        document.title = 'Quickg - Discover'
        if (data !== undefined && data !== null) {
            submitMetric({entity: 'User', entityId: data.id, userAction: 'discovery', user: data.id})
        }
    }, [submitMetric, data])


    if (isFetching) {
        return (
            <Loading/>
        )
    }

    if (isError) {
        return (
            <NotFound message={'No users to discover!'}/>
        )
    }

    return (
        <Navigate to={`/${data.username}`}/>
    )
}

Discover.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
};

export default withTranslation()(Discover);
