import React from "react";
import DoubleShapeTop from "../../General/DoubleShapeTop";
import DoubleShapeBottom from "../../General/DoubleShapeBottom";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {Col, Row} from "react-bootstrap";
import SupportMeForm from "./SupportMe/SupportMeForm";
import RecentSupportMe from "./SupportMe/RecentSupportMe";
import {showRecentSupportMe} from "../../../helpers/userSettingsHelper";

const SupportSection = ({t, i18n, user}) => {
    return (
        <section className="support-section">
            <Row className={'text-center'}>
                <Col>
                    {/*<p>*/}
                    {/*  It is a long established fact that a reader will be distracted by the*/}
                    {/*  readable content of a page when looking at its layout.*/}
                    {/*</p>*/}
                </Col>
            </Row>

            <div className="container d-flex align-items-center flex-column justify-content-center text-center">
                <h3>{t('supportMe.title')}</h3>


                <SupportMeForm billingInfoId={user.billing_info.id} userId={user.id}/>


                {showRecentSupportMe(user)
                    ? <RecentSupportMe userId={user.id}/>
                    : null
                }
                {/*<a className='seeMore' href="/">+ see more</a>*/}
            </div>
            <DoubleShapeTop/>
            <DoubleShapeBottom/>
        </section>
    );
};

SupportSection.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
    user: PropTypes.shape({
        id: PropTypes.string,
        bio: PropTypes.string,
        email: PropTypes.string,
        username: PropTypes.string,
        //todo use shape
        billing_info: PropTypes.object,
        //todo use shape
        profile_picture: PropTypes.object,
    })
};

export default withTranslation()(SupportSection);
