import React, {Suspense} from 'react';
import './styles/app.scss'
import 'react-image-crop/dist/ReactCrop.css';
import 'react-placeholder/lib/reactPlaceholder.css';
import {Provider} from "react-redux";
import store from "./store";
import {library} from '@fortawesome/fontawesome-svg-core'
import {
    fab,
    faFacebookF,
    faInstagram,
    faLinkedinIn,
    faStripe,
    faTiktok,
    faTwitter
} from '@fortawesome/free-brands-svg-icons'
import App from "./container/App";
import reportWebVitals from './reportWebVitals';
import ReactGA from 'react-ga';
import {
    faAddressCard,
    faBan,
    faBug,
    faCaretDown,
    faCheck,
    faCircleCheck,
    faCircleChevronDown,
    faCircleChevronUp,
    faCircleNotch,
    faClipboard,
    faClone,
    faCommentDollar,
    faCompass,
    faCopy,
    faEdit,
    faEnvelope,
    faEuroSign,
    faExclamation,
    faExclamationCircle,
    faEye,
    faFileArrowUp,
    faFlag,
    faHandHoldingUsd,
    faHashtag,
    faHeadset,
    faHeart,
    faIdCard,
    faInfo,
    faLink,
    faLock,
    faPlus,
    faRobot,
    faStoreAlt,
    faTicket,
    faTimes,
    faTrash,
    faTrashAlt,
    faUser,
    faUserGear,
    faUserPen,
    faUserTag,
    faXmarkCircle,
} from "@fortawesome/free-solid-svg-icons";
import './i18n';
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";
import {loadStripe} from "@stripe/stripe-js/pure";
import {Elements} from "@stripe/react-stripe-js";
import {ChakraProvider} from "@chakra-ui/react";
import "@fontsource/quicksand";
import {createRoot} from "react-dom/client";
import theme from "./theme";

library.add(
    fab,
    faAddressCard,
    faBan,
    faBug,
    faCaretDown,
    faClone,
    faClipboard,
    faCommentDollar,
    faCompass,
    faCopy,
    faCircleChevronDown,
    faCircleChevronUp,
    faCircleCheck,
    faCircleNotch,
    faEdit,
    faEye,
    faEuroSign,
    faEnvelope,
    faExclamation,
    faExclamationCircle,
    faFacebookF,
    faFileArrowUp,
    faInfo,
    faLink,
    faLinkedinIn,
    faLock,
    faHeart,
    faHeadset,
    faStripe,
    faTrash,
    faTwitter,
    faCheck,
    faTimes,
    faTrashAlt,
    faTiktok,
    faTicket,
    faStoreAlt,
    faPlus,
    faRobot,
    faUserPen,
    faUserTag,
    faHashtag,
    faHandHoldingUsd,
    faIdCard,
    faInstagram,
    faUser,
    faUserGear,
    faFlag,
    faXmarkCircle,
)
;

const stripePromise = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
    ? loadStripe('pk_test_51IOdPdGuYEF7FAvHtorDYh09eEp0s0N6NipKrfeVoFUp4YTSA7VqYkvd0sbPXCfkhWKVQHxlV1bGmxDw8Aecbo7n00WgDRS7Fe')
    : loadStripe('pk_live_51IOdPdGuYEF7FAvHJJ43G9wUo5wv6apCKx731ELqkFCF2InNHKHFUpV1xewJWJsd0p7f5qiF1PxcRNYwpn3kDnUz00p8ZSiEbG');

!process.env.NODE_ENV || process.env.NODE_ENV === 'development'
    ? ReactGA.initialize('UA-000000-01', {debug: true})
    : ReactGA.initialize('UA-165954401-1')

// ReactGA.set({
//     userId: auth.currentUserId(),
//     // any data that is relevant to the user session
//     // that you would like to track with google analytics
// })

!process.env.NODE_ENV || process.env.NODE_ENV === 'development'
    ? Sentry.init({
        dsn: "",
        integrations: [new Integrations.BrowserTracing()],

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,
    })
    : Sentry.init({
        dsn: "https://0520d5daaaec4baa9e6e5e0ae4cf85df@o233657.ingest.sentry.io/5641672",
        integrations: [new Integrations.BrowserTracing()],

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,
    })

const root = createRoot(document.getElementById('root')!);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <Suspense fallback={(<div>Loading</div>)}>
                <Elements stripe={stripePromise}>
                    <ChakraProvider theme={theme}>
                        <App/>
                    </ChakraProvider>
                </Elements>
            </Suspense>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// reportWebVitals(console.log);
