import {linkService} from "../services/linkService";
import {linkConstants} from "../constants/action-types";
import {baseApi} from "../services/apiService";
import {errorAlert, successAlert} from "../slicers/alertsSlice";


const create = (link) => {
    return (dispatch, getState) => {
        if (getState().links.isSending === true) {
            return;
        }
        dispatch(request(link));

        linkService.create(link)
            .then(
                link => {
                    dispatch(success(link));
                    dispatch(baseApi.endpoints.getCurrentUser.initiate(undefined, {forceRefetch: true}))
                    dispatch(successAlert('Link Created'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(errorAlert(error.toString()));
                }
            );
    };

    function request(link) {
        return {type: linkConstants.CREATE_REQUEST, link}
    }

    function success(link) {
        return {type: linkConstants.CREATE_SUCCESS, link}
    }

    function failure(error) {
        return {type: linkConstants.CREATE_FAILURE, error}
    }
}

const update = (link) => {
    return (dispatch, getState) => {
        if (getState().links.isSending === true) {
            return;
        }
        dispatch(request(link));

        linkService.update(link)
            .then(
                link => {
                    dispatch(success(link));
                    dispatch(baseApi.endpoints.getCurrentUser.initiate(undefined, {forceRefetch: true}))
                    dispatch(successAlert('Link Updated'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(errorAlert(error.toString()));
                }
            );
    };

    function request(link) {
        return {type: linkConstants.UPDATE_REQUEST, link}
    }

    function success(link) {
        return {type: linkConstants.UPDATE_SUCCESS, link}
    }

    function failure(error) {
        return {type: linkConstants.UPDATE_FAILURE, error}
    }
}

// prefixed function name with underscore because delete is a reserved word in javascript
const _delete = (id) => {
    return (dispatch, getState) => {
        if (getState().links.isSending === true) {
            return;
        }
        dispatch(request(id));

        linkService.delete(id)
            .then(
                link => {
                    dispatch(success(link));
                    dispatch(baseApi.endpoints.getCurrentUser.initiate(undefined, {forceRefetch: true}))
                    dispatch(successAlert('Link Deleted'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(errorAlert(error.toString()));
                }
            );
    };

    function request(id) {
        return {type: linkConstants.DELETE_REQUEST, id}
    }

    function success(id) {
        return {type: linkConstants.DELETE_SUCCESS, id}
    }

    function failure(id, error) {
        return {type: linkConstants.DELETE_FAILURE, id, error}
    }
}

export const linkActions = {
    create,
    update,
    delete: _delete
};
