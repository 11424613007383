const fontSizes = {
    xs: "0.75rem", // 12px / 16px = 0.75rem
    sm: "0.875rem", // 14px / 16px = 0.875rem
    md: "1rem", // 16px / 16px = 1rem
    lg: "1.125rem", // 18px / 16px = 1.125rem
    xl: "1.25rem", // 20px / 16px = 1.25rem
    "2xl": "1.5rem", // 24px / 16px = 1.5rem
    "3xl": "1.75rem", // 28px / 16px = 1.75rem
    "4xl": "2rem", // 32px / 16px = 2rem
    "5xl": "3rem", // 48px / 16px = 3rem
    "6xl": "3.5rem", // 56px / 16px = 3.5rem
    "7xl": "4rem", // 64px / 16px = 4rem
    "8xl": "5rem", // 80px / 16px = 5rem
};

export default fontSizes;
