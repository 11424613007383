import React, {useState} from "react";
import {Box, Button, Container, Grid, GridItem, HStack, Image, Text, VStack,} from "@chakra-ui/react";
import PlanCard from "./PlanCard";
import {bounceAnimation} from "../../../../helpers/animation";

const plans = [
    {
        planName: "Free",
        perMonthPrice: "0€",
        perYearPrice: "0€",
        title: "Great for starting creators or small companies!",
        desc: "Monetization, unlimited links, limited email subscription, and sending. Customizable Quickg profile to take control over your online image.",
        btnText: "Sign up for free",
        showBtnOutline: false,
        comingSoon: false,
        moreInfo: [
            {
                heading: "Profile",
                items: [
                    {
                        name: "Private profile",
                        tick: true,
                        showComingSoon: false,
                        showFreeTrial: false,
                    },
                    {
                        name: "Profile badge",
                        tick: false,
                        showComingSoon: true,
                        showFreeTrial: false,
                    },
                    {
                        name: "Profile in discovery",
                        tick: false,
                        showComingSoon: false,
                        showFreeTrial: true,
                    },
                ],
            },
            {
                heading: "Support Me",
                items: [
                    {
                        name: (
                            <>
                                <b>5% Fee + </b>
                                <Text as="span" fontSize="xs">
                                    Stripe fees
                                </Text>
                            </>
                        ),
                        tick: true,
                        showComingSoon: false,
                        showFreeTrial: false,
                    },
                ],
            },
            {
                heading: "Hashtags",
                items: [
                    {
                        name: (
                            <>
                                <b> Max 3 </b><br/> custom hashtags
                            </>
                        ),
                        tick: true,
                        showComingSoon: false,
                        showFreeTrial: false,
                    },
                    {
                        name: <>Copy custom hashtags</>,
                        tick: true,
                        showComingSoon: false,
                        showFreeTrial: false,
                    },
                ],
            },
            {
                heading: "Metrics",
                items: [
                    {
                        name: (
                            <>
                                <b> 7 days </b>
                                history
                            </>
                        ),
                        tick: true,
                        showComingSoon: true,
                        showFreeTrial: false,
                    },
                    {
                        name: <>Profile visits</>,
                        tick: true,
                        showComingSoon: false,
                        showFreeTrial: false,
                    },
                    {
                        name: <>See who visited profile</>,
                        tick: false,
                        showComingSoon: true,
                        showFreeTrial: false,
                    },
                    {
                        name: <>Discovery visits</>,
                        tick: false,
                        showComingSoon: false,
                        showFreeTrial: true,
                    },
                    {
                        name: <>Hashtag clicks</>,
                        tick: true,
                        showComingSoon: false,
                        showFreeTrial: false,
                    },
                    {
                        name: <>Social media clicks</>,
                        tick: false,
                        showComingSoon: false,
                        showFreeTrial: false,
                    },
                    {
                        name: <>Link click</>,
                        tick: true,
                        showComingSoon: false,
                        showFreeTrial: false,
                    },
                    {
                        name: <>Affiliate link clicks</>,
                        tick: false,
                        showComingSoon: true,
                        showFreeTrial: false,
                    },
                ],
            },
            {
                heading: "Email subscription",
                items: [
                    {
                        name: (
                            <>
                                <b> Max 50 </b>
                                subscribers
                            </>
                        ),
                        tick: true,
                        showComingSoon: false,
                        showFreeTrial: false,
                    },
                    {
                        name: <>Custom message on subscribe</>,
                        tick: false,
                        showComingSoon: true,
                        showFreeTrial: false,
                    },
                    {
                        name: <><b>PDF/CSV</b> Export of subscribers</>,
                        tick: false,
                        showComingSoon: true,
                        showFreeTrial: false,
                    },
                ],
            },
            {
                heading: "Email sending",
                items: [
                    {
                        name: (
                            <>
                                <b> 1</b> email per <b>2</b> weeks
                            </>
                        ),
                        tick: true,
                        showComingSoon: true,
                        showFreeTrial: false,
                    },
                    {
                        name: (
                            <>
                                <b>Max 50 </b> emails per month
                            </>
                        ),
                        tick: true,
                        showComingSoon: true,
                        showFreeTrial: false,
                    },
                    {
                        name: (
                            <>
                                {" "}
                                <b>0.005€ </b> per additional mail
                            </>
                        ),
                        tick: true,
                        showComingSoon: true,
                        showFreeTrial: false,
                    },
                ],
            },
        ],
    },
    {
        planName: "Standard",
        perMonthPrice: "3€",
        perYearPrice: "30€",
        title: "Great for existing creators or medium companies!",
        desc: "Grow faster, reach further. See more metrics and learn about your supporters. Reach more of your subscribers through Quickg.",
        btnText: "Coming soon!",
        showBtnOutline: true,
        comingSoon: true,
        moreInfo: [
            {
                heading: "Profile",
                items: [
                    {
                        name: "Private profile",
                        tick: true,
                        showComingSoon: false,
                        showFreeTrial: false,
                    },
                    {
                        name: "Profile badge",
                        tick: true,
                        showComingSoon: true,
                        showFreeTrial: false,
                    },
                    {
                        name: "Profile in discovery",
                        tick: true,
                        showComingSoon: false,
                        showFreeTrial: false,
                    },
                ],
            },
            {
                heading: "Support Me",
                items: [
                    {
                        name: (
                            <>
                                <b>5% Fee + </b>
                                <Text as="span" fontSize="xs">
                                    Stripe fees
                                </Text>
                            </>
                        ),
                        tick: true,
                        showComingSoon: false,
                        showFreeTrial: false,
                    },
                ],
            },
            {
                heading: "Hashtags",
                items: [
                    {
                        name: (
                            <>
                                <b> Max 6 </b><br/> custom hashtags
                            </>
                        ),
                        tick: true,
                        showComingSoon: true,
                        showFreeTrial: false,
                    },
                    {
                        name: <>Copy custom hashtags</>,
                        tick: true,
                        showComingSoon: false,
                        showFreeTrial: false,
                    },
                ],
            },
            {
                heading: "Metrics",
                items: [
                    {
                        name: (
                            <>
                                <b> 30 days </b>
                                history
                            </>
                        ),
                        tick: true,
                        showComingSoon: true,
                        showFreeTrial: false,
                    },
                    {
                        name: <>Profile visits</>,
                        tick: true,
                        showComingSoon: false,
                        showFreeTrial: false,
                    },
                    {
                        name: <>See who visited profile</>,
                        tick: true,
                        showComingSoon: true,
                        showFreeTrial: false,
                    },
                    {
                        name: <>Discovery visits</>,
                        tick: true,
                        showComingSoon: false,
                        showFreeTrial: false,
                    },
                    {
                        name: <>Hashtag clicks</>,
                        tick: true,
                        showComingSoon: false,
                        showFreeTrial: false,
                    },
                    {
                        name: <>Social media clicks</>,
                        tick: true,
                        showComingSoon: false,
                        showFreeTrial: false,
                    },
                    {
                        name: <>Link click</>,
                        tick: true,
                        showComingSoon: false,
                        showFreeTrial: false,
                    },
                    {
                        name: <>Affiliate link clicks</>,
                        tick: false,
                        showComingSoon: true,
                        showFreeTrial: false,
                    },
                ],
            },
            {
                heading: "Email subscription",
                items: [
                    {
                        name: (
                            <>
                                <b> Unlimited </b>
                                subscribers
                            </>
                        ),
                        tick: true,
                        showComingSoon: false,
                        showFreeTrial: false,
                    },
                    {
                        name: <>Custom message on subscribe</>,
                        tick: true,
                        showComingSoon: true,
                        showFreeTrial: false,
                    },
                    {
                        name: <><b>PDF</b> Export of subscribers</>,
                        tick: true,
                        showComingSoon: true,
                        showFreeTrial: false,
                    },
                ],
            },
            {
                heading: "Email sending",
                items: [
                    {
                        name: (
                            <>
                                <b> 1</b> email per <b>week</b>
                            </>
                        ),
                        tick: true,
                        showComingSoon: true,
                        showFreeTrial: false,
                    },
                    {
                        name: (
                            <>
                                <b>Max 1000 </b> emails per month
                            </>
                        ),
                        tick: true,
                        showComingSoon: true,
                        showFreeTrial: false,
                    },
                    {
                        name: (
                            <>
                                {" "}
                                <b>0.003€ </b> per additional mail
                            </>
                        ),
                        tick: true,
                        showComingSoon: true,
                        showFreeTrial: false,
                    },
                ],
            },
        ],
    },
    {
        planName: "Professional",
        perMonthPrice: "12€",
        perYearPrice: "120€",
        title: "Great for existing creators or medium companies!",
        desc: "Monetization, unlimited links, limited email subscription, and sending. Customizable Quickg profile to take control over your online image.",
        btnText: "Coming soon!",
        showBtnOutline: true,
        comingSoon: true,
        moreInfo: [
            {
                heading: "Profile",
                items: [
                    {
                        name: "Private profile",
                        tick: true,
                        showComingSoon: false,
                        showFreeTrial: false,
                    },
                    {
                        name: "Profile badge",
                        tick: true,
                        showComingSoon: true,
                        showFreeTrial: false,
                    },
                    {
                        name: "Profile in discovery",
                        tick: true,
                        showComingSoon: false,
                        showFreeTrial: false,
                    },
                ],
            },
            {
                heading: "Support Me",
                items: [
                    {
                        name: (
                            <>
                                <b>5% Fee + </b>
                                <Text as="span" fontSize="xs">
                                    Stripe fees
                                </Text>
                            </>
                        ),
                        tick: true,
                        showComingSoon: false,
                        showFreeTrial: false,
                    },
                ],
            },
            {
                heading: "Hashtags",
                items: [
                    {
                        name: (
                            <>
                                <b> Unlimited </b><br/> custom hashtags
                            </>
                        ),
                        tick: true,
                        showComingSoon: true,
                        showFreeTrial: false,
                    },
                    {
                        name: <>Copy custom hashtags</>,
                        tick: true,
                        showComingSoon: false,
                        showFreeTrial: false,
                    },
                ],
            },
            {
                heading: "Metrics",
                items: [
                    {
                        name: (
                            <>
                                <b> 6 months </b>
                                history
                            </>
                        ),
                        tick: true,
                        showComingSoon: true,
                        showFreeTrial: false,
                    },
                    {
                        name: <>Profile visits</>,
                        tick: true,
                        showComingSoon: false,
                        showFreeTrial: false,
                    },
                    {
                        name: <>See who visited profile</>,
                        tick: true,
                        showComingSoon: true,
                        showFreeTrial: false,
                    },
                    {
                        name: <>Discovery visits</>,
                        tick: true,
                        showComingSoon: false,
                        showFreeTrial: false,
                    },
                    {
                        name: <>Hashtag clicks</>,
                        tick: true,
                        showComingSoon: false,
                        showFreeTrial: false,
                    },
                    {
                        name: <>Social media clicks</>,
                        tick: true,
                        showComingSoon: false,
                        showFreeTrial: false,
                    },
                    {
                        name: <>Link click</>,
                        tick: true,
                        showComingSoon: false,
                        showFreeTrial: false,
                    },
                    {
                        name: <>Affiliate link clicks</>,
                        tick: true,
                        showComingSoon: true,
                        showFreeTrial: false,
                    },
                ],
            },
            {
                heading: "Email subscription",
                items: [
                    {
                        name: (
                            <>
                                <b> Unlimited </b>
                                subscribers
                            </>
                        ),
                        tick: true,
                        showComingSoon: false,
                        showFreeTrial: false,
                    },
                    {
                        name: <>Custom message on subscribe</>,
                        tick: true,
                        showComingSoon: true,
                        showFreeTrial: false,
                    },
                    {
                        name: <><b>PDF/CSV</b> Export of subscribers</>,
                        tick: true,
                        showComingSoon: true,
                        showFreeTrial: false,
                    },
                ],
            },
            {
                heading: "Email sending",
                items: [
                    {
                        name: (
                            <>
                                <b> 3</b> email per <b>week</b>
                            </>
                        ),
                        tick: true,
                        showComingSoon: true,
                        showFreeTrial: false,
                    },
                    {
                        name: (
                            <>
                                <b>Max 3000 </b> emails per month
                            </>
                        ),
                        tick: true,
                        showComingSoon: true,
                        showFreeTrial: false,
                    },
                    {
                        name: (
                            <>
                                {" "}
                                <b>0.002€ </b> per additional mail
                            </>
                        ),
                        tick: true,
                        showComingSoon: true,
                        showFreeTrial: false,
                    },
                ],
            },
        ],
    },
];

const PlanSection = () => {
    const [isYearly, setIsYearly] = useState(false);
    return (
        <Box py="60px" position="relative" id={'pricing'}>
            <Container maxW="1080px">
                <VStack spacing={6}>
                    <Text textAlign="center" textStyle="h2" color="text.400">
                        Plan Selection
                    </Text>

                    <HStack
                        w="fit-content"
                        justify="center"
                        borderRadius="full"
                        bg="#F4F4F4"
                        h="52px"
                    >
                        <Button
                            onClick={() => setIsYearly(false)}
                            bg={!isYearly ? "brand.dark" : "#F4F4F4"}
                            color={!isYearly ? "white" : "#8E8E8E"}
                        >
                            Monthly
                        </Button>
                        <Button
                            onClick={() => setIsYearly(true)}
                            bg={isYearly ? "brand.dark" : "#F4F4F4"}
                            color={isYearly ? "white" : "#8E8E8E"}
                        >
                            Yearly
                        </Button>
                    </HStack>

                    <Grid
                        templateColumns={{base: "repeat(1, 1fr)", lg: "repeat(3, 1fr)"}}
                        gap={8}
                        justifyContent={"center"}
                    >
                        {plans.map(
                            ({
                                 planName,
                                 perMonthPrice,
                                 perYearPrice,
                                 title,
                                 desc,
                                 btnText,
                                 showBtnOutline,
                                 comingSoon,
                                 moreInfo,
                             }) => (
                                <GridItem key={planName}>
                                    <PlanCard
                                        planName={planName}
                                        perMonthPrice={perMonthPrice}
                                        perYearPrice={perYearPrice}
                                        title={title}
                                        desc={desc}
                                        btnText={btnText}
                                        showBtnOutline={showBtnOutline}
                                        comingSoon={comingSoon}
                                        moreInfo={moreInfo}
                                        isYearly={isYearly}
                                    />
                                </GridItem>
                            )
                        )}
                    </Grid>
                </VStack>

                <Box
                    display={{base: "none", lg: "block"}}
                    animation={`${bounceAnimation} 5s infinite`}
                    position="absolute"
                    bottom={140}
                    right={"60px"}
                >
                    <Image src="/icons/rect_big.svg"/>
                </Box>
                <Box
                    display={{base: "none", lg: "block"}}
                    animation={`${bounceAnimation} 5s infinite`}
                    position="absolute"
                    top={140}
                    left={76}
                >
                    <Image src="/icons/rect_big.svg"/>
                </Box>
                <Box
                    display={{base: "none", lg: "block"}}
                    animation={`${bounceAnimation} 5s infinite`}
                    position="absolute"
                    bottom={0}
                    right={1}
                >
                    <Image src="/icons/rect_small.svg"/>
                </Box>
                <Box
                    display={{base: "none", lg: "block"}}
                    animation={`${bounceAnimation} 5s infinite`}
                    top={0}
                    left={1}
                    position="absolute"
                >
                    <Image src="/icons/rect_small.svg"/>
                </Box>
            </Container>
        </Box>
    );
};

export default PlanSection;
