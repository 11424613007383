// import config from 'config';
import {authHeader} from "../helpers/authHeader";
import {URL} from "../constants/API";
import {handleDelete, handleResponse} from "../helpers/handleResponse";

export const profilePictureService = {
    create,
    delete: _delete
};
const config = {
    apiUrl: URL.concat('/api')
}

function create(id, file) {
    const requestOptions = {
        method: 'POST',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
        body: JSON.stringify({filename: `profilePicture_${id}`, file: file})
    };

    return fetch(`${config.apiUrl}/users/${id}/picture`, requestOptions).then(handleResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}/links/${id}`, requestOptions).then(handleDelete);
}
