import {Box, Flex, Image, Stack, Text, VStack} from "@chakra-ui/react";
import React from "react";

interface BenefitItemProps {
    img: string;
    headingDark: string;
    headingColored: string;
    desc: string;
    ctaSection: React.ReactNode;
    reverse?: boolean;
}

const BenefitItem: React.FC<BenefitItemProps> = (
    {
        img,
        headingDark,
        headingColored,
        desc,
        ctaSection,
        reverse = false,
    }) => {
    return (
        <Flex
            px="2"
            py={{
                base: 2,
                lg: 8,
            }}
            gap={{
                base: 2,
                lg: 8,
            }}
            flexDir={{
                base: "column",
                lg: reverse ? "row-reverse" : "row",
            }}
            justify={"center"}
            align={{
                base: "center",
                lg: "flex-start",
            }}
        >
            <Box flex="1">
                <Image src={img}/>
            </Box>

            <Stack flex="1" py={6} spacing={0}>
                <Flex
                    justify={{
                        base: "center",
                        lg: "flex-start",
                    }}
                >
                    <Text textStyle="h3" fontWeight="bold" color="brand.dark">
                        {headingColored}
                    </Text>
                    <Text ml={2} textStyle="h3" color="text.400" fontWeight="bold">
                        {headingDark}
                    </Text>
                </Flex>
                <Text
                    maxW="600px"
                    textAlign={{
                        base: "center",
                        lg: "left",
                    }}
                    mb="9"
                    textStyle={{
                        lg: "h6",
                        base: "p1",
                    }}
                    color="text.400"
                >
                    {desc}
                </Text>
                <VStack
                    align={{
                        base: "center",
                        lg: "flex-start",
                    }}
                >
                    {ctaSection}
                </VStack>
            </Stack>
        </Flex>
    );
};

export default BenefitItem;
