import {Navigate, Outlet} from 'react-router-dom'
import {useSelector} from "react-redux";
import React from "react";
import {isLoggedIn} from "../slicers/authSlice";

export function PrivateOutlet() {
    const auth = useSelector(state => state.auth);

    // if (isFetching) {
    // //     todo loading
    //     return null
    // }
    if (!isLoggedIn(auth)) {
        return <Navigate to="/login" state={{from: location}}/>
    }

    return <Outlet/>
}
