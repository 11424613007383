import React from 'react';
import UserPhotoPlaceholderImg from '../../../styles/images/profile-user.svg'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import {userPrivateTypeStrict, userPublicType} from "../../../types";
import {withTranslation} from "react-i18next";
import {URL} from "../../../constants/API";
import {
    adultContent,
    enabledContactMe,
    hasHashtags,
    showEmail,
    supportedLinks
} from "../../../helpers/userSettingsHelper";
import {useDispatch, useSelector} from "react-redux";
import ReportOffcanvas from "../../Report/ReportOffcanvas";
import {showOffcanvasUserReport} from "../../../slicers/reportOffcanvasSlice";
import UserPrivateInfo from "../PrivateProfile/UserPrivateInfo";
import {successAlert} from "../../../slicers/alertsSlice";
import Clipboard from "react-clipboard.js";
import {useCreateMetricMutation} from "../../../services/metricService";

const UserInfo = ({t, i18n, user, isPrivateProfile = false}) => {
    const offcanvasState = useSelector(state => state.reportOffcanvas);

    return (
        <section className='adminInfo'>
            <ReportOffcanvas offcanvasState={offcanvasState}/>
            <div className="container d-flex align-items-center flex-column justify-content-center text-center">
                <div className="dp">
                    <img className={'bg-primary'}
                         src={user.profile_picture?.url ? URL.concat(user.profile_picture.url) : UserPhotoPlaceholderImg}
                         alt="user.username"/>
                </div>
                <h3 className={adultContent(user) ? 'name eighteenplus' : 'name'}>{user.username} </h3>
                {isPrivateProfile ? <UserPrivateInfo user={user}/> : <UserPublicInfo user={user}/>}
            </div>
        </section>
    )
}

UserInfo.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
    isPrivateProfile: PropTypes.bool,
    user: PropTypes.oneOfType([userPrivateTypeStrict, userPublicType])
};

export default withTranslation()(UserInfo);

const UserPublicInfo = ({user}) => {
    const dispatch = useDispatch()
    const [submitMetric] = useCreateMetricMutation()

    return (
        <>
            <p className="bio">{user.bio}</p>
            <span className={'link-primary report-btn'}
                  onClick={() => dispatch(showOffcanvasUserReport({user: user.id}))}> <FontAwesomeIcon
                icon={'flag'}/></span>
            {showEmail(user) && <p className='email'>{user.email}</p>}

            {user.social_links !== null && user.social_links !== undefined ?
                <ul className="social-icons">
                    {Object.keys(user.social_links).map(key =>
                        user.social_links[key] !== null
                            ? <li key={key}>
                                <a
                                    href={supportedLinks.find(e => e.type === key).link.concat(user.social_links[key])}
                                    rel="noreferrer"
                                    target='_blank'
                                    onClick={() => submitMetric({
                                        entity: 'SocialMediaLink',
                                        entityId: key,
                                        userAction: 'click',
                                        user: user.id
                                    })}
                                >
                                    <FontAwesomeIcon icon={['fab', key]}/>
                                </a>
                            </li>
                            : null
                    )}
                </ul> : null}
            {hasHashtags(user)
                ? <div className="tags">
                    {user.profile_settings?.hashtags?.hashtags.map(
                        (hashtag, key) =>
                            <Clipboard key={key}
                                       className='mb-2'
                                       onSuccess={
                                           () => {
                                               dispatch(successAlert('copy.profileHashtag'))
                                               submitMetric({
                                                   entity: 'Hashtag',
                                                   entityId: 'hashtag',
                                                   userAction: 'copy',
                                                   user: user.id
                                               })
                                           }
                                       }
                                       data-clipboard-text={`${hashtag}`}>
                                {hashtag}
                            </Clipboard>)}
                    {/*<button className='seeMore disabled'>+ See More</button>*/}
                </div>
                : null}
            {
                (showEmail(user) || enabledContactMe(user)) &&
                <div className="contact-btns mt-1 mb-5">
                    {enabledContactMe(user) && <a href="/" className='btn btn-outline-primary'>Contact Me</a>}
                    {showEmail(user) && <a href={`mailto:${user.email}?subject=Contact%20from%20Quickg`}
                                           className='btn btn-outline-primary'>Send email</a>}
                </div>
            }
        </>
    )
}

UserPublicInfo.propTypes = {
    user: PropTypes.oneOfType([userPrivateTypeStrict, userPublicType])
};
