import {SettingHeader, SettingHeaderContainer, Status} from "../../../styled-components/S_Setting";
import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";


const Header = ({t, i18n, children, danger, status, disabled}) => {
    return (
        <SettingHeaderContainer>
            <SettingHeader>{children}</SettingHeader>
            {status && <Status red={danger} disabled={disabled}>{t(status)}</Status>}
        </SettingHeaderContainer>
    );
}

Header.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    danger: PropTypes.bool,
    disabled: PropTypes.bool,
    status: PropTypes.string,
};

export default withTranslation()(Header);
