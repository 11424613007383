import {createSlice} from '@reduxjs/toolkit'
import {reportApi} from "../services/reportService";

const reportOffcanvasInitialState = {
    showOffcanvas: false,
    user: null,
    reason: 'fake',
    message: '',
};

const reporOffcanvasSlice = createSlice({
    name: 'reporOffcanvas',
    initialState: reportOffcanvasInitialState,
    reducers: {
        showOffcanvasUserReport: (state, {payload: {user}}) => {
            state.showOffcanvas = true
            state.user = user
        },
        hideOffcanvas: (state, action) => {
            return reportOffcanvasInitialState
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(reportApi.endpoints.createReport.matchFulfilled,
            (state, action) => {
                return reportOffcanvasInitialState
            }
        )
    },
})

export const {showOffcanvasUserReport, hideOffcanvas} = reporOffcanvasSlice.actions

export default reporOffcanvasSlice.reducer
