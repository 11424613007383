import React from "react";
import {Form} from "react-bootstrap";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {useDispatch} from "react-redux";
import {bool, object, setLocale, string} from "yup";
import {Formik} from "formik";
import * as ReactGA from "react-ga";
import {linkActions} from "../../../actions/link";
import {IconInput, LinkInput, LinkRow, SettingInput, SwitchControl, Text} from "../../../styled-components/S_Setting";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {linkType} from "../../../types";

const LinkItem = ({t, i18n, index, link}) => {
    const dispatch = useDispatch();

    setLocale({
        string:
            {url: 'This must be a valid URL (https://site.com)'}
    });

    const schema = object({
        enabled: bool().required(),
        name: string().when('enabled', {
            is: true,
            then: string().required()
        }),
        url: string().when('enabled', {
            is: true,
            then: string().url().required()
        }),
    });

    return (
        <Formik
            key={`links.${link.id ?? index}`}
            validationSchema={schema}
            validateOnMount={true}
            onSubmit={(values, {setSubmitting, resetForm}) => {
                ReactGA.event({
                    category: 'Links',
                    action: 'Edit Links'
                });
                setSubmitting(true);
                dispatch(linkActions.update(values))
                setSubmitting(false);
                resetForm({values: values})
            }}
            enableReinitialize={true}
            initialValues={link}>
            {({
                  handleSubmit,
                  handleChange,
                  handleReset,
                  handleBlur,
                  values,
                  dirty,
                  touched,
                  isValid,
                  isSubmitting,
                  errors,
                  setFieldValue,
                  setFieldTouched,
              }) => (
                <LinkRow isPublish={!values.enabled ? "showGray" : null}>
                    <Form noValidate onSubmit={handleSubmit}>
                        <Text>Name</Text>
                        <LinkInput>
                            <Form.Control
                                as={SettingInput}
                                id={`links.${link.id ?? index}.name`}
                                name={`name`}
                                value={values.name}
                                onBlur={e => {
                                    handleBlur(e)
                                    setTimeout(() => {
                                        if (dirty && isValid)
                                            handleSubmit(e)
                                    }, 0);
                                }}
                                onChange={handleChange}
                                placeholder="Name"
                                isValid={touched.name && !errors.name}
                                isInvalid={!!errors.name}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.name}
                            </Form.Control.Feedback> <IconInput hasValue>
                            <div>
                                <FontAwesomeIcon icon={'link'}/>
                                <Form.Control
                                    type="text"
                                    id={`links.${link.id ?? index}.url`}
                                    name="url"
                                    value={values.url}
                                    onChange={handleChange}
                                    placeholder="Link"
                                    onBlur={e => {
                                        handleBlur(e)
                                        setTimeout(() => {
                                            if (dirty && isValid)
                                                handleSubmit(e)
                                        }, 0);
                                    }}
                                    isValid={touched.url && !errors.url}
                                    isInvalid={!!errors.url}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.url}
                                </Form.Control.Feedback>
                            </div>
                        </IconInput>
                            <SwitchControl>
                                <div className="trash" onClick={event => {
                                    dispatch(linkActions.delete(values.id))
                                }}>
                                    <FontAwesomeIcon className={'trash'} icon={'trash'}/>
                                    <p>Delete</p>
                                </div>

                                <div>
                                    <Form.Check
                                        className={'styled-switch'}
                                        type="switch"
                                        // className="mb-2 me-sm-2"
                                        id={`links.${link.id ?? index}.enabled`}
                                        // label="Visible"
                                        name={`enabled`}
                                        checked={values.enabled === true}
                                        feedback={errors.enabled}
                                        onBlur={e => {
                                            handleBlur(e)
                                            setTimeout(() => {
                                                if (dirty && isValid)
                                                    handleSubmit(e)
                                            }, 0);
                                        }}
                                        onChange={e => {
                                            handleChange(e)
                                            setTimeout(() => {
                                                if (isValid)
                                                    handleSubmit(e)
                                            }, 0);
                                        }}
                                    />
                                    <p>{values.enabled === true ? "Link published" : "Link unpublished"}</p>
                                </div>
                            </SwitchControl>
                        </LinkInput>
                    </Form>
                </LinkRow>
            )}
        </Formik>
    );
};

LinkItem.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    link: linkType.isRequired
};

export default withTranslation()(LinkItem);
