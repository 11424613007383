import React from "react";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {Button, Offcanvas, ProgressBar, Stack, ToggleButton} from "react-bootstrap";
import {useDispatch} from "react-redux";
import {supportMeActions} from "../../../../actions/supportMe";
import PaymentForm from "./PaymentForm";
import {supportMeType} from "../../../../types";
import PayIntentForm from "./PayIntentForm";

const SupportMeOffcanvas = ({t, i18n, supportMe, resetAmountForm}) => {
    const dispatch = useDispatch()

    return (
        <Offcanvas className={'offcanvas-width'} key={'id+amount'} show={supportMe.showOffcanvas} onHide={() => {
            dispatch(supportMeActions.hideOffcanvas())
            dispatch(supportMeActions.changeStep(0))
        }} placement={'end'}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title> {t('supportMe.title')} - {supportMe.amount}€</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                {supportMe.step === 0
                    ? <PayIntentForm supportMe={supportMe}/>
                    : <PaymentForm supportMe={supportMe} resetAmountForm={resetAmountForm}/>
                }
                <div className="position-relative m-5 d-none d-md-block">
                    <ProgressBar now={supportMe.step === 0 ? 0 : supportMe.step === 1 ? 50 : 100}/>

                    <ToggleButton
                        className={'mx-5 my-2 px-5 position-absolute top-0 start-0 translate-middle'}
                        key={0}
                        id={`radio-0`}
                        variant="secondary"
                        value={0}
                        active={supportMe.step === 0}
                        // disabled={supportMe.step === 0}
                        onClick={(e) => dispatch(supportMeActions.changeStep(0))}
                    >
                        1
                    </ToggleButton>
                    <ToggleButton
                        className={'m-2 px-5 position-absolute top-0 start-50 translate-middle'}
                        key={1}
                        id={`radio-1`}
                        variant="secondary"
                        value={1}
                        active={supportMe.step === 1}
                        disabled={supportMe.step !== 1}
                        // onClick={(e) => console.log(!formikRef.current?.isValid)}
                    >
                        2
                    </ToggleButton>
                    <Button
                        className={'mx-0 my-2 px-5  position-absolute top-0 end-0 translate-middle-y'}
                        key={2}
                        id={`radio-1`}
                        variant="success"
                        disabled={true}
                    >
                        Done!
                    </Button>
                </div>

                <div className="position-relative m-5 d-md-none">
                    <Stack direction="vertical" gap={3}>
                        <ProgressBar now={supportMe.step === 0 ? 0 : supportMe.step === 1 ? 50 : 100}/>
                        <ToggleButton
                            className={'mx-auto px-5'}
                            key={0}
                            id={`radio-0`}
                            variant="secondary"
                            value={0}
                            active={supportMe.step === 0}
                            // disabled={supportMe.step === 0}
                            onClick={(e) => dispatch(supportMeActions.changeStep(0))}
                        >
                            1
                        </ToggleButton>
                        <ToggleButton
                            className={'mx-auto px-5'}
                            key={1}
                            id={`radio-1`}
                            variant="secondary"
                            value={1}
                            active={supportMe.step === 1}
                            disabled={supportMe.step !== 1}
                            // onClick={(e) => console.log(!formikRef.current?.isValid)}
                        >
                            2
                        </ToggleButton>
                        <Button
                            className={'mx-auto px-5'}
                            key={2}
                            id={`radio-1`}
                            variant="success"
                            disabled={true}
                        >
                            Done!
                        </Button>
                    </Stack>

                </div>
            </Offcanvas.Body>
        </Offcanvas>
    )
};

SupportMeOffcanvas.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
    supportMe: supportMeType,
    resetAmountForm: PropTypes.func.isRequired,
};

export default withTranslation()(SupportMeOffcanvas);
