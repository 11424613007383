import {baseApi} from "./apiService";

export const emailSubscribeApi = baseApi.injectEndpoints({
        overrideExisting: false,
        endpoints: (builder) => ({
            createEmailSubscribe: builder.mutation({
                query(data) {
                    return {
                        url: `emailSubscribe`,
                        method: 'POST',
                        body: data,
                    }
                },
            }),
            deleteEmailSubscribe: builder.mutation({
                query(data) {
                    return {
                        url: `emailSubscribe/${data}`,
                        method: 'DELETE',
                    }
                },
                invalidatesTags: (result, error, {data}) => [{type: 'EmailSubscribers', id: data}],
            }),
            getEmailSubscribers: builder.query({
                query: () => `emailSubscribe`,
                providesTags: (result) =>
                    result
                        ? [
                            {type: 'EmailSubscribers', id: result.id},
                            {type: 'EmailSubscribers', id: 'LIST'},
                        ] : [
                            {type: 'EmailSubscribers', id: 'LIST'},
                        ]
            }),
            unsubscribeAll: builder.mutation({
                query(data) {
                    return {
                        url: `emailSubscribe/unsubscribe`,
                        method: 'POST',
                        body: data,
                    }
                },
            }),
            unsubscribeProfile: builder.mutation({
                query(data) {
                    const {id, ...body} = data
                    return {
                        url: `emailSubscribe/unsubscribe/${id}`,
                        method: 'POST',
                        body: body,
                    }
                },
            }),
        }),
    }
)

export const {
    useCreateEmailSubscribeMutation,
    useGetEmailSubscribersQuery,
    useDeleteEmailSubscribeMutation,
    useUnsubscribeAllMutation,
    useUnsubscribeProfileMutation,
} = emailSubscribeApi
