const colors = {
    brand: {
        light: "#FFF8FA",
        lighter: "#FFF5F9",
        lightest: "#FCF9FA",
        dark: "#BC3665",
        darker: "#242424",
    },
    social: {
        youtube: "#FF0000",
        facebook: "#2656FF",
        linkedin: "##3789E9",
        instagram: "#F25D66",
        gray: "#E2DBDD",
    },
    dark: {
        400: "#72808F",
        500: "#A6A6A6",
        800: "#252A33",
        900: "#000000",
    },
    text: {
        300: "#404040",
        400: "#343434",
        500: "#263238",
        600: "#909090",
    },
};

export default colors;
