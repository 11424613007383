import {Role} from "../constants/roles";

//todo later if needed add inclusive / exclusive (for example super admin is also user) check if it is only user
export function isUser(user) {
    return user?.roles?.includes(Role.User) ?? false
}

export function isSuperAdmin(user) {
    return user?.roles?.includes(Role.SuperAdmin) ?? false
}
