import React from "react";
import {SwitchContainer} from "../../../styled-components/S_Setting";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";

const ToggleSwitch = ({t, i18n, text = ["Hide Me", "Active"], showLine = true, value, action, disabled = false}) => {

    return (
        <SwitchContainer>
            {showLine && <hr/>}
            {/*disable popover*/}
            {disabled
                ? <div className={'disabled'}>
                    <p>{t(text[0])}</p>
                    <p>{t(text[1])}</p>
                </div>
                : <div className={value ? "active" : null}>
                    <p onClick={action}>
                        {t(text[0])}
                    </p>
                    <p onClick={action}>
                        {t(text[1])}
                    </p>
                </div>
            }
        </SwitchContainer>
    );
}

ToggleSwitch.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
    text: PropTypes.arrayOf(PropTypes.string),
    showLine: PropTypes.bool,
    value: PropTypes.bool.isRequired,
    action: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

export default withTranslation()(ToggleSwitch);
