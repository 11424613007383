import React, {useEffect} from "react";
import {withTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import Header from "../components/Header";
import LoginForm from "../components/LoginForm";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import {AlreadyHave, RegisterHeader, RegisterWrapper, RegistrationContainer} from "../styled-components/S_Registration";
import {PrimaryButton} from "../styled-components/Buttons";


const Login = ({t, i18n}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        document.title = 'Quickg - Login';
    }, [dispatch]);

    return (
        <RegistrationContainer>
            <Header hasGrayBackground={true}/>
            <div>
                <RegisterWrapper>
                    <RegisterHeader>
                        <h1>{t('login.title')}</h1>
                    </RegisterHeader>
                    <LoginForm/>
                    <AlreadyHave className={'my-3'}>
                        <hr/>
                        <h4>Need an account?</h4>
                        <hr/>
                    </AlreadyHave>
                    <Link to={'/register'}>
                        <PrimaryButton outline>{t('nav.signUp')}</PrimaryButton>
                    </Link>
                    <AlreadyHave className={'my-3'}>
                        <hr/>
                        <h4>Forgot password?</h4>
                        <hr/>
                    </AlreadyHave>
                    <Link to={'/forgotPassword'}>
                        <PrimaryButton outline>{t('user.forgotPassword.btn')}</PrimaryButton>
                    </Link>
                </RegisterWrapper>
            </div>
        </RegistrationContainer>
    )
}

Login.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
};

export default withTranslation()(Login);
