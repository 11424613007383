import {extendTheme, ThemeConfig} from "@chakra-ui/react";
// import "focus-visible/dist/focus-visible";
import * as components from "./components";
import * as foundations from "./foundations";
import {textStyles} from "./textStyles";

/**
 * This file is generated for providing a custom theme to Chakra UI
 *
 * To learn more about custom themes
 * please visit https://chakra-ui.com/docs/getting-started#add-custom-theme-optional
 */

const theme = extendTheme({
    ...foundations,

    components: {
        ...components,
    },
    textStyles,

    // config: {
    //   useSystemColorMode: false,
    //   initialColorMode: "light",
    // },
} as ThemeConfig);

export default theme;
