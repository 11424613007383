// import config from 'config';
import {authHeader} from "../helpers/authHeader";
import {URL} from "../constants/API";
import {handleResponse} from "../helpers/handleResponse";

export const billingInfoService = {
    create,
    update,
    delete: _delete
};
const config = {
    apiUrl: URL.concat('/api')
}

function update(id, billingInfo) {
    const requestOptions = {
        method: 'PUT',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
        body: JSON.stringify(billingInfo)
    };

    return fetch(`${config.apiUrl}/billing-info/${id}`, requestOptions).then(handleResponse);
}


function create(billingInfo) {
    const requestOptions = {
        method: 'POST',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
        body: JSON.stringify(billingInfo)
    };

    return fetch(`${config.apiUrl}/billing-info`, requestOptions).then(handleResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}/billing-info/${id}`, requestOptions).then(handleResponse);
}
