import {applyMiddleware, compose, createStore} from "redux";
import rootReducer from "../reducers/index";
import thunk from "redux-thunk";
import * as Sentry from "@sentry/react";
import {baseApi} from "../services/apiService";
import {rtkQueryLogger} from "../middleware/rtkQueryLogger";
import {setupListeners} from "@reduxjs/toolkit/query";
import {rtkQueryNotifications} from "../middleware/rtkQueryNotifications";

// todo extract to reducers/index
// interface RootState {
//     [baseApi.reducerPath]: any; // Replace 'any' with the actual type
//     development: any;
//     postDescription: any;
//     tagCategories: any;
//     generateTags: any;
//     hashtags: any;
//     authentication: any;
//     payIntent: any;
//     registration: any;
//     links: any;
//     profilePicture: any;
//     supportMe: any;
//     auth: any;
//     alerts: any;
//     contactOffcanvas: ContactOffcanvasState;
//     reportOffcanvas: any;
// }

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    // Optionally pass options
});

const composeEnhancers = (window as any)['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as typeof compose || compose;

const store = createStore(
    rootReducer,
    compose(composeEnhancers(applyMiddleware(thunk, baseApi.middleware, rtkQueryNotifications, rtkQueryLogger), sentryReduxEnhancer))
);

setupListeners(store.dispatch)

export default store;

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
