const baseStyle = {};

const variants = {
    lexend: {
        fontFamily: "'Lexend', sans-serif",
    },
};

const defaultProps = {};

const Text = {
    baseStyle,
    variants,
    defaultProps,
};

export default Text;
