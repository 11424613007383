import {userConstants} from "../constants/action-types";
import {billingInfoService} from "../services/billingInfoService";
import {errorAlert, successAlert, warningAlert} from "../slicers/alertsSlice";
import {baseApi} from "../services/apiService";

const create = (billingInfo) => {
    return (dispatch, getState) => {
        if (getState().authentication.updatingProfile === true) {
            return;
        }
        dispatch(request(billingInfo));

        billingInfoService.create(billingInfo)
            .then(
                billingInfo => {
                    dispatch(success(billingInfo));
                    dispatch(baseApi.endpoints.getCurrentUser.initiate(undefined, {forceRefetch: true}))
                    dispatch(successAlert('alert.billingInfo.userCreated'));
                },
                error => {
                    if (error.code === 422) {
                        dispatch(warningAlert('alert.invalidForm'));
                    } else {
                        dispatch(errorAlert());
                    }
                    dispatch(failure(error));
                }
            );
    };

    function request(billingInfo) {
        return {type: userConstants.CREATE_BILLING_INFO_REQUEST, billingInfo}
    }

    function success(billingInfo) {
        return {type: userConstants.CREATE_BILLING_INFO_SUCCESS, billingInfo}
    }

    function failure(error) {
        return {type: userConstants.CREATE_BILLING_INFO_FAILURE, error}
    }
}

const update = (id, billingInfo) => {
    return (dispatch, getState) => {
        if (getState().authentication.updatingProfile === true) {
            return;
        }
        dispatch(request(billingInfo));

        billingInfoService.update(id, billingInfo)
            .then(
                billingInfo => {
                    dispatch(success(billingInfo));
                    dispatch(baseApi.endpoints.getCurrentUser.initiate(undefined, {forceRefetch: true}))
                    dispatch(successAlert('alert.billingInfo.userUpdated'));
                },
                error => {
                    if (error.code === 422) {
                        dispatch(warningAlert('alert.invalidForm'));
                    } else {
                        dispatch(errorAlert());
                    }
                    dispatch(failure(error));
                }
            );
    };

    function request(billingInfo) {
        return {type: userConstants.UPDATE_BILLING_INFO_REQUEST, billingInfo}
    }

    function success(billingInfo) {
        return {type: userConstants.UPDATE_BILLING_INFO_SUCCESS, billingInfo}
    }

    function failure(error) {
        return {type: userConstants.UPDATE_BILLING_INFO_FAILURE, error}
    }
}

// prefixed function name with underscore because delete is a reserved word in javascript
const _delete = (id) => {
    return (dispatch, getState) => {
        if (getState().authentication.isDeletingAccount === true) {
            return;
        }
        dispatch(request(id));

        billingInfoService.delete(id)
            .then(
                billingInfo => {
                    dispatch(success(billingInfo))
                    dispatch(successAlert('alert.billingInfo.userUpdated'));

                },
                error => {
                    dispatch(failure(error));
                    dispatch(errorAlert());
                }
            );
    };

    function request(id) {
        return {type: userConstants.DELETE_BILLING_INFO_REQUEST, id}
    }

    function success(id) {
        return {type: userConstants.DELETE_BILLING_INFO_SUCCESS, id}
    }

    function failure(id, error) {
        return {type: userConstants.DELETE_BILLING_INFO_FAILURE, id, error}
    }
}

export const billingInfoActions = {
    create,
    update,
    delete: _delete
};
