import React from 'react';
import DoubleShapeTop from "./DoubleShapeTop";
import DoubleShapeBottom from "./DoubleShapeBottom";
import {Col, Row} from "react-bootstrap";
import Header from "../Header";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

const Loading = ({text = 'Loading'}) => {
    return (
        <>
            <Header header={<FontAwesomeIcon icon="circle-notch" spin/>}/>
            <Row className={'my-5'}>
                <Col className={'text-center'}>
                    <h1>{text}</h1>
                </Col>
            </Row>
            <DoubleShapeTop half={true}/>
            <DoubleShapeBottom half={true}/>
        </>
    );
}

Loading.propTypes = {
    text: PropTypes.string,
};

export default Loading
