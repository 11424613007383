export function authHeader() {
    // return authorization header with jwt token
    let user = JSON.parse(localStorage.getItem('user'));
//  todo and url is api url
//  const isApiUrl = url.startsWith(config.apiUrl);
//  if (user && user.token && isApiUrl) {

    if (user && user.token) {
        return {'Authorization': 'Bearer ' + user.token};
    } else {
        return {};
    }
}
