const baseStyle = {
  py: "12px",
  px: "20px",
  h: "44px",
  borderRadius: "100px",
  fontSize: "16px",
  fontWeight: "bold",
};

const variants = {
  primary: {
    bg: "brand.dark",
    color: "white",
    fontWeight: "bold",
    fontSize: "16px",
    _hover: {
      opacity: 0.8,
    },
    _focus: {
      opacity: 1,
    },
  },
  outline: {
    bg: "white",
    color: "brand.dark",
    borderColor: "brand.dark",
    fontWeight: "bold",
    fontSize: "16px",
    _hover: {
      opacity: 0.8,
    },
    _focus: {
      opacity: 1,
    },
  },

  bright: {
    bg: "white",
    color: "#404040",
    fontWeight: "bold",
    fontSize: "16px",
    _hover: {
      opacity: 0.8,
    },
    _focus: {
      opacity: 1,
    },
  },
  dark_grad: {
    bg: "linear-gradient(142.93deg, #BC3665 6.38%, #4B4BC3 130.75%)",
    color: "white",
    fontWeight: "bold",
    fontSize: "16px",
    _hover: {
      opacity: 0.8,
    },
    _focus: {
      opacity: 1,
    },
  },
  yellow_grad: {
    bg: "linear-gradient(251.57deg, #FFD951 11.85%, #FF9922 85.37%)",
    color: "white",
    fontWeight: "bold",
    fontSize: "16px",
    _hover: {
      opacity: 0.8,
    },
    _focus: {
      opacity: 1,
    },
  },

  grad1: {
    bg: "linear-gradient(94.62deg, #BD3766 -2.73%, #E83288 92.45%)",
    color: "white",
    fontWeight: "bold",
    fontSize: "16px",
    _hover: {
      opacity: 0.8,
    },
    _focus: {
      opacity: 1,
    },
  },
  grad2: {
    bg: "linear-gradient(94.62deg, #C13769 -2.73%, #E83288 92.45%)",
    color: "white",
    fontWeight: "bold",
    fontSize: "16px",
    _hover: {
      opacity: 0.8,
    },
    _focus: {
      opacity: 1,
    },
  },
  grad3: {
    bg: "linear-gradient(94.62deg, #FEB064 -2.73%, #EF2E2E 92.45%)",
    color: "white",
    fontWeight: "bold",
    fontSize: "16px",
    _hover: {
      opacity: 0.8,
    },
    _focus: {
      opacity: 1,
    },
  },
  grad4: {
    bg: "linear-gradient(94.62deg, #E6922F -2.73%, #E6C62E 92.45%)",
    color: "white",
    fontWeight: "bold",
    fontSize: "16px",
    _hover: {
      opacity: 0.8,
    },
    _focus: {
      opacity: 1,
    },
  },
};

const defaultProps = {
  variant: "primary",
};

const button = {
  baseStyle,
  variants,
  defaultProps,
};

export default button;
