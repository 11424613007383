import React, {useEffect} from "react";
import {withTranslation} from "react-i18next";
import {
    BillingInfo,
    BillingInput,
    ButtonContainer,
    ChangePassword,
    FormRegularRow,
    New,
    OldPassword,
    PageHeader,
    Progresses,
    SettingInput,
    SettingLabel,
    SettingsContainer,
    SettingsWrapper,
    Stripe,
    Text
} from "../styled-components/S_Setting";
import {PrimaryButton} from "../styled-components/Buttons";
import {useGetCurrentUserQuery} from "../services/userService";
import Loading from "../components/General/Loading";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import User from "../components/User/PrivateProfile/User";
import Header from "../components/User/PrivateProfile/Header";
import {
    enabledStripeCharges,
    enabledStripePayouts,
    hasStripe,
    stripeDetailsSubmitted
} from "../helpers/userSettingsHelper";
import {Form} from "react-bootstrap";
import {stripeActions} from "../actions/stripe";
import {useDispatch, useSelector} from "react-redux";
import * as Yup from "yup";
import {object, string} from "yup";
import * as ReactGA from "react-ga";
import {billingInfoActions} from "../actions/billingInfo";
import {CountryDropdown, RegionDropdown} from "react-country-region-selector";
import {userActions} from "../actions/user";
import {Formik} from "formik";
import EditProfile from "../components/Settings/EditProfile";
import ValidateFromApi from "../components/ValidateFromApi";
import Progress from "../components/User/Progress";

//todo refetch current user on billing info update
const Settings = ({t, i18n}) => {
    const {
        data,
        isLoading
    } = useGetCurrentUserQuery()

    const errorState = useSelector(state => state.authentication.formErrors);

    const dispatch = useDispatch();

    useEffect(() => {
        document.title = 'Quickg - '.concat(data?.username);
    }, [data]);

    if (isLoading) {
        return (
            <Loading/>
        )
    }

    const billingInfo = data.billing_info !== undefined && data.billing_info !== null ? data.billing_info : {}

    const schema = object({
        name: string().min(2).max(50).required(),
        store_description: string(),
        business_type: string().required().oneOf(['individual', 'non_profit', 'company']),
        postal_code: string().min(2).max(15).required(),
        line1: string().min(2).max(15).required(),
        line2: string(),
        city: string().required(),
        country: string().required(),
        state: string(),
    });

    const schemaPassword = object({
        oldPassword: string()
            .max(50)
            .min(6, 'Password must be at least 6 characters')
            .required('Password is required'),
        password: string()
            .max(50)
            .min(6, 'Password must be at least 6 characters')
            .required('Password is required'),
        confirmPassword: string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Confirm Password is required'),
    });

    return (
        <>
            {/* todo isSettings is undefined and not used*/}
            <User user={data} isSettings={true}/>
            <SettingsContainer>
                <SettingsWrapper>
                    <PageHeader>
                        <Header>Settings</Header>
                    </PageHeader>
                    {/* todo isSettings is undefined and not used*/}
                    {/*<ContentManager user={data} isSettings={true}/>*/}

                    {/* todo trigger user reload */}
                    <EditProfile user={data} errorState={errorState}/>

                    <ChangePassword>
                        <Header>Change Password</Header>
                        <Formik
                            validationSchema={schemaPassword}
                            validateOnMount={false}
                            onSubmit={(values, {setSubmitting, resetForm}) => {
                                ReactGA.event({
                                    category: 'UserSettings',
                                    action: 'Update user password'
                                });
                                setSubmitting(true);
                                dispatch(userActions.changePassword(data.id, values))
                                setSubmitting(false);
                                resetForm()
                                //todo dispatch notification
                            }}
                            enableReinitialize={true}
                            initialValues={{
                                oldPassword: '',
                                password: '',
                                confirmPassword: '',
                            }}>
                            {({
                                  handleSubmit,
                                  handleChange,
                                  handleReset,
                                  handleBlur,
                                  values,
                                  dirty,
                                  touched,
                                  isValid,
                                  isSubmitting,
                                  errors,
                                  setFieldValue,
                                  setFieldTouched,
                              }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <FormRegularRow>
                                        <SettingLabel htmlFor={'oldPassword'}>
                                            Old password
                                        </SettingLabel>
                                        <SettingInput
                                            className="mb-2 me-sm-2"
                                            id={'oldPassword'}
                                            name={`oldPassword`}
                                            type='password'
                                            onBlur={e => {
                                                handleBlur(e)
                                            }}
                                            onChange={e => {
                                                handleChange(e)
                                            }}
                                            value={values.oldPassword}
                                            placeholder="Old password"
                                            isValid={touched.oldPassword && !errors.oldPassword}
                                            isInvalid={!!errors.oldPassword}
                                        />
                                        <Form.Control.Feedback type="invalid" className={'tooltip text-danger'}>
                                            {errors.oldPassword}
                                        </Form.Control.Feedback>
                                    </FormRegularRow>
                                    <New>
                                        <Text>New</Text>
                                        <hr/>
                                    </New>
                                    <OldPassword>
                                        <FormRegularRow>
                                            <SettingLabel htmlFor={'password'}>
                                                New password
                                            </SettingLabel>
                                            <SettingInput
                                                className="mb-2 me-sm-2"
                                                id={'password'}
                                                name={`password`}
                                                type='password'
                                                onBlur={e => {
                                                    handleBlur(e)
                                                }}
                                                onChange={e => {
                                                    handleChange(e)
                                                }}
                                                value={values.password}
                                                placeholder="New password"
                                                isValid={touched.password && !errors.password}
                                                isInvalid={!!errors.password}
                                            />
                                            <Form.Control.Feedback type="invalid" className={'tooltip text-danger'}>
                                                {errors.password}
                                            </Form.Control.Feedback>
                                        </FormRegularRow>
                                        <FormRegularRow>
                                            <SettingLabel htmlFor={'bio'}>
                                                Confirm password
                                            </SettingLabel>
                                            <SettingInput
                                                className="mb-2 me-sm-2"
                                                id={'confirmPassword'}
                                                name={`confirmPassword`}
                                                type='password'
                                                onBlur={e => {
                                                    handleBlur(e)
                                                }}
                                                onChange={e => {
                                                    handleChange(e)
                                                }}
                                                value={values.confirmPassword}
                                                placeholder="Confirm password"
                                                isValid={touched.confirmPassword && !errors.confirmPassword}
                                                isInvalid={!!errors.confirmPassword}
                                            />
                                            <Form.Control.Feedback type="invalid" className={'tooltip text-danger'}>
                                                {errors.confirmPassword}
                                            </Form.Control.Feedback>
                                        </FormRegularRow>
                                    </OldPassword>
                                    <ButtonContainer>
                                        <PrimaryButton outline type={"button"}
                                                       onClick={() => handleReset()}>Reset</PrimaryButton>
                                        <PrimaryButton
                                            type={"submit"}
                                            disabled={isSubmitting || !isValid
                                            }>Submit</PrimaryButton>
                                    </ButtonContainer>
                                </Form>
                            )}
                        </Formik>
                    </ChangePassword>

                    <div>
                        <div>
                            <Header>Billing Info</Header>
                            {/*<ToggleSwitch />*/}
                        </div>
                        <BillingInfo>
                            {data.email_verified ?
                                <Formik
                                    validationSchema={schema}
                                    validateOnMount={true}
                                    onSubmit={(values, {setSubmitting, resetForm}) => {
                                        ReactGA.event({
                                            category: 'UserSettings',
                                            action: 'Update user billing info'
                                        });
                                        setSubmitting(true);
                                        Object.keys(billingInfo).length === 0
                                            ? dispatch(billingInfoActions.create(values))
                                            : dispatch(billingInfoActions.update(billingInfo.id, values))
                                        setSubmitting(false);
                                        // resetForm({values: values})
                                    }}
                                    enableReinitialize={true}
                                    initialValues={{
                                        name: billingInfo.name ?? '',
                                        store_description: billingInfo.store_description ?? '',
                                        business_type: billingInfo.business_type ?? '',
                                        postal_code: billingInfo.postal_code ?? '',
                                        line1: billingInfo.line1 ?? '',
                                        line2: billingInfo.line2 ?? '',
                                        city: billingInfo.city ?? '',
                                        country: billingInfo.country ?? '',
                                        state: billingInfo.state ?? '',
                                        // todo add currency field later
                                        // currency: billingInfo.currency ?? '',',
                                    }}>
                                    {({
                                          handleSubmit,
                                          handleChange,
                                          handleReset,
                                          handleBlur,
                                          values,
                                          dirty,
                                          touched,
                                          isValid,
                                          isSubmitting,
                                          errors,
                                          setFieldValue,
                                          setFieldTouched,
                                      }) => (
                                        <Form noValidate onSubmit={handleSubmit}>
                                            <BillingInput gap="5rem">
                                                <FormRegularRow>
                                                    <SettingLabel htmlFor={'name'}>
                                                        {t('user.edit.name')}
                                                    </SettingLabel>
                                                    <SettingInput
                                                        id={'name'}
                                                        name={`name`}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.name}
                                                        placeholder={t('user.edit.name')}
                                                        isValid={touched.name && !errors.name}
                                                        isInvalid={!!errors.name}
                                                    />
                                                    <Form.Control.Feedback type="invalid"
                                                                           className={'tooltip text-danger'}>
                                                        {errors.name}
                                                    </Form.Control.Feedback>
                                                </FormRegularRow>
                                                <FormRegularRow>
                                                    <SettingLabel htmlFor={'business_type'}>
                                                        {t('user.edit.business_type')}
                                                    </SettingLabel>
                                                    <SettingInput
                                                        as={Form.Select}
                                                        id={'business_type'}
                                                        name={`business_type`}
                                                        onBlur={e => {
                                                            handleBlur(e)
                                                        }}
                                                        onChange={handleChange}
                                                        value={values.business_type}
                                                        placeholder={t('user.edit.business_type')}
                                                        isValid={touched.business_type && !errors.business_type}
                                                        isInvalid={!!errors.business_type}
                                                    >
                                                        <option value=''>{t('user.edit.business_types.select')}</option>
                                                        <option
                                                            value='individual'>{t('user.edit.business_types.individual')}</option>
                                                        <option
                                                            value='non_profit'>{t('user.edit.business_types.nonProfit')}</option>
                                                        <option
                                                            value='company'>{t('user.edit.business_types.company')}</option>
                                                    </SettingInput>
                                                    <Form.Control.Feedback type="invalid"
                                                                           className={'tooltip text-danger'}>
                                                        {errors.business_type}
                                                    </Form.Control.Feedback>
                                                </FormRegularRow>
                                            </BillingInput>

                                            <BillingInput>
                                                <FormRegularRow>
                                                    <SettingLabel htmlFor={'store_description'}>
                                                        {t('user.edit.store_description')}
                                                    </SettingLabel>
                                                    <SettingInput
                                                        as='textarea'
                                                        rows={3}
                                                        id={'store_description'}
                                                        name={`store_description`}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.store_description}
                                                        placeholder={t('user.edit.store_description')}
                                                        isValid={touched.store_description && !errors.store_description}
                                                        isInvalid={!!errors.store_description}
                                                    />
                                                    <Form.Control.Feedback type="invalid"
                                                                           className={'tooltip text-danger'}>
                                                        {errors.store_description}
                                                    </Form.Control.Feedback>
                                                </FormRegularRow>
                                            </BillingInput>

                                            <BillingInput gap="2rem">
                                                <FormRegularRow>
                                                    <SettingLabel htmlFor={'line1'}>
                                                        {t('user.edit.line1')}
                                                    </SettingLabel>
                                                    <SettingInput
                                                        id={'line1'}
                                                        name={`line1`}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.line1}
                                                        placeholder={t('user.edit.line1')}
                                                        isValid={touched.line1 && !errors.line1}
                                                        isInvalid={!!errors.line1}
                                                    />
                                                    <Form.Control.Feedback type="invalid"
                                                                           className={'tooltip text-danger'}>
                                                        {errors.line1}
                                                    </Form.Control.Feedback>
                                                </FormRegularRow>
                                                <FormRegularRow>
                                                    <SettingLabel htmlFor={'line2'}>
                                                        {t('user.edit.line2')}
                                                    </SettingLabel>
                                                    <SettingInput
                                                        id={'line2'}
                                                        name={`line2`}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.line2}
                                                        placeholder={t('user.edit.line2')}
                                                        isValid={touched.line2 && !errors.line2}
                                                        isInvalid={!!errors.line2}
                                                    />
                                                    <Form.Control.Feedback type="invalid"
                                                                           className={'tooltip text-danger'}>
                                                        {errors.line2}
                                                    </Form.Control.Feedback>
                                                </FormRegularRow>
                                                <FormRegularRow>
                                                    <SettingLabel htmlFor={'city'}>
                                                        {t('user.edit.city')}
                                                    </SettingLabel>
                                                    <SettingInput
                                                        id={'city'}
                                                        name={`city`}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.city}
                                                        placeholder={t('user.edit.city')}
                                                        isValid={touched.city && !errors.city}
                                                        isInvalid={!!errors.city}
                                                    />
                                                    <Form.Control.Feedback type="invalid"
                                                                           className={'tooltip text-danger'}>
                                                        {errors.city}
                                                    </Form.Control.Feedback>
                                                </FormRegularRow>
                                            </BillingInput>
                                            <BillingInput gap="2rem">
                                                <FormRegularRow>
                                                    <SettingLabel htmlFor={'country'}>
                                                        {t('user.edit.country')}
                                                    </SettingLabel>
                                                    <SettingInput
                                                        as={CountryDropdown}
                                                        className={'form-select'}
                                                        id={'country'}
                                                        name={`country`}
                                                        valueType={'short'}
                                                        onChange={(val) => setFieldValue('country', val)}
                                                        onBlur={handleBlur}
                                                        value={values.country}
                                                        placeholder={t('user.edit.country')}
                                                        isValid={touched.country && !errors.country}
                                                        isInvalid={!!errors.country}
                                                    />
                                                    <Form.Control.Feedback type="invalid"
                                                                           className={'tooltip text-danger'}>
                                                        {errors.country}
                                                    </Form.Control.Feedback>
                                                </FormRegularRow>
                                                <FormRegularRow>
                                                    <SettingLabel htmlFor={'state'}>
                                                        {t('user.edit.state')}
                                                    </SettingLabel>
                                                    <SettingInput
                                                        className={'form-select'}
                                                        as={RegionDropdown}
                                                        country={values.country}
                                                        countryValueType="short"
                                                        id={'state'}
                                                        name={`state`}
                                                        onChange={(val) => setFieldValue('state', val)}
                                                        onBlur={handleBlur}
                                                        value={values.state}
                                                        placeholder={t('user.edit.state')}
                                                        isValid={touched.state && !errors.state}
                                                        isInvalid={!!errors.state}
                                                    />
                                                    <Form.Control.Feedback type="invalid"
                                                                           className={'tooltip text-danger'}>
                                                        {errors.state}
                                                    </Form.Control.Feedback>
                                                </FormRegularRow>
                                                <FormRegularRow>
                                                    <SettingLabel htmlFor={'postal_code'}>
                                                        {t('user.edit.postal_code')}
                                                    </SettingLabel>
                                                    <SettingInput
                                                        className="mb-2 me-sm-2"
                                                        id={'postal_code'}
                                                        name={`postal_code`}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.postal_code}
                                                        placeholder={t('user.edit.postal_code')}
                                                        isValid={touched.postal_code && !errors.postal_code}
                                                        isInvalid={!!errors.postal_code}
                                                    />
                                                    <Form.Control.Feedback type="invalid"
                                                                           className={'tooltip text-danger'}>
                                                        {errors.postal_code}
                                                    </Form.Control.Feedback>
                                                </FormRegularRow>
                                            </BillingInput>

                                            <ButtonContainer>
                                                <PrimaryButton type={'button'} outline
                                                               onClick={() => handleReset()}>Reset</PrimaryButton>
                                                <PrimaryButton type={"submit"}>Submit</PrimaryButton>
                                            </ButtonContainer>
                                            <ValidateFromApi errors={errorState ?? {}}/>
                                        </Form>
                                    )}
                                </Formik>
                                : <h5 className={'text-center text-warning'}>{t('user.unverified')}</h5>}
                        </BillingInfo>
                    </div>

                    <div>
                        <Header>Billing accounts</Header>
                        <Stripe>
                            <div>
                                <FontAwesomeIcon
                                    color={'stripe'}
                                    icon={['fab', 'stripe']}
                                /><span>( Stripe Account )</span>
                            </div>
                            {data.email_verified
                                ? <PrimaryButton
                                    outline
                                    className={'me-5'}
                                    onClick={() => dispatch(stripeActions.onboard(data.billing_info.id))}
                                >{t('stripe.connect')}</PrimaryButton>
                                : <h5 className={'text-center text-warning'}>{t('user.unverified')}</h5>
                            }
                        </Stripe>
                        <Progresses>
                            <Progress value={stripeDetailsSubmitted(data)} disabled={!hasStripe(data)}>
                                Details Submitted
                            </Progress>
                            <Progress value={enabledStripeCharges(data)} disabled={!hasStripe(data)}>
                                Charges
                            </Progress>
                            <Progress value={enabledStripePayouts(data)} disabled={!hasStripe(data)}>
                                Payouts
                            </Progress>
                        </Progresses>
                    </div>

                </SettingsWrapper>
            </SettingsContainer>
        </>
    );
}

Settings.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
};

export default withTranslation()(Settings);
