import {URL} from "../constants/API";
import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import {isLoggedIn, logoutNew, setTokens} from "../slicers/authSlice";

const config = {
    apiUrl: URL.concat('/api/')
}

const baseQuery = fetchBaseQuery({
    baseUrl: config.apiUrl,
});

const baseAuthenticatedQuery = fetchBaseQuery({
    baseUrl: config.apiUrl,
    prepareHeaders: (headers, {getState}) => {
        const token = getState().auth.token

        if (token) {
            headers.set('authorization', `Bearer ${token}`)
        }
        return headers
    },
});

const baseQueryWithReauth = async (
    args,
    api,
    extraOptions,
) => {
    let result = await baseAuthenticatedQuery(args, api, extraOptions);

    if (result.error && result.error.status === 401 && isLoggedIn(api.getState().auth)) {
        const refreshResult = await baseQuery({
            url: 'token/refresh',
            method: 'POST',
            headers: {},
            body: {refresh_token: api.getState().auth.refreshToken},
        }, api, extraOptions);

        if (refreshResult.data) {
            api.dispatch(setTokens(refreshResult.data));

            // retry the initial query
            result = await baseAuthenticatedQuery(args, api, extraOptions);
        } else {
            api.dispatch(logoutNew());
        }
    }
    return result;
};

export const baseApi = createApi({
        baseQuery: baseQueryWithReauth,
        tagTypes: ['EmailToken', 'UserPublicProfiles', 'RecentSupportMe', 'CurrentUser', 'EmailSubscribers', 'AdminUsers'],

        endpoints: () => ({}),
    }
)
