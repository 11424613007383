import React from "react";
import {Helmet} from "react-helmet";
import PropTypes from "prop-types";
import {useLocation} from "react-router-dom";

const Seo = ({title = 'Quickg', description, twitterType = 'summary_large_image', ogType = 'website'}) => {
    const location = useLocation();

    return (
        <Helmet>
            <title>{title}</title>
            <meta content={title} name="title"/>
            <meta name="description" content={description}/>

            {/*Twitter*/}
            <meta name="twitter:card" content={twitterType}/>
            <meta name="twitter:title" content={title}/>
            <meta name="twitter:description" content={description}/>
            <meta name="twitter:site" content="@quickg_official"/>
            <meta name="twitter:image" content="https://quickg.eu/media.png"/>
            <meta name="twitter:creator" content="@its_janosik"/>

            {/*Open Graph*/}
            <meta property="og:title" content={title}/>
            <meta property="og:description" content={description}/>
            <meta property="og:url" content={`https://quickg.eu${location.pathname}`}/>
            <meta property="og:image" content="https://quickg.eu/media.png"/>
            <meta property="og:site_name" content="Quickg"/>
            <meta property="og:locale" content="en_US"/>
            <meta property="og:type" content="website"/>
        </Helmet>
    );
};

export default Seo;

Seo.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string.isRequired,
    twitterType: PropTypes.string,
    ogType: PropTypes.string,
};
