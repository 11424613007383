import {ComponentStyleConfig} from "@chakra-ui/react";

const FormLabel: ComponentStyleConfig = {
    baseStyle: {
        fontSize: "18px",
        fontWeight: "bold",
    },
};

export default FormLabel;
