import React from "react";
import {Box, Container, Flex, HStack, Image, Spacer, Stack, Text, Wrap,} from "@chakra-ui/react";
import {Link} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../helpers/hooks";
import ContactOffcanvas from "../Contact/ContactOffcanvas";
import {showOffcanvas} from "../../slicers/contactOffcanvasSlice";
import {useTranslation} from "react-i18next";
import {NavHashLink} from "react-router-hash-link";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Footer = () => {
    const {t} = useTranslation();
    const offcanvasState = useAppSelector(state => state.contactOffcanvas);
    const dispatch = useAppDispatch()

    return (
        <>
            <ContactOffcanvas offcanvasState={offcanvasState}/>
            <Box bg="brand.darker">
                <Container py={"30px"} maxW={"1360px"}>
                    <Stack>
                        <Flex direction="column">
                            <Box>
                                <Image src={"/icons/logo_white.svg"} height="34px"/>
                            </Box>
                            {/*<Text mt={2} textStyle="h5" color="white">*/}
                            {/*    Quickg is not associated with Instagram and Facebook company.*/}
                            {/*</Text>*/}
                        </Flex>

                        <Flex
                            height={["180px", '140px', '80px']}
                            direction={{base: "column-reverse", lg: "row"}}
                        >
                            {/*<Stack direction={['column', 'row']} spacing={['10','2']}>*/}

                            {/*<HStack spacing={10}>*/}
                            <Wrap spacing={8} spacingY={0}>
                                <Link to="#" onClick={() => dispatch(showOffcanvas())}>
                                    <Text
                                        textStyle={{
                                            base: "p1",
                                            lg: "h5",
                                        }}
                                        fontWeight="medium"
                                        color="brand.dark"
                                    >
                                        {t('footer.contactUs')}
                                    </Text>
                                </Link>
                                <Link to="gdpr">
                                    <Text
                                        textStyle={{
                                            base: "p1",
                                            lg: "h5",
                                        }}
                                        fontWeight="medium"
                                        color="brand.dark"
                                    >
                                        {t('footer.gdpr')}
                                    </Text>
                                </Link>
                                <Link to="privacy">
                                    <Text
                                        textStyle={{
                                            base: "p1",
                                            lg: "h5",
                                        }}
                                        fontWeight="medium"
                                        color="brand.dark"
                                    >
                                        {t('footer.privacy')}
                                    </Text>
                                </Link>
                                <Link to="terms">
                                    <Text
                                        textStyle={{
                                            base: "p1",
                                            lg: "h5",
                                        }}
                                        fontWeight="medium"
                                        color="brand.dark"
                                    >
                                        {t('footer.terms')}
                                    </Text>
                                </Link>
                                <Link to="unsubscribe">
                                    <Text
                                        textStyle={{
                                            base: "p1",
                                            lg: "h5",
                                        }}
                                        fontWeight="medium"
                                        color="brand.dark"
                                    >
                                        {t('emailUnsubscribe.header')}
                                    </Text>
                                </Link>
                                <NavHashLink to="/#pricing">
                                    <Text
                                        textStyle={{
                                            base: "p1",
                                            lg: "h5",
                                        }}
                                        fontWeight="medium"
                                        color="brand.dark"
                                    >
                                        {t('nav.pricing')}
                                    </Text>
                                </NavHashLink>
                                <Link to="blog">
                                    <Text
                                        textStyle={{
                                            base: "p1",
                                            lg: "h5",
                                        }}
                                        fontWeight="medium"
                                        color="brand.dark"
                                    >
                                        {t('nav.blog')}
                                    </Text>
                                </Link>
                            </Wrap>
                            <Spacer/>

                            <HStack spacing={4}>
                                <a className='text-light' target={'_blank'} rel="noreferrer"
                                   href="https://www.instagram.com/quickg_official/">
                                    <FontAwesomeIcon icon={['fab', 'instagram']}/>
                                </a>
                                <a className='text-light' target={'_blank'} rel="noreferrer"
                                   href="https://www.linkedin.com/company/quickg/">
                                    <FontAwesomeIcon icon={['fab', 'linkedin-in']}/>
                                </a>
                                <a className='text-light' target={'_blank'} rel="noreferrer"
                                   href="https://www.facebook.com/quickgeu/">
                                    <FontAwesomeIcon icon={['fab', 'facebook-f']}/>
                                </a>
                                <a className='text-light' target={'_blank'} rel="noreferrer"
                                   href="https://twitter.com/quickg_official">
                                    <FontAwesomeIcon icon={['fab', 'twitter']}/>
                                </a>
                            </HStack>
                        </Flex>
                    </Stack>
                </Container>
            </Box>
            <Box bg="white">
                <Text
                    textStyle="p2"
                    fontWeight="bold"
                    textAlign="center"
                    color="text.600"
                    py={4}
                >
                    <small>{t('footer.copyright', {year: ((new Date()).getFullYear())})} <FontAwesomeIcon
                        icon={'heart'} className='text-danger'/> {t('footer.copyrightEnd')}</small>
                </Text>
            </Box>
        </>
    );
};

export default Footer;
