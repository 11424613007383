import {createSlice} from '@reduxjs/toolkit'
import {contactApi} from "../services/contactService";

export interface ContactOffcanvasState {
    showOffcanvas: boolean;
    name: string;
    email: string;
    message: string;
    subject: string;
}

const contactOffcanvasInitialState: ContactOffcanvasState = {
    showOffcanvas: false,
    name: '',
    email: '',
    message: '',
    subject: 'contact',
};

const contactOffcanvasSlice = createSlice({
    name: 'contactOffcanvas',
    initialState: contactOffcanvasInitialState,
    reducers: {
        showOffcanvas: (state) => {
            state.showOffcanvas = true
        },
        hideOffcanvas: (state, action) => {
            return {...action.payload, showOffcanvas: false}
        },
        clearOffcanvas: (state, action) => {
            return contactOffcanvasInitialState
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(contactApi.endpoints.createContact.matchFulfilled,
            (state, action) => {
                return contactOffcanvasInitialState
            }
        )
    },
})

export const {showOffcanvas, hideOffcanvas, clearOffcanvas} = contactOffcanvasSlice.actions

export default contactOffcanvasSlice.reducer
