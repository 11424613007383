import React, {useEffect} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {withTranslation} from "react-i18next";
import Header from "../components/Header";
import PropTypes from "prop-types";

const Terms = ({t, i18n}) => {
    useEffect(() => {
        document.title = 'Quickg - Terms of use';
    });

    return (
        <Container fluid>
            <Row className={'mb-5'}>
                <Header header='Terms of use'/>
            </Row>
            <Row>
                <Col>
                    <Container>
                        <Row>
                            <Col>
                                <p>
                                    Welcome to Quickg, this page explains our terms of use. When you use Quickg, you’re
                                    agreeing to all the rules on this page. Some of them need to be expressed in legal
                                    language, but we’ve done our best to offer you clear and simple explanations of what
                                    everything means.
                                </p>
                                <h4> About Creating an Account</h4>
                                <p>
                                    To sign up for a Quickg account, you need to be 18 or over. You’re responsible for
                                    your account and all the activity on it. You can browse Quickg without registering
                                    for an account. But to use some of our features, you’ll need to register, choose a
                                    username, and set a password. When you do that, the information you give us has to
                                    be accurate and complete. Don’t impersonate anyone else or choose names that are
                                    offensive or that violate anyone’s rights. If you don’t follow these rules, we may
                                    cancel your account.
                                    <br/>
                                    You’re responsible for all the activity on your account, and for keeping your
                                    password confidential. If you find out that someone has used your account without
                                    your permission, you should report it to <a
                                    href="mailto:info@quickg.eu?Subject=Privacy"
                                    target="_top"> info@quickg.eu</a>.
                                    <br/>
                                    To sign up for an account, you need to be at least 18 years old, or old enough to
                                    form a binding contract where you live. If necessary, we may ask you for proof of
                                    age.
                                    Things that are big no-no’s
                                </p>
                                <h4>Not allowed activities</h4>
                                <p>
                                    We expect all of you to behave responsibly and help keep this a nice place. If you
                                    want be a part of this don’t do any of these things on our site:
                                </p>
                                <ul>
                                    <li>
                                        Don’t break the law. Don’t take any action that infringes or violates other
                                        people’s rights, violates the law, or breaches any contract or legal duty you
                                        have toward anyone.
                                    </li>
                                    <li>
                                        Don’t lie to people. Don’t post information you know is false, misleading, or
                                        inaccurate. Don’t do anything deceptive or fraudulent.
                                    </li>
                                    <li>Don’t offer prohibited items. Don’t offer any rewards that are illegal, violate
                                        any of Quickg&apos;s policies, rules, or guidelines, or violate any applicable
                                        law,
                                        statute, ordinance, or regulation.
                                        Don’t victimize anyone. Don’t do anything threatening, abusive, harassing,
                                        defamatory, libelous, tortious, obscene, profane, or invasive of another
                                        person’s privacy.

                                    </li>
                                    <li> Don’t spam. Don’t distribute unsolicited or unauthorized advertising or
                                        promotional material, or any junk mail, spam, or chain letters. Don’t run mail
                                        lists, listservs, or any kind of auto-responder or spam on or through the Site.
                                        Don’t harm anyone’s computer. Don’t distribute software viruses, or anything
                                        else (code, films, programs) designed to interfere with the proper function of
                                        any software, hardware, or equipment on the Site (whether it belongs to Quickg
                                        or another party).
                                    </li>
                                    <li>
                                        Don’t abuse other users’ personal information. When you use Quickg — and
                                        especially if you create a successful page — you may receive information about
                                        other users, including things like their names, email addresses, and postal
                                        addresses. This information is provided for the purpose of participating in
                                        Quickg: don’t use it for other purposes, and don’t abuse it.
                                    </li>
                                </ul>
                                <h4>Things we don’t do and are not responsible for</h4>
                                <p>
                                    Quickg is not and should not be held liable for any damages or losses related to
                                    your use of the Services. We don’t become involved in disputes between users, or
                                    between users and any third party relating to the use of the Services. We don’t
                                    oversee the performance or punctuality of pages and contents, and we don’t endorse
                                    any content users submit to the Site. When you use the Services, you release Quickg
                                    from claims, damages, and demands of every kind — known or unknown, suspected or
                                    unsuspected, disclosed or undisclosed — arising out of or in any way related to such
                                    disputes and the Services. All content you access through the Services is at your
                                    own risk. You’re solely responsible for any resulting damage or loss to any party.
                                </p>
                                <h4>About our fees</h4>
                                <p>
                                    Creating an account on Quickg is free. If you create a page that is successfully
                                    supported, we (and our payment partners) collect fees. Our partners’ fees may vary
                                    slightly based on your location. Each payment provider is its own company, and
                                    Quickg isn’t responsible for its performance. You’re responsible for paying any
                                    additional fees or taxes associated with your use of Quickg.
                                </p>
                                <h4>About other websites and links</h4>
                                <p>
                                    Quickg may contain links to other websites. (For instance, user websites pages, user
                                    profiles, and comments that may link to other sites.) When you access third-party
                                    websites, you do so at your own risk. We don’t control or endorse those sites.
                                    <br/>
                                    Quickg partners with other companies (such as Stripe and PayPal) for payment
                                    processing. When you support or create a page, you’re also agreeing to the payment
                                    processor’s terms of service.
                                </p>
                                <h4>Our Intellectual Property</h4>
                                <p>
                                    Quickg’s services, content and Marks, are legally protected in a number of ways,
                                    including pursuant to copyright, trademark, service marks, patent, trade secrets,
                                    and other EU and international intellectual-property laws.
                                    You agree to respect all copyright and other legal notices, information, and
                                    restrictions contained in any Quickg Content, Services, or Marks accessed through
                                    the Site or the Services.
                                    You agree not to change, translate, or otherwise create derivative works of the
                                    Services.
                                    Quickg grants you a limited license (that is temporary, non-exclusive,
                                    non-sublicensable, and non-transferrable) to access and use User Content and
                                    Quickg’s Content solely for use of the Services in accordance with these Terms.
                                    You may not reproduce, redistribute, transmit, assign, sell, broadcast, rent, share,
                                    lend, modify, adapt, edit, create derivative works of, license, or otherwise
                                    transfer or use any User Content or Quickg Content unless We give you express
                                    written permission to do so.
                                    We reserve the right to revoke this limited license to access and use User Content
                                    and Quickg Content at any time and in our sole discretion.
                                </p>
                                <h4>Your intellectual property</h4>
                                <p>
                                    Any royalties or licensing on your Content are your responsibility. You will pay all
                                    royalties and other amounts owed to any person or entity based on your Content, or
                                    on Quickg’s hosting of that Content.
                                    <br/>
                                    You’re responsible for the stuff you post. All information submitted to the Site,
                                    whether publicly posted or privately transmitted, is the sole responsibility of the
                                    person from whom that content originated.
                                    We’re not responsible for mistakes in your content. Quickg will not be liable for
                                    any errors or omissions in any content.
                                </p>
                                <h4> About account termination</h4>
                                <p>
                                    You can terminate your account at any time through your account settings. We may
                                    retain certain information as required by law or as necessary for our legitimate
                                    business purposes. All provisions of this agreement survive termination of an
                                    account, including our rights regarding any content you’ve already submitted to the
                                    Site. (For instance, if you’ve launched a page, deleting your account will not
                                    automatically remove the page from the Site.) You can contact us at <a
                                    href="mailto:info@quickg.eu?Subject=Privacy"
                                    target="_top">info@quickg.eu</a> for additional information or to request page
                                    deletion (this is not available in all circumstances).
                                </p>
                                <h4>About indemnity</h4>
                                <p>
                                    You agree to defend, indemnify and hold harmless Quickg, Our subsidiaries and
                                    affiliated companies, and Our officers, directors, employees, partners, contractors,
                                    representatives, agents, and third party providers from and against any and all
                                    claims, causes of action, damages, obligations, losses, liabilities, costs or debt,
                                    and expenses (including reasonable attorneys&apos; fees and costs) and all amounts
                                    paid
                                    in settlement arising from or relating to, breach of these Terms or violation of any
                                    applicable laws. We reserve the right, in Our sole discretion and at Our own
                                    expense, to assume the exclusive defense and control of any matter for which you
                                    have agreed to indemnify us and you agree to assist and cooperate with us as
                                    reasonably required in the defense or settlement of any such matters.
                                </p>
                                <h4> About dispute resolution</h4>
                                <p>
                                    We at Quickg encourage you to contact us if you’re having an issue, before resorting
                                    to the courts. In the unfortunate situation where legal action does arise, these
                                    Terms (and all other rules, policies, or guidelines incorporated by reference) will
                                    be governed by and construed in accordance with the laws of the European union,
                                    without giving effect to any principles of conflicts of law, and without application
                                    of the Uniform Computer Information Transaction Act or the United Nations Convention
                                    of Controls for International Sale of Goods.
                                </p>
                                <h4> About copyright</h4>
                                <p>
                                    The Digital Millennium Copyright Act lays out a system of legal requirements for
                                    dealing with allegations of copyright infringement. Quickg complies with the DMCA,
                                    and we respond to notices of alleged infringement if they comply with the law and
                                    the requirements set forth in our Copyright Policy. We reserve the right to delete
                                    or disable content alleged to be infringing, and to terminate accounts for repeat
                                    infringers. (We do this when appropriate and at our sole discretion.)
                                </p>
                                <h4>Agreement Between You and Us</h4>
                                <p>
                                    These Terms are the entire agreement between You and Quickg with respect to the
                                    Services. They supersede all other communications and proposals (whether oral,
                                    written, or electronic) between you and Quickg with respect to the Services and
                                    govern our relationship. If any provision of these Terms are deemed invalid by a
                                    court of competent jurisdiction, the invalidity of such provision shall not affect
                                    the validity of the remaining provisions of this Agreement, which shall remain in
                                    full force and effect. Quickg&apos;s failure to assert any right or provision under
                                    this
                                    Agreement shall not constitute a waiver of such right or provision.
                                </p>
                                <h4>Miscellaneous</h4>
                                <p>
                                    We may modify or discontinue the Services at any time, in our sole discretion. You
                                    agree that, except as otherwise expressly provided in these Terms, there shall be no
                                    third-party beneficiaries to these Terms. No waiver of any provision of these Terms
                                    shall be deemed a further or continuing waiver of such term or any other term, and
                                    Quickg’s failure to assert any right or provision under these Terms shall not
                                    constitute a waiver of such right or provision. You agree that regardless of any
                                    statute or law to the contrary, any claim arising out of or related to the Services
                                    must commence within one (1) year after the cause of action accrues. Otherwise, such
                                    cause of action is permanently barred.
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Container>
    )
}

Terms.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
};

export default withTranslation()(Terms);
