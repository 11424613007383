import React from "react";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {Button, Col, Image, Row,} from "react-bootstrap";
import {useDispatch} from "react-redux";
import {stripeActions} from "../../../../actions/stripe";
import CardSection from "../../../Stripe/CardSection";
import PoweredByStripeImg from "../../../../styles/PoweredByStripeBlurple.svg";
import {CardElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {supportMeActions} from "../../../../actions/supportMe";
import {supportMeType} from "../../../../types";

const PaymentForm = ({t, i18n, supportMe, resetAmountForm}) => {
    const dispatch = useDispatch()

    const stripe = useStripe()
    const elements = useElements()

    const payIntent = supportMe.payIntent

    const pay = async () => {
        if (!stripe || !elements) {
            return;
        }

        if (payIntent.client_secret !== null && payIntent.isPaying !== true) {
            dispatch(stripeActions.payRequest())

            const result = await stripe.confirmCardPayment(payIntent.client_secret, {
                payment_method: {
                    card: elements.getElement(CardElement),
                    billing_details: {
                        name: supportMe.billingName,
                        email: supportMe.email,
                    },
                }
            });
            if (result.error) {
                dispatch(stripeActions.payFailure(result.error.message))
                // Show error to your customer (e.g., insufficient funds)
                // console.log(result.error.message);
            } else {
                // The payment has been processed!
                if (result.paymentIntent.status === 'succeeded') {
                    dispatch(stripeActions.paySuccess())
                    elements.getElement(CardElement).clear()
                    resetAmountForm()
                    dispatch(supportMeActions.clear())
                    // dispatch(baseApi.util.invalidateTags([{type: 'UserPublicProfiles', id: supportMe.userId}]))


                    // Show a success message to your customer
                    // There's a risk of the customer closing the window before callback
                    // execution. Set up a webhook or plugin to listen for the
                    // payment_intent.succeeded event that handles any business critical
                    // post-payment actions.
                }
            }
        } else {
            dispatch(supportMeActions.changeStep(0))
            // dispatch(stripeActions.payModalHide())
            // todo re-request intent
        }
    }


    return (
        <>
            {!process.env.NODE_ENV || process.env.NODE_ENV === 'development'
                ? <Row className={'text-center'}>
                    <Col><p>4000 0025 0000 3155</p></Col>
                </Row>
                : null
            }
            <Row className={'text-center pt-3 pb-3'}>
                <Col>
                    <CardSection/>
                </Col>
            </Row>
            <Row className={'text-center pt-3 pb-3'}>
                <Col>
                    <Image style={{height: 25}} src={PoweredByStripeImg}/>
                </Col>
            </Row>
            <Row className={'text-center mt-4'}>
                <Col>
                    <Button variant={'danger'} disabled={!stripe} onClick={() => {
                        pay()
                    }}>{t('supportMe.payNow')}</Button>
                </Col>
            </Row>

        </>
    )
};

PaymentForm.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
    supportMe: supportMeType,
    resetAmountForm: PropTypes.func.isRequired,
};

export default withTranslation()(PaymentForm);
