import React from "react";
import {withTranslation} from "react-i18next";
import {Toast} from "react-bootstrap";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Moment from "react-moment";
import {useDispatch, useSelector} from "react-redux";
import {hideAlert} from "../slicers/alertsSlice";

const Alerts = ({t, i18n}) => {
    const dispatch = useDispatch();
    const alerts = useSelector(state => state.alerts);

    return (
        <div className={'toasts-container'}>
            {alerts.alerts.slice().sort((a, b) => new Date(b.date) - new Date(a.date)).map(alert =>
                <Toast key={alert.id} show={alert.isVisible} onClose={() => dispatch(hideAlert(alert.id))}
                       delay={5000} autohide>
                    <Toast.Header>
                        <FontAwesomeIcon icon={alert.icon} size="lg" className={`me-2 text-${alert.type}`}/>
                        <strong className={`me-auto text-${alert.type}`}>{t(alert.title)}</strong>
                        <small className={'ms-2'}>
                            <Moment fromNow>{alert.date}</Moment>
                        </small>
                    </Toast.Header>
                    <Toast.Body>{t(alert.message)}</Toast.Body>
                </Toast>
            )}
        </div>
    )
};

Alerts.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
};


export default withTranslation()(Alerts);
