import React, {useRef} from "react";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {Button, Col, Form, Image, Offcanvas, Row} from "react-bootstrap";
import {useDispatch} from "react-redux";
import {hideOffcanvas} from "../../slicers/contactOffcanvasSlice";
import * as ReactGA from "react-ga";
import logo from "../../styles/LogoPink.png";
import {Formik} from "formik";
import {useCreateContactMutation} from "../../services/contactService";
import {object, string} from "yup";

const ContactOffcanvas = ({t, i18n, offcanvasState}) => {
    const dispatch = useDispatch()

    const schema = object({
        name: string().required(),
        message: string().required(),
        email: string().email(),
        subject: string().required(),
    });

    const formikRef = useRef()
    const [createContact, {isLoading}] = useCreateContactMutation()

    return (
        <Offcanvas className={'offcanvas-width'} key={'contactOffcanvas'} show={offcanvasState.showOffcanvas}
                   onHide={() => {
                       dispatch(hideOffcanvas(formikRef.current.values))
                   }} placement={'end'}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>{t('footer.contactUs')}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Formik
                    innerRef={formikRef}
                    onSubmit={(values, {setSubmitting, resetForm}) => {
                        ReactGA.event({
                            category: 'Contact',
                            action: 'Contact',
                        });
                        setSubmitting(true);
                        createContact(values)
                        setSubmitting(false);
                        resetForm({})
                    }}
                    validationSchema={schema}
                    initialValues={{
                        name: offcanvasState.name,
                        email: offcanvasState.email,
                        message: offcanvasState.message,
                        subject: offcanvasState.subject,
                    }}>
                    {({
                          handleSubmit,
                          handleChange,
                          handleReset,
                          handleBlur,
                          values,
                          dirty,
                          touched,
                          isValid,
                          isSubmitting,
                          errors,
                      }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Image fluid width={'40%'} src={logo}/>
                            <h6>{t('contact.content')}</h6>
                            <hr/>
                            <Form.Group controlId="contact.name">
                                <Form.Label>{t('contact.name')}</Form.Label>
                                <Form.Control type="text"
                                              name="name"
                                              placeholder={t('contact.placeholders.name')}
                                              value={values.name}
                                              onChange={handleChange}
                                              isValid={touched.name && !errors.name}
                                              isInvalid={!!errors.name}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.name}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="contact.email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="text"
                                              name="email"
                                              placeholder={t('contact.placeholders.email')}
                                              value={values.email}
                                              onChange={handleChange}
                                              isValid={touched.email && !errors.email}
                                              isInvalid={!!errors.email}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.email}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="contact.type">
                                <Form.Label>{t('contact.subject')}</Form.Label>
                                <Form.Select
                                    name="subject"
                                    value={values.subject}
                                    onChange={handleChange}
                                    isValid={touched.subject && !errors.subject}
                                    isInvalid={!!errors.subject}
                                    aria-label={t('contact.subjects.contact')}
                                >
                                    <option value="contact">{t('contact.subjects.contact')}</option>
                                    <option value="issue">{t('contact.subjects.issue')}</option>
                                    <option value="feedback">{t('contact.subjects.feedback')}</option>
                                    <option value="suggestion">{t('contact.subjects.suggestion')}</option>
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    {errors.subject}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="contact.message">
                                <Form.Label>{t('contact.message')}</Form.Label>
                                <Form.Control as="textarea" rows="3"
                                              type="text"
                                              name="message"
                                              placeholder={t('contact.placeholders.message')}
                                              value={values.message}
                                              onChange={handleChange}
                                              isValid={touched.message && !errors.message}
                                              isInvalid={!!errors.message}

                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Row>
                                <Col className={'text-center mt-4'}>
                                    <Button variant="primary" size="lg" block type="submit"
                                            disabled={isSubmitting || isLoading}>{t('footer.contactUs')}</Button>

                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </Offcanvas.Body>
        </Offcanvas>
    )
};

ContactOffcanvas.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
    offcanvasState: PropTypes.shape({
        showOffcanvas: PropTypes.bool.isRequired,
        name: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        message: PropTypes.string.isRequired,
        subject: PropTypes.string.isRequired,
    }),
    isFeedback: PropTypes.bool,
};

export default withTranslation()(ContactOffcanvas);
