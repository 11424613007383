import React from "react";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {Button, Col, Form, FormControl, InputGroup, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as ReactGA from "react-ga";
import {Formik} from "formik";
import {number, object} from "yup";
import {useDispatch, useSelector} from "react-redux";
import SupportMeOffcanvas from "./SupportMeOffcanvas";
import {supportMeActions} from "../../../../actions/supportMe";

const SupportMeForm = ({t, i18n, billingInfoId, userId}) => {
    const dispatch = useDispatch()

    const schema = object({
        amount: number().required().min(3).max(1000).integer(),
    });

    const supportMe = useSelector(state => state.supportMe);

    return (
        <Row className={'w-100 pt-4'}>
            <Col>
                <Formik
                    validationSchema={schema}
                    validateOnMount={false}
                    onSubmit={(values, {setSubmitting, resetForm}) => {
                        ReactGA.event({
                            category: 'UserSupport',
                            action: 'Support user - amount'
                        });
                        setSubmitting(true);
                        dispatch(supportMeActions.showOffcanvas(userId, billingInfoId, values.amount))
                        setSubmitting(false);
                    }}
                    enableReinitialize={true}
                    initialValues={{
                        amount: '',
                    }}>
                    {({
                          handleSubmit,
                          handleChange,
                          handleReset,
                          handleBlur,
                          values,
                          dirty,
                          touched,
                          isValid,
                          isSubmitting,
                          errors,
                          setFieldValue,
                          setFieldTouched,
                      }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Row>
                                <Col md={3} xs={7} className={'mx-auto'}>
                                    <Form.Label htmlFor={'amount'} visuallyHidden={true}>
                                        {t('supportMe.amount')}
                                    </Form.Label>
                                    <InputGroup className="mb-1" hasValidation={true}>
                                        <InputGroup.Text><FontAwesomeIcon icon={'euro-sign'}/></InputGroup.Text>
                                        <FormControl
                                            id={'amount'}
                                            name={`amount`}
                                            type={'text'}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.amount}
                                            placeholder={t('supportMe.amount')}
                                            isValid={touched.amount && !errors.amount}
                                            isInvalid={!!errors.amount}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            <small>{errors.amount}</small>
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Button type={'submit'}>{t('supportMe.title')}</Button>

                            <SupportMeOffcanvas supportMe={supportMe} resetAmountForm={handleReset}/>
                        </Form>
                    )}
                </Formik>
            </Col>
        </Row>
    )
};

SupportMeForm.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
    billingInfoId: PropTypes.string.isRequired,
    email: PropTypes.string,
    userId: PropTypes.string,
};

export default withTranslation()(SupportMeForm);
