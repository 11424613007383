import React, {useEffect} from "react";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import Loading from "../../components/General/Loading";
import {useGetCurrentUserQuery} from "../../services/userService";
import DoubleShapeTop from "../../components/General/DoubleShapeTop";
import DoubleShapeBottom from "../../components/General/DoubleShapeBottom";
import Header from "../../components/Header";
import {RegisterHeader, RegisterWrapper, RegistrationContainer} from "../../styled-components/S_Registration";
import UserPhotoPlaceholderImg from "../../styles/images/profile-user.svg";
import Moment from "react-moment";
import {useGetRecentSupportersQuery} from "../../services/supportMeService";

const SupportersList = ({t, i18n}) => {

    const {data: user, isSuccess: userSuccess} = useGetCurrentUserQuery()

    console.log(user)
    console.log(userSuccess)
    const {
        data,
        isFetching,
    } = useGetRecentSupportersQuery({id: user?.id, limit: 50}, {skip: !userSuccess})

    console.log(data)
    console.log(isFetching)

    useEffect(() => {
        document.title = 'Quickg - '.concat(t('supportMe.recent.title'));
    });

    if (isFetching || !userSuccess) {
        return (
            <Loading/>
        )
    }

    return (
        <RegistrationContainer>
            <Header hasGrayBackground={true}/>
            <div>
                <RegisterWrapper>
                    <RegisterHeader>
                        <h1>{t('emailSubscribe.list.title')}</h1>
                    </RegisterHeader>
                    <section className="support-section pt-0">
                        <div
                            className="container d-flex align-items-center flex-column justify-content-center text-center">
                            <div className="support-wrapper">
                                {data.map(supporter => (
                                    <div className="support-card" key={supporter.id}>
                                        <img src={UserPhotoPlaceholderImg} alt="supporter"/>
                                        <div className="info">
                                            <p className='name'>{supporter.username} <span className='date'><Moment
                                                date={supporter.created_at} format={'ll'}/></span></p>
                                            <p className='bio'>{supporter.message}</p>
                                        </div>
                                        <h4 className="price">{supporter.amount}</h4>
                                    </div>
                                ))
                                }
                            </div>
                        </div>
                    </section>
                </RegisterWrapper>
            </div>
            <DoubleShapeTop/>
            <DoubleShapeBottom/>
        </RegistrationContainer>
    )
}

SupportersList.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
};

export default withTranslation()(SupportersList);
