import React, {useEffect} from "react";
import {
    AlreadyHave,
    InputGroup,
    PasswordContainer,
    RegisterHeader,
    RegisterInputContainer,
    RegisterInputs,
    RegisterWrapper,
    RegistrationContainer,
    Terms,
} from "../styled-components/S_Registration";
import {IconInput, Text} from "../styled-components/S_Setting";
import {PrimaryButton} from "../styled-components/Buttons";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import Header from "../components/Header";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {userActions} from "../actions/user";
import * as Yup from "yup";
import {bool, object, string} from "yup";
import * as ReactGA from "react-ga";
import {Form} from "react-bootstrap";
import {Formik} from "formik";
import ValidateFromApi from "../components/ValidateFromApi";

const Register = ({t, i18n}) => {
    const dispatch = useDispatch();
    const registrationState = useSelector(state => state.registration);

    // todo extract to BE and add endpoint + validation
    const notAllowedNames = [
        'admin',
        'dashboard',
        'aykon',
        'aykonsvk',
        'changePassword',
        'emails',
        'forgotPassword',
        'giftology',
        'janosik',
        'login',
        'martin',
        'martive',
        'profile',
        'privacy',
        'recentSupporters',
        'register',
        'setting',
        'settings',
        'unsubscribe',
        'tags',
        'terms',
        'verifyEmail',
        'gdpr',
        'quickg',
    ]

    useEffect(() => {
        dispatch(userActions.logout());
        document.title = 'Quickg - Register';
    }, [dispatch]);

    const schema = object({
        firstName: string().min(2).max(50).required('First Name is required'),
        lastName: string().min(2).max(50).required('Last Name is required'),
        username: string()
            .min(2)
            .max(50)
            .required('Username is required')
            .test("notAllowed", "Not allowed username", val => !notAllowedNames.includes(val?.toLowerCase())),
        email: string().min(2).max(50).email('Email is invalid').required('Email is required'),
        password: string()
            .min(6)
            .max(50)
            .min(6, 'Password must be at least 6 characters')
            .required('Password is required'),
        confirmPassword: string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Confirm Password is required'),
        acceptTerms: bool()
            .oneOf([true], 'Accept Terms & Conditions is required'),
        marketingEmails: bool(),
    });

    return (
        <RegistrationContainer>
            <Header hasGrayBackground={true}/>
            <div>
                <RegisterWrapper>
                    <RegisterHeader>
                        <h1>{t('register.title')}</h1>
                        {/*<p>*/}
                        {/*    It is a long established fact that a reader will be distracted by*/}
                        {/*    the readable content of a page when looking at its layout.*/}
                        {/*</p>*/}
                    </RegisterHeader>
                    <Formik
                        onSubmit={(values, {setSubmitting, resetForm}) => {
                            setSubmitting(true);
                            ReactGA.event({
                                category: 'Register',
                                action: 'Register Form'
                            });
                            dispatch(userActions.register(values));
                            setSubmitting(false);
                        }}
                        enableReinitialize={true}
                        validationSchema={schema}
                        initialValues={{
                            firstName: '',
                            lastName: '',
                            username: '',
                            email: '',
                            password: '',
                            confirmPassword: '',
                            acceptTerms: false,
                            marketingEmails: false,
                        }}>
                        {({
                              handleSubmit,
                              handleChange,
                              handleReset,
                              handleBlur,
                              values,
                              dirty,
                              touched,
                              isValid,
                              isSubmitting,
                              errors,
                              setFieldValue,
                              setFieldTouched,
                          }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <RegisterInputs>
                                    <InputGroup>
                                        <RegisterInputContainer>
                                            <Form.Group controlId="registerForm.username">
                                                <Text><Form.Label>{t('register.form.username')}:</Form.Label></Text>
                                                <IconInput>
                                                    <div>
                                                        <FontAwesomeIcon icon={'user'}/>
                                                        <Form.Control type="text"
                                                                      name="username"
                                                                      placeholder={'User123'}
                                                                      value={values.username}
                                                                      onChange={handleChange}
                                                                      onBlur={handleBlur}
                                                                      isValid={touched.username && !errors.username}
                                                                      isInvalid={!!errors.username}
                                                        />
                                                    </div>
                                                </IconInput>
                                                <Form.Control.Feedback type="invalid" className={'tooltip text-danger'}>
                                                    {errors.username}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </RegisterInputContainer>
                                        <RegisterInputContainer>
                                            <Form.Group controlId="registerForm.firstName">
                                                <Text><Form.Label>{t('register.form.firstName')}:</Form.Label></Text>
                                                <IconInput>
                                                    <div>
                                                        <FontAwesomeIcon icon={'user'}/>
                                                        <Form.Control type="text"
                                                                      name="firstName"
                                                                      placeholder={'Jane'}
                                                                      value={values.firstName}
                                                                      onChange={handleChange}
                                                                      onBlur={handleBlur}
                                                                      isValid={touched.firstName && !errors.firstName}
                                                                      isInvalid={!!errors.firstName}
                                                        />
                                                    </div>
                                                </IconInput>
                                                <Form.Control.Feedback type="invalid" className={'tooltip text-danger'}>
                                                    {errors.firstName}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </RegisterInputContainer>
                                        <RegisterInputContainer>
                                            <Form.Group controlId="registerForm.lastName">
                                                <Text><Form.Label>{t('register.form.lastName')}:</Form.Label></Text>
                                                <IconInput>
                                                    <div>
                                                        <FontAwesomeIcon icon={'user'}/>
                                                        <Form.Control type="text"
                                                                      name="lastName"
                                                                      placeholder={'Doe'}
                                                                      value={values.lastName}
                                                                      onChange={handleChange}
                                                                      onBlur={handleBlur}
                                                                      isValid={touched.lastName && !errors.lastName}
                                                                      isInvalid={!!errors.lastName}
                                                        />

                                                    </div>
                                                </IconInput>
                                                <Form.Control.Feedback type="invalid" className={'tooltip text-danger'}>
                                                    {errors.lastName}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </RegisterInputContainer>
                                    </InputGroup>
                                    <RegisterInputContainer>
                                        <Form.Group controlId="registerForm.email">
                                            <Text><Form.Label>{t('register.form.email')}:</Form.Label></Text>
                                            <IconInput>
                                                <div>
                                                    <FontAwesomeIcon icon={'envelope'}/>
                                                    <Form.Control type="text"
                                                                  name="email"
                                                                  placeholder={'jane@quickg.eu'}
                                                                  value={values.email}
                                                                  onChange={handleChange}
                                                                  onBlur={handleBlur}
                                                                  isValid={touched.email && !errors.email}
                                                                  isInvalid={!!errors.email}
                                                    />
                                                </div>
                                            </IconInput>
                                            <Form.Control.Feedback type="invalid" className={'tooltip text-danger'}>
                                                {errors.email}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </RegisterInputContainer>
                                    <PasswordContainer>
                                        <RegisterInputContainer>
                                            <Form.Group controlId="registerForm.password">
                                                <Text><Form.Label>{t('register.form.password')}:</Form.Label></Text>
                                                <IconInput>
                                                    <div>
                                                        <FontAwesomeIcon icon={'lock'}/>
                                                        <Form.Control type="password"
                                                                      name="password"
                                                            // placeholder={'*********'}
                                                                      value={values.password}
                                                                      onChange={handleChange}
                                                                      onBlur={handleBlur}
                                                                      isValid={touched.password && !errors.password}
                                                                      isInvalid={!!errors.password}
                                                        />
                                                    </div>
                                                </IconInput>
                                                <Form.Control.Feedback type="invalid" className={'tooltip text-danger'}>
                                                    {errors.password}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </RegisterInputContainer>
                                        {/*<PasswordRate rate={[1, 2, 3]}>*/}
                                        {/*    /!*<p>Password is too short</p>*!/*/}
                                        {/*    <div></div>*/}
                                        {/*    <div></div>*/}
                                        {/*    <div></div>*/}
                                        {/*    <div></div>*/}
                                        {/*    <div></div>*/}
                                        {/*    <div></div>*/}
                                        {/*    <div></div>*/}
                                        {/*</PasswordRate>*/}
                                    </PasswordContainer>
                                    <PasswordContainer>
                                        <RegisterInputContainer>
                                            <Form.Group controlId="registerForm.confirmPassword">
                                                <Text><Form.Label>{t('register.form.confirmPassword')}:</Form.Label></Text>
                                                <IconInput>
                                                    <div>
                                                        <FontAwesomeIcon icon={'lock'}/>
                                                        <Form.Control type="password"
                                                                      name="confirmPassword"
                                                            // placeholder={'*********'}
                                                                      value={values.confirmPassword}
                                                                      onChange={handleChange}
                                                                      onBlur={handleBlur}
                                                                      isValid={touched.confirmPassword && !errors.confirmPassword}
                                                                      isInvalid={!!errors.confirmPassword}
                                                        />
                                                    </div>
                                                </IconInput>
                                                <Form.Control.Feedback type="invalid" className={'tooltip text-danger'}>
                                                    {errors.confirmPassword}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </RegisterInputContainer>
                                        {/*    <PasswordRate rate={[1, 2, 3]}>*/}
                                        {/*    /!*<p>Password is too short</p>*!/*/}
                                        {/*    <div></div>*/}
                                        {/*    <div></div>*/}
                                        {/*    <div></div>*/}
                                        {/*    <div></div>*/}
                                        {/*    <div></div>*/}
                                        {/*    <div></div>*/}
                                        {/*    <div></div>*/}
                                        {/*</PasswordRate>*/}
                                    </PasswordContainer>
                                    <Terms>
                                        <Form.Group controlId="registerForm.acceptTerms">
                                            <p><Form.Label>Please accept <Link to='/terms' target="_blank"
                                                                               rel="noopener noreferrer">Terms of
                                                use</Link> and <Link to='/privacy' target="_blank"
                                                                     rel="noopener noreferrer">Privacy
                                                policy</Link></Form.Label></p>
                                            <Form.Check
                                                custom
                                                name="acceptTerms"
                                                checked={values.acceptTerms}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value="false"
                                                type='checkbox'
                                                label={`I'm over 18 and I accept Quickg terms of use and privacy policy`}
                                                isInvalid={!!errors.acceptTerms}
                                            />
                                            <Form.Control.Feedback type="invalid" className={'tooltip text-danger'}>
                                                {errors.acceptTerms}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Terms>

                                    <Terms>
                                        <Form.Group controlId="registerForm.marketingEmails">
                                            <Form.Check
                                                custom
                                                name="marketingEmails"
                                                checked={values.marketingEmails}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value="false"
                                                type='checkbox'
                                                label={`I'm interested in updates from Quickg and partners`}
                                                isInvalid={!!errors.marketingEmails}
                                            />
                                            <Form.Control.Feedback type="invalid" className={'tooltip text-danger'}>
                                                {errors.marketingEmails}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Terms>
                                    <PrimaryButton>Register</PrimaryButton>

                                    <AlreadyHave>
                                        <hr/>
                                        <h4>Already have an account?</h4>
                                        <hr/>
                                    </AlreadyHave>
                                    <Link to={'/login'}>
                                        <PrimaryButton outline>{t('nav.signIn')}</PrimaryButton>
                                    </Link>
                                </RegisterInputs>
                                <ValidateFromApi errors={registrationState.errors}/>

                            </Form>
                        )}
                    </Formik>
                </RegisterWrapper>
            </div>
        </RegistrationContainer>
    );
}

Register.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
};

export default withTranslation()(Register);
