import {baseApi} from "./apiService";

export const contactApi = baseApi.injectEndpoints({
        overrideExisting: false,
        endpoints: (builder) => ({
            createContact: builder.mutation({
                query(data) {
                    return {
                        url: `contacts`,
                        method: 'POST',
                        body: data,
                    }
                },
            }),
        }),
    }
)

export const {
    useCreateContactMutation,
} = contactApi
