// import config from 'config';
import {authHeader} from "../helpers/authHeader";
import {URL} from "../constants/API";
import {handleResponse, handleResponseWithoutLogout} from "../helpers/handleResponse";
import {baseApi} from "./apiService";

export const userService = {
    login,
    logout,
    register,
    getById,
    changePassword,
    update,
    delete: _delete
};
const config = {
    apiUrl: URL.concat('/api')
}


function login(username, password) {

    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({username, password})
    };

    return fetch(`${URL}/api/login_check`, requestOptions)
        .then(handleResponseWithoutLogout)
        .then(response => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(response));

            return response;
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

// function getAll() {
//     const requestOptions = {
//         method: 'GET',
//         headers: authHeader(),
//     };
//
//     return fetch(`${config.apiUrl}/users`, requestOptions).then(handleResponse);
// }

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}/users/${id}`, requestOptions).then(handleResponse);
}

function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(user)
    };

    return fetch(`${URL}/api/users/register`, requestOptions).then(handleResponse);
}

function update(id, user) {
    const requestOptions = {
        method: 'PUT',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
        body: JSON.stringify(user)
    };

    return fetch(`${config.apiUrl}/users/${id}`, requestOptions).then(handleResponse);
}

function changePassword(id, passwords) {
    const requestOptions = {
        method: 'PUT',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
        body: JSON.stringify(passwords)
    };

    return fetch(`${config.apiUrl}/users/${id}/change_password`, requestOptions).then(handleResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}/users/${id}`, requestOptions).then(handleResponse);
}

export const userApi = baseApi.injectEndpoints({
        overrideExisting: false,
        endpoints: (builder) => ({
            getUserPublicProfileByUsername: builder.query({
                query: (username) => `/users/profile/${username}`,
                providesTags: (result, errors, id) =>
                    result
                        ? [
                            {type: 'UserPublicProfiles', id: result.id},
                            {type: 'UserPublicProfiles'},
                        ] : [
                            {type: 'UserPublicProfiles'},
                        ]
            }),
            getUserPublicProfileById: builder.query({
                query: (id) => `/users/${id}`,
                providesTags: (result, errors, id) =>
                    result
                        ? [
                            {type: 'UserPublicProfiles', id: result.id},
                            {type: 'UserPublicProfiles'},
                        ] : [
                            {type: 'UserPublicProfiles'},
                        ]
            }),
            getEmailVerificationToken: builder.query({
                query: (id) => `/users/verify/${id}`,
                providesTags: (result) => [{type: 'EmailToken'}],
            }),
            resendEmailVerificationToken: builder.mutation({
                query() {
                    return {
                        url: `/users/verify/resend`,
                        method: 'GET',
                    }
                },
                invalidatesTags: [{type: 'EmailToken'}],
            }),
            forgotPasswordEmail: builder.mutation({
                query(data) {
                    const {id, ...body} = data
                    return {
                        url: `users/password/request`,
                        method: 'POST',
                        body,
                    }
                },
                // invalidatesTags: (result, error, {id}) => [{type: 'Projects', id}],
            }),
            getForgotPasswordToken: builder.query({
                query: (id) => `/users/password/verify/${id}`,
                // providesTags: (result) => [{type: 'EmailToken'}],
            }),
            changeForgotPassword: builder.mutation({
                query(data) {
                    const {id, ...body} = data
                    return {
                        url: `users/password/change`,
                        method: 'POST',
                        body,
                    }
                },
                // invalidatesTags: (result, error, {id}) => [{type: 'Projects', id}],
            }),
            changeUserSettings: builder.mutation({
                query(data) {
                    const {id, ...body} = data
                    return {
                        url: `/users/${id}/profile_settings`,
                        method: 'POST',
                        body,
                    }
                },
                invalidatesTags: (result, error, {id}) => [{type: 'UserPublicProfiles', id}, {type: 'CurrentUser'}],
            }),
            changeSocialLinks: builder.mutation({
                query(data) {
                    const {id, ...body} = data
                    return {
                        url: `/users/${id}/social_links`,
                        method: 'POST',
                        body,
                    }
                },
                invalidatesTags: (result, error, {id}) => [{type: 'UserPublicProfiles', id}, {type: 'CurrentUser'}],
            }),
            getCurrentUser: builder.query({
                query: (id) => `users/me`,
                providesTags: (result) => [{type: 'CurrentUser'}],
            }),
            getDiscoveryUser: builder.query({
                query: () => `users/discovery`,
                // providesTags: (result) => [{type: 'D'}],
            }),
        }),
    }
)

export const {
    useGetUserPublicProfileByUsernameQuery,
    useGetUserPublicProfileByIdQuery,
    useGetEmailVerificationTokenQuery,
    useResendEmailVerificationTokenMutation,
    useForgotPasswordEmailMutation,
    useGetForgotPasswordTokenQuery,
    useChangeForgotPasswordMutation,
    useChangeSocialLinksMutation,
    useChangeUserSettingsMutation,
    useGetCurrentUserQuery,
    useGetDiscoveryUserQuery,
} = userApi

// todo start timer on login
// todo invalidate token
// todo store refresh token in cookie
// todo on timer reset request new token
// todo use jwt decode function
//
// let refreshTokenTimeout;
//
// function startRefreshTokenTimer() {
//     // parse json object from base64 encoded jwt token
//     const jwtToken = JSON.parse(atob(userSubject.value.jwtToken.split('.')[1]));
//
//     // set a timeout to refresh the token a minute before it expires
//     const expires = new Date(jwtToken.exp * 1000);
//     const timeout = expires.getTime() - Date.now() - (60 * 1000);
//     refreshTokenTimeout = setTimeout(refreshToken, timeout);
// }
//
// function stopRefreshTokenTimer() {
//     clearTimeout(refreshTokenTimeout);
// }
