import React, {useRef} from "react";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {Button, Col, Form, Image, Offcanvas, Row} from "react-bootstrap";
import {useDispatch} from "react-redux";
import {hideOffcanvas} from "../../slicers/reportOffcanvasSlice";
import * as ReactGA from "react-ga";
import logo from "../../styles/LogoPink.png";
import {Formik} from "formik";
import {object, string} from "yup";
import {useCreateReportMutation} from "../../services/reportService";

const ReportOffcanvas = ({t, i18n, offcanvasState}) => {
    const dispatch = useDispatch()

    const schema = object({
        reason: string().required(),
        message: string().required(),
    });

    const formikRef = useRef()
    const [createReport, {isLoading}] = useCreateReportMutation()

    return (
        <Offcanvas className={'offcanvas-width'} key={'contactOffcanvas'} show={offcanvasState.showOffcanvas}
                   onHide={() => {
                       dispatch(hideOffcanvas())
                   }} placement={'end'}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>{t('report.header')}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Formik
                    innerRef={formikRef}
                    onSubmit={(values, {setSubmitting, resetForm}) => {
                        ReactGA.event({
                            category: 'Report',
                            action: 'Report',
                        });
                        setSubmitting(true);
                        createReport({...values, userId: offcanvasState.user})
                        setSubmitting(false);
                        resetForm({})
                    }}
                    validationSchema={schema}
                    initialValues={{
                        reason: offcanvasState.reason,
                        message: offcanvasState.message,
                    }}>
                    {({
                          handleSubmit,
                          handleChange,
                          handleReset,
                          handleBlur,
                          values,
                          dirty,
                          touched,
                          isValid,
                          isSubmitting,
                          errors,
                      }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Image fluid width={'40%'} src={logo}/>
                            <h6>{t('report.content')}</h6>
                            <hr/>
                            <Form.Group controlId="report.reason">
                                <Form.Label>{t('report.reason')}</Form.Label>
                                <Form.Select
                                    name="reason"
                                    value={values.reason}
                                    onChange={handleChange}
                                    isValid={touched.reason && !errors.reason}
                                    aria-label={t('report.subjects.fake')}
                                >
                                    <option value="fake">{t('report.subjects.fake')}</option>
                                    <option value="adult">{t('report.subjects.adult')}</option>
                                    <option value="prohibited">{t('report.subjects.prohibited')}</option>
                                    <option value="other">{t('report.subjects.other')}</option>
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    {errors.reason}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="report.message">
                                <Form.Label>{t('report.message')}</Form.Label>
                                <Form.Control as="textarea" rows="3"
                                              type="text"
                                              name="message"
                                              placeholder={t('report.placeholders.message')}
                                              value={values.message}
                                              onChange={handleChange}
                                              isValid={touched.message && !errors.message}/>
                                <Form.Control.Feedback type="invalid">
                                    {errors.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Row>
                                <Col className={'text-center mt-4'}>
                                    <Button variant="primary" size="lg" block type="submit"
                                            disabled={isSubmitting || isLoading}>{t('button.submit')}</Button>

                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </Offcanvas.Body>
        </Offcanvas>
    )
};

ReportOffcanvas.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
    offcanvasState: PropTypes.shape({
        showOffcanvas: PropTypes.bool.isRequired,
        user: PropTypes.string,
        reason: PropTypes.string.isRequired,
        message: PropTypes.string.isRequired,
    }),
    isFeedback: PropTypes.bool,
};

export default withTranslation()(ReportOffcanvas);
