import React from "react";
import {UserContainer} from "../../../styled-components/S_Setting";
import styled from "styled-components";
import Header from "../../Header";
import UserInfo from "../PublicProfile/UserInfo";
import {userPrivateType} from "../../../types";


export default function User({user}) {
    return (
        <UserContainer>
            <Header/>
            <UserInfo user={user} isPrivateProfile={true}/>
        </UserContainer>
    );
}

User.propTypes = {
    user: userPrivateType.isRequired,
};

export const EditProfile = styled.button`
  color: #f05252;
  outline: none;
  border: none;
  line-height: 20px;
  text-decoration: underline;
  background: transparent;
  font-size: 0.9rem;
  font-weight: 700;
  svg {
    margin-left: 5px;
    font-size: 22px;
  }
  span {
    display: inline-flex;
  }
  
`;
