import styled from "styled-components";
import {colors, flexStart, spaceBetween} from "./styledUtils";

export const RegistrationContainer = styled.div`
  background: #f5f5f5;
  padding-bottom: 5rem;
  > div:last-child {
    padding: 0 1rem;
  }
`;

export const RegisterWrapper = styled.div`
  max-width: 1000px;
  border-radius: 10px;
  background: #fff;
  text-align: center;
  margin: auto;
  padding: 3rem 6rem;
  margin: -11rem auto 0;
  position: relative;
  @media (max-width: 646px) {
    padding: 3rem 2rem;
  }
`;

export const RegisterHeader = styled.div`
  margin-bottom: 2rem;
  h1 {
    font-size: 2.5rem;
  }
  p {
    font-size: 1.1rem;
    max-width: 650px;
    margin: auto;
    font-weight: 700;
    line-height: 1.3rem;
  }
  @media (max-width: 452px) {
    h1 {
      font-size: 2rem;
    }
    p {
      font-size: 0.8rem;
    }
  }
`;

export const RegisterInputs = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  margin-top: 1rem;
`;

export const RegisterInputContainer = styled.div`
  width: 100%;
  text-align: left;

  p {
    margin: 0;
    text-align: left;
    font-size: 0.9rem;
  }
  label {
    margin: 0;
    padding: 0;
  }
  svg {
    padding: 6px 10px !important;
    font-size: 1.3rem !important;
  }
  @media (max-width: 646px) {
    svg {
      font-size: 1rem !important;
    }
    p {
      font-size: 0.8rem;
    }
  }
`;

export const InputGroup = styled.div`
  ${spaceBetween}
  column-gap: 3rem;
  @media (max-width: 889px) {
    flex-direction: column;
    row-gap: 1rem;
  }
`;

export const PasswordContainer = styled.div``;

export const PasswordRate = styled.div`
  ${flexStart}
  column-gap: 3px;
  p {
    font-size: 0.9rem;
    font-weight: 700;
    margin-right: 1rem;
    margin-bottom: 0;
  }
  div {
    width: 17px;
    height: 5px;
    border-radius: 50px;
    background: #56fced;

    :nth-child(3) {
      opacity: 0.7;
    }
    :nth-child(4) {
      opacity: 0.5;
    }
    :nth-child(5) {
      opacity: 0.2;
    }
    :nth-child(6) {
      background: #eaeaea;
    }
    :nth-child(7) {
      background: #eaeaea;
    }
    :nth-child(8) {
      background: #eaeaea;
    }
  }
  @media (max-width: 645px) {
    div {
      width: 11px;
    }
    p {
      font-size: 0.7rem;
      margin-right: 10px;
    }
  }
`;

export const Terms = styled.div`
  * {
    text-align: left;
  }
  .form-check {
      label {
        font-weight: 700;
        text-align: left;
        margin-bottom: 4px;
        font-size: 16px;
        display: flex;
      }
  }
  @media (max-width: 425px) {
    * {
      font-size: 14px;
    }
  }
  p {
    font-weight: 700;
    text-align: left;
    margin-bottom: 4px;
    font-size: 16px;
    :first-child {
      span {
        color: ${colors.primary};
      }
    }
    :last-child {
      display: flex;
      align-items: flex-start;
      ${flexStart}

      /* label {
        margin-top: 9px;
      } */
      svg {
        color: #fff;
      }
      > span {
        margin-left: 1rem;
      }
    }
  }
  @media (max-width: 425px) {
    * {
      font-size: 14px;
    }
  }
`;

export const AlreadyHave = styled.div`
  ${spaceBetween}
  column-gap: 3rem;
  hr {
    height: 2px;
    background: #afafaf;
    width: 18%;
  }
  h4 {
    font-size: 1.5rem;
    margin-bottom: 0;
    @media (max-width: 425px) {
      font-size: 1rem;
    }
  }
  @media (max-width: 425px) {
    column-gap: 1rem;
  }
`;
