import React from 'react'
import DoubleShapeTop from "../../General/DoubleShapeTop";
import DoubleShapeBottom from "../../General/DoubleShapeBottom";
import {withTranslation} from "react-i18next";
import {Button, Col, Row} from "react-bootstrap";
import PropTypes from "prop-types";
import {useCreateMetricMutation} from "../../../services/metricService";

const Links = ({t, i18n, userId, links = []}) => {
    const [submitMetric] = useCreateMetricMutation()

    return (
        <section className='cta-section'>
            <div className="container d-flex align-items-center flex-column justify-content-center text-center">
                <h3>{t('user.links.title')}</h3>
                {/*<p>It is a long established fact that a reader will be distracted by the readable content of a page when*/}
                {/*    looking at its layout.</p>*/}
                {links.length === 0
                    ? <Row className='text-center'>
                        <Col>
                            <h4>{t('user.links.none')}</h4>
                        </Col>
                    </Row>
                    : <div className="btns">
                        {links.map((link, key) => (
                            <a key={key} rel='noreferrer' href={link.url} target='_blank' onClick={() => submitMetric({
                                entity: 'Link',
                                entityId: link.id,
                                userAction: 'click',
                                user: userId
                            })}>
                                <Button variant="primary" size="lg">{link.name}</Button>
                            </a>
                        ))}
                    </div>
                }

            </div>
            <DoubleShapeTop half={true}/>
            <DoubleShapeBottom half={true}/>
        </section>
    )
}

Links.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
    links: PropTypes.array.isRequired,
    userId: PropTypes.string.isRequired,
};

export default withTranslation()(Links);
