export const textStyles = {
    h1: {
        fontSize: {
            base: "5xl",
            md: "6xl",
            lg: "7xl",
        },

        lineHeight: {
            base: "110%",
        },
        fontWeight: "bold",
    },
    h2: {
        fontSize: {
            base: "4xl",
            md: "5xl",
            lg: "6xl",
        },
        lineHeight: {
            base: "110%",
        },
        fontWeight: "bold",
    },
    h3: {
        fontSize: {
            base: "3xl",
            md: "4xl",
        },
    },
    h4: {
        fontSize: {
            base: "2xl",
            md: "3xl",
        },
    },
    h5: {
        fontSize: {
            base: "xl",
            md: "2xl",
        },
    },
    h6: {
        fontSize: {
            base: "lg",
            md: "xl",
        },
    },

    p1_bold: {
        fontSize: {
            base: "md",
            md: "lg",
        },
        fontWeight: "bold",
        color: "dark.700",
    },
    p1: {
        fontSize: {
            base: "md",
            md: "lg",
        },
        fontWeight: "normal",
        color: "dark.700",
    },
    p2: {
        fontSize: {
            base: "sm",
            lg: "md",
        },
        fontWeight: "normal",
    },
    p3: {
        fontSize: {
            base: "xs",
            lg: "sm",
        },
        fontWeight: "normal",
    },
};
