import React from "react";
import DoubleShapeTop from "../../General/DoubleShapeTop";
import DoubleShapeBottom from "../../General/DoubleShapeBottom";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {Button, Col, Form, Row} from "react-bootstrap";
import * as ReactGA from "react-ga";
import {Formik} from "formik";
import {object, string} from "yup";
import {useCreateEmailSubscribeMutation} from "../../../services/emailSubscribeService";
import {Link} from "react-router-dom";
import {hasEmailSubscribeMessage} from "../../../helpers/userSettingsHelper";
import {userPublicType} from "../../../types";

const EmailSubscribeSection = ({t, i18n, user}) => {
    const schema = object({
        email: string().email().required(),
    });

    const [createEmailSubscribe, {isLoading}] = useCreateEmailSubscribeMutation()

    return (
        <section className="EmailSubscribe-section">
            <Row className={'text-center'}>
                <Col>
                    {/*todo add global text*/}
                    {/*<p>*/}
                    {/*  It is a long established fact that a reader will be distracted by the*/}
                    {/*  readable content of a page when looking at its layout.*/}
                    {/*</p>*/}
                </Col>
            </Row>

            <div className="container d-flex align-items-center flex-column justify-content-center text-center">
                <h3>{t('emailSubscribe.title')}</h3>
                {hasEmailSubscribeMessage(user) ?
                    <small>{user.profile_settings?.emailSubscribe?.message}</small> : null}

                <Row className={'w-100 pt-4'}>
                    <Col>
                        <Formik
                            onSubmit={(values, {setSubmitting, resetForm}) => {
                                ReactGA.event({
                                    category: 'Subscribe email',
                                    action: 'Report',
                                });
                                setSubmitting(true);
                                createEmailSubscribe({...values, userId: user.id})
                                setSubmitting(false);
                                // resetForm()
                            }}
                            validationSchema={schema}
                            initialValues={{
                                email: '',
                            }}>
                            {({
                                  handleSubmit,
                                  handleChange,
                                  handleReset,
                                  handleBlur,
                                  values,
                                  dirty,
                                  touched,
                                  isValid,
                                  isSubmitting,
                                  errors,
                              }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Row>
                                        <Col md={3} xs={7} className={'mx-auto'}>
                                            <Form.Group controlId="emailSubscribe.email">
                                                {/*<Form.Label>{t('emailSubscribe.email')}</Form.Label>*/}
                                                <Form.Control type="text"
                                                              name="email"
                                                              placeholder={t('emailSubscribe.placeholders.email')}
                                                              value={values.email}
                                                              onChange={handleChange}
                                                              onBlur={handleBlur}
                                                              isValid={touched.email && !errors.email}
                                                              isInvalid={!!errors.email}/>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.email}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Row className={'mt-4'}>
                                                {/*<Col className={'text-center mt-4'}>*/}
                                                <Button variant="primary" size="lg" type="submit"
                                                        disabled={isSubmitting || isLoading}>{t('emailSubscribe.submit')}</Button>

                                                {/*</Col>*/}
                                            </Row>
                                        </Col>
                                    </Row>
                                </Form>
                            )}
                        </Formik>
                    </Col>
                </Row>
                <Row>
                    <Col><small>Already subscribed? <Link to={`/unsubscribe/${user.id}`}>Unsubsribe here!</Link></small></Col>
                </Row>
            </div>
            <DoubleShapeTop/>
            <DoubleShapeBottom/>
        </section>
    );
};

EmailSubscribeSection.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
    user: userPublicType
};

export default withTranslation()(EmailSubscribeSection);
