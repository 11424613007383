import {developmentActions} from "../actions/dev/development";
import store from "../store";
import {userActions} from "../actions/user";
import {globalNavigate} from "./global-history";

export function handleResponse(response) {
    profilerLink(response)

    return response.json().then(json => {
            const data = json
            if (!response.ok) {
                //todo remove 403
                if (response.status === 401 || response.status === 403) {
                    store.dispatch(userActions.logout());
                    globalNavigate('/login')
                    window.location.reload(false);
                }
                // if (response.status === 403) {
                //     history.push('/dashboard')
                //     //todo dispatch message
                //     window.location.reload(false);
                // }

                return Promise.reject({
                    code: response.status,
                    statusText: response.statusText,
                    data: data,
                });
            }

            return data;
        }
    );
}

export function handleDelete(response) {
    if (!response.ok) {
        if (response.status === 401) {
            // auto logout if 401 response returned from api
            userActions.logout();
        }

        const error = response.statusText;
        return Promise.reject(error);
    }
}

export function handleResponseWithoutLogout(response) {
    profilerLink(response)

    return response.json().then(json => {
        const data = json
        if (!response.ok) {
            return Promise.reject({
                code: response.status,
                statusText: response.statusText,
                    data: data,
                });
            }

            return data;
        }
    );
}

function profilerLink(response) {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        if (response.headers.get('x-debug-token-link') !== null) {
            store.dispatch(developmentActions.addProfilerLink({
                url: response.url,
                status: response.status,
                profiler: response.headers.get('x-debug-token-link')
            }))
        }
    }
}
