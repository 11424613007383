import {supportMeConstants} from "../constants/action-types";

const showOffcanvas = (userId, billingInfoId, amount) => ({
    type: supportMeConstants.SHOW_OFFCANVAS,
    userId: userId,
    billingInfoId: billingInfoId,
    amount: amount,
})

const hideOffcanvas = () => ({
    type: supportMeConstants.HIDE_OFFCANVAS,
})

const changeStep = (step) => ({
    type: supportMeConstants.CHANGE_STEP,
    step: step,
})

const createdPayIntent = (billingName, email, payIntent) => ({
    type: supportMeConstants.CREATED_PAY_INTENT,
    billingName: billingName,
    email: email,
    payIntent: payIntent,
})

const clear = () => ({
    type: supportMeConstants.CLEAR,
})

export const supportMeActions = {
    showOffcanvas,
    hideOffcanvas,
    changeStep,
    createdPayIntent,
    clear,
};
