export const supportMeConstants = {
    SHOW_OFFCANVAS: 'SUPPORT_ME_SHOW_OFFCANVAS',
    HIDE_OFFCANVAS: 'SUPPORT_ME_HIDE_OFFCANVAS',
    CLEAR: 'SUPPORT_ME_CLEAR',
    CHANGE_STEP: 'SUPPORT_ME_CHANGE_STEP',
    CREATED_PAY_INTENT: 'SUPPORT_ME_CREATED_PAY_INTENT',
};

export const developmentConstants = {
    SET_PROFILER: 'DEVELOPMENT_SET_PROFILER',
}

export const linkConstants = {
    CREATE_REQUEST: 'LINK_CREATE_REQUEST',
    CREATE_SUCCESS: 'LINK_CREATE_SUCCESS',
    CREATE_FAILURE: 'LINK_CREATE_FAILURE',

    UPDATE_REQUEST: 'LINK_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'LINK_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'LINK_UPDATE_FAILURE',

    DELETE_REQUEST: 'LINK_DELETE_REQUEST',
    DELETE_SUCCESS: 'LINK_DELETE_SUCCESS',
    DELETE_FAILURE: 'LINK_DELETE_FAILURE',
}

export const profilePictureConstants = {
    CREATE_REQUEST: 'PROFILE_PICTURE_CREATE_REQUEST',
    CREATE_SUCCESS: 'PROFILE_PICTURE_CREATE_SUCCESS',
    CREATE_FAILURE: 'PROFILE_PICTURE_CREATE_FAILURE',

    DELETE_REQUEST: 'PROFILE_PICTURE_DELETE_REQUEST',
    DELETE_SUCCESS: 'PROFILE_PICTURE_DELETE_SUCCESS',
    DELETE_FAILURE: 'PROFILE_PICTURE_DELETE_FAILURE',

    LOAD_REQUEST: 'PROFILE_PICTURE_LOAD_REQUEST',
    LOAD_SUCCESS: 'PROFILE_PICTURE_LOAD_SUCCESS',
    LOAD_FAILURE: 'PROFILE_PICTURE_LOAD_FAILURE',

    CROP_REQUEST: 'PROFILE_PICTURE_CROP_REQUEST',
    CROP_SUCCESS: 'PROFILE_PICTURE_CROP_SUCCESS',
    CROP_FAILURE: 'PROFILE_PICTURE_CROP_FAILURE',

    CROP_COMPLETE_REQUEST: 'PROFILE_PICTURE_CROP_COMPLETE_REQUEST',
    CROP_COMPLETE_SUCCESS: 'PROFILE_PICTURE_CROP_COMPLETE_SUCCESS',
    CROP_COMPLETE_FAILURE: 'PROFILE_PICTURE_CROP_COMPLETE_FAILURE',

    CLEAR_SUCCESS: 'PROFILE_PICTURE_CLEAR_SUCCESS',
}

export const stripeConstants = {
    ONBOARD_REQUEST: 'STRIPE_ONBOARD_REQUEST',
    ONBOARD_SUCCESS: 'STRIPE_ONBOARD_SUCCESS',
    ONBOARD_FAILURE: 'STRIPE_ONBOARD_FAILURE',

    UNLINK_REQUEST: 'STRIPE_UNLINK_REQUEST',
    UNLINK_SUCCESS: 'STRIPE_UNLINK_SUCCESS',
    UNLINK_FAILURE: 'STRIPE_UNLINK_FAILURE',

    PAY_MODAL_SHOW: "STRIPE_PAY_MODAL_SHOW",
    PAY_MODAL_HIDE: "STRIPE_PAY_MODAL_HIDE",

    PAY_REQUEST: 'STRIPE_PAY_REQUEST',
    PAY_SUCCESS: 'STRIPE_PAY_SUCCESS',
    PAY_FAILURE: 'STRIPE_PAY_FAILURE',

    CREATE_PAY_INTENT_REQUEST: 'STRIPE_CREATE_PAY_INTENT_REQUEST',
    CREATE_PAY_INTENT_SUCCESS: 'STRIPE_CREATE_PAY_INTENT_SUCCESS',
    CREATE_PAY_INTENT_FAILURE: 'STRIPE_CREATE_PAY_INTENT_FAILURE',

    REMOVE_PAY_INTENT_REQUEST: 'STRIPE_REMOVE_PAY_INTENT_REQUEST',
    REMOVE_PAY_INTENT_SUCCESS: 'STRIPE_REMOVE_PAY_INTENT_SUCCESS',
    REMOVE_PAY_INTENT_FAILURE: 'STRIPE_REMOVE_PAY_INTENT_FAILURE',
}

export const userConstants = {
    CHANGE_PASSWORD_REQUEST: 'USERS_CHANGE_PASSWORD_REQUEST',
    CHANGE_PASSWORD_SUCCESS: 'USERS_CHANGE_PASSWORD_SUCCESS',
    CHANGE_PASSWORD_FAILURE: 'USERS_CHANGE_PASSWORD_FAILURE',

    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    UPDATE_REQUEST: 'USER_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'USER_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'USER_UPDATE_FAILURE',

    UPDATE_SETTINGS_REQUEST: 'USER_SETTINGS_UPDATE_REQUEST',
    UPDATE_SETTINGS_SUCCESS: 'USER_SETTINGS_UPDATE_SUCCESS',
    UPDATE_SETTINGS_FAILURE: 'USER_SETTINGS_UPDATE_FAILURE',

    CREATE_BILLING_INFO_REQUEST: 'USER_BILLING_INFO_CREATE_REQUEST',
    CREATE_BILLING_INFO_SUCCESS: 'USER_BILLING_INFO_CREATE_SUCCESS',
    CREATE_BILLING_INFO_FAILURE: 'USER_BILLING_INFO_CREATE_FAILURE',

    UPDATE_BILLING_INFO_REQUEST: 'USER_BILLING_INFO_UPDATE_REQUEST',
    UPDATE_BILLING_INFO_SUCCESS: 'USER_BILLING_INFO_UPDATE_SUCCESS',
    UPDATE_BILLING_INFO_FAILURE: 'USER_BILLING_INFO_UPDATE_FAILURE',

    DELETE_BILLING_INFO_REQUEST: 'USER_BILLING_INFO_DELETE_REQUEST',
    DELETE_BILLING_INFO_SUCCESS: 'USER_BILLING_INFO_DELETE_SUCCESS',
    DELETE_BILLING_INFO_FAILURE: 'USER_BILLING_INFO_DELETE_FAILURE',

    LOGOUT: 'USERS_LOGOUT',

    DELETE_REQUEST: 'USERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'USERS_DELETE_FAILURE',

    CLEAR_FORM_ERRORS_SUCCESS: 'USERS_CLEAR_FORM_ERRORS_SUCCESS',
};
