import {createSlice} from '@reduxjs/toolkit'

let token = JSON.parse(localStorage.getItem('user'));

const authenticationEmptyState = {
    token: null,
    refreshToken: null,
};

const authenticationInitialState = token ? {
    token: token.token,
    refreshToken: token.refresh_token,
} : {...authenticationEmptyState};


const authSlice = createSlice({
    name: 'auth',
    initialState: authenticationInitialState,
    reducers: {
        setTokens: (
            state,
            {payload: {token, refresh_token}}
        ) => {
            localStorage.setItem('user', JSON.stringify({token: token, refresh_token: refresh_token}))
            state.token = token
            state.refreshToken = refresh_token
        },
        refreshToken: (
            state,
            {payload: {token}}
        ) => {
            state.token = token
        },
        logoutNew: () => {
            localStorage.removeItem('user')
            return authenticationEmptyState
        },
    },
    extraReducers: (builder) => {
        //todo add this when created user slice
        // builder.addCase(authActions.logout,(state)=>initialState)

        // builder.addCase(fetchUserById.error, (state, action) => {
        //     localStorage.removeItem('user')
        //     state.loading=true;
        //     state.whoseDataIsLoading = action.payload
        // })

        //todo add this when created login slice
        // builder.addMatcher(
        //     api.endpoints.login.matchFulfilled,
        //     (state, { payload }) => {
        //         state.token = payload.token
        //         state.user = payload.user
        //     }
        // )
    },
})

export const {setTokens, refreshToken, logoutNew} = authSlice.actions

export default authSlice.reducer

export const isLoggedIn = (auth) => auth.token !== null
//todo check if expired the JWT code
// export const isExpired = (state) => state.auth.token !== null
