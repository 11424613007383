import {useDispatch} from "react-redux";
import {useResendEmailVerificationTokenMutation} from "../../../services/userService";
import {EditProfile} from "./User";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {privateProfile} from "../../../helpers/userSettingsHelper";
import React from "react";
import {withTranslation} from "react-i18next";
import {Link, useLocation} from "react-router-dom";
import {ControlButton, Controls} from "../../../styled-components/S_Setting";
import Clipboard from "react-clipboard.js";
import {successAlert} from "../../../slicers/alertsSlice";
import PropTypes from "prop-types";
import {userPrivateTypeStrict, userPublicType} from "../../../types";

const UserPrivateInfo = ({t, i18n, user}) => {
    const dispatch = useDispatch()
    const [resendVerificationEmail, {isLoading}] = useResendEmailVerificationTokenMutation()

    const location = useLocation();

    return (
        <>
            <p>{user.bio}</p>
            {/*todo maybe some popup to resend */}
            {!user.email_verified
                ? <EditProfile className={'mb-3'} onClick={() => resendVerificationEmail()} disabled={isLoading}>
                        <span className={'align-items-center '}>
                            <span>{t('user.profile.private.emailNotVerified')}<br/>{t('user.profile.private.resendVerificationEmail')}</span>
                            <FontAwesomeIcon icon="exclamation-circle"/>
                        </span>
                </EditProfile>
                : null
            }
            <Controls className={' mb-4'}>
                <Link to={`/${user.username}`}>
                    <ControlButton>
                        <FontAwesomeIcon icon="eye"/>
                        {t(privateProfile(user) ? 'user.profile.private.previewProfile' : 'user.profile.private.viewProfile')}
                    </ControlButton>
                </Link>
                {/* todo do it correctly */}
                {location.pathname === '/profile'
                    ? <Link to={`/profile/edit`}>
                        <ControlButton>
                            <FontAwesomeIcon icon="user-gear"/>
                            {t('nav.settings')}
                        </ControlButton>
                    </Link>
                    : <Link to={`/profile`}>
                        <ControlButton>
                            <FontAwesomeIcon icon="user-pen"/>
                            {t('nav.profile')}
                        </ControlButton>
                    </Link>
                }
                {privateProfile(user)
                    ? null
                    : <Clipboard component={ControlButton} onSuccess={() => dispatch(successAlert('copy.profileLink'))}
                                 data-clipboard-text={`https://${window.location.hostname}/${user.username}`}>
                        <FontAwesomeIcon icon="clone"/>
                        {t('user.bio.copyProfileLink')}
                    </Clipboard>
                }

            </Controls>
        </>
    )
}

UserPrivateInfo.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
    user: PropTypes.oneOfType([userPrivateTypeStrict, userPublicType])
};

export default withTranslation()(UserPrivateInfo);
