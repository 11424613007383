import React from "react";
import {Button, Modal} from "react-bootstrap";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";

const DeleteModal = ({t, i18n, action, show, onHide, title = 'modal.delete.title'}) => {
    return (<Modal
            {...{show, onHide}}
            size="lg"
            className={'text-center'}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {t(title)}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>{t('modal.question')}</h4>
            </Modal.Body>
            <Modal.Footer>
                <Button variant={'danger'} onClick={action}>{t('modal.yes')}</Button>
                <Button onClick={onHide}>{t('modal.no')}</Button>
            </Modal.Footer>
        </Modal>
    )
};

DeleteModal.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
    action: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    title: PropTypes.string,
};

export default withTranslation()(DeleteModal);
