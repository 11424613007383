import PropTypes from 'prop-types';
import {Role} from "../constants/roles";

const {shape, number, bool, string, oneOf} = PropTypes;

export const billingInfoType = shape({
    stripe_account: shape({
        account_id: string,
        charges_enabled: bool,
        details_submitted: bool,
    }),
    id: string,
    stripe_id: string,
});

export const socialLinkType = shape({
    facebook: string.isRequired,
    twitter: string.isRequired,
    tiktok: string.isRequired,
    instagram: string.isRequired,
});

export const linkType = shape({
    id: string.isRequired,
    name: string.isRequired,
    url: string.isRequired,
    enabled: bool.isRequired,
});

const profileSettingsType = shape({
    recentSupportMe: shape({
        enabled: PropTypes.bool,
    }),
    supportMe: shape({
        enabled: PropTypes.bool,
    }),
    privateProfile: PropTypes.bool,
    adultContent: PropTypes.bool,
    link: shape({
        enabled: PropTypes.bool,
    }),
    emailSubscribe: shape({
        enabled: PropTypes.bool,
        message: PropTypes.string,
    }),
    showEmail: shape({
        enabled: PropTypes.bool,
    }),
    hashtags: shape({
        hashtags: PropTypes.arrayOf(string)
    })
})


export const userPrivateType = shape({
    id: string,
    bio: string,
    email: string,
    username: string,
    links: PropTypes.arrayOf(linkType),
    profile_settings: profileSettingsType,
    social_link: socialLinkType,
    marketing_emails: PropTypes.bool,
    role: oneOf([Role.User, Role.Admin]),
    email_verified: PropTypes.bool,
    //todo use shape
    billing_info: billingInfoType,
    profile_picture: shape({
        id: string,
        url: string,
    })
});

export const userPrivateTypeStrict = shape({
    id: string.isRequired,
    bio: string,
    email: string.isRequired,
    username: string.isRequired,
    links: PropTypes.arrayOf(linkType),
    profile_settings: profileSettingsType,
    role: oneOf([Role.User, Role.Admin]),
    email_verified: PropTypes.bool.isRequired,
    social_link: socialLinkType,
    marketing_emails: PropTypes.bool,
    billing_info: billingInfoType,
    profile_picture: shape({
        id: string,
        url: string,
    })
});

export const userPublicType = shape({
    id: string.isRequired,
    bio: string,
    email: string, //optional
    username: string.isRequired,
    links: PropTypes.arrayOf(linkType),
    social_link: socialLinkType,
    marketing_emails: PropTypes.bool,
    profile_settings: profileSettingsType,

    role: oneOf([Role.User, Role.Admin]),
    billing_info: billingInfoType,
    profile_picture: shape({
        id: string,
        url: string,
    })
});

export const productType = shape({
    product_id: string.isRequired,
    name: string.isRequired,
    brand: string.isRequired,
    short_description: string.isRequired,
    long_description: string.isRequired,
    code: string.isRequired,
    price: number.isRequired,
    active: bool.isRequired,
    size: string.isRequired,
});

export const supportMeType = shape({
    step: number.isRequired,
    amount: string.isRequired,
    billingInfoId: string,
    userId: string,
    email: string,
    billingName: string,
    payIntent: shape({
        support_me_id: string,
        client_secret: string,
    }),
    showOffcanvas: bool.isRequired,
});


//FROM USERS LIST ON ADMIN VIEW!
interface Link {
    id: string;
    name: string;
    url: string;
    enabled: boolean;
}

interface RecentSupportMe {
    enabled: boolean;
}

interface SupportMe {
    enabled: boolean;
}

interface EmailSubscribe {
    enabled: boolean;
    message?: string;
}

interface Hashtags {
    hashtags: string[];
}

interface Affiliate {
    enabled: boolean;
    message?: string;
}

interface LinkSetting {
    enabled: boolean;
}

interface ShowEmail {
    enabled: boolean;
}

interface ProfileSettings {
    recentSupportMe?: RecentSupportMe;
    supportMe?: SupportMe;
    privateProfile?: boolean;
    adultContent?: boolean;
    link?: LinkSetting;
    emailSubscribe?: EmailSubscribe;
    showEmail?: ShowEmail;
    hashtags?: Hashtags;
    affiliate?: Affiliate;
}

interface SocialLinks {
    instagram?: string;
    facebook?: string;
    twitter?: string;
    tiktok?: string;
    linkedin?: string;
}

interface ProfilePicture {
    id: string;
    url: string;
}

interface BillingInfo {
    stripe_account: null | string;
    id: string;
    stripe_id: string;
    name: string;
    store_description?: string;
    currency: string;
    business_type: string;
    city: string;
    country: string;
    line1: string;
    line2: string;
    postal_code: string;
    state: string;
}

interface User {
    id: string;
    email: string;
    username: string;
    bio: string | null;
    links: Link[];
    roles: string[];
    profile_settings?: ProfileSettings;
    social_links?: SocialLinks;
    email_verified: boolean;
    marketing_emails: boolean;
    profile_picture?: ProfilePicture;
    billing_info?: BillingInfo;
}

export type Users = User[];
