import {useFormikContext} from "formik";
import {useEffect} from "react";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";

const ValidateFromApi = ({t, i18n, errors = {}}) => {
    const {values, setFieldError} = useFormikContext();

    useEffect(() => {
        if (errors !== undefined && Object.entries(errors).length !== 0) {
            errors.data.forEach(error => {
                if (values[error.property_path] === error.invalid_value) {
                    setTimeout(() => setFieldError(error.property_path, error.message))
                }
            })
        }
    }, [errors, values, setFieldError]);

    return null;
};

ValidateFromApi.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
    errors: PropTypes.object
};

export default withTranslation()(ValidateFromApi);
