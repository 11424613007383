import React, {useEffect} from "react";
import {Form, Row} from "react-bootstrap";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {Link, useLocation} from "react-router-dom";
import {useChangeForgotPasswordMutation, useGetForgotPasswordTokenQuery} from "../../services/userService";
import * as ReactGA from "react-ga";
import {ErrorMessage, Formik} from "formik";
import * as Yup from "yup";
import {object, string} from "yup";
import Loading from "../../components/General/Loading";
import {
    InputGroup,
    RegisterHeader,
    RegisterInputContainer,
    RegisterInputs,
    RegisterWrapper,
    RegistrationContainer
} from "../../styled-components/S_Registration";
import Header from "../../components/Header";
import {PrimaryButton} from "../../styled-components/Buttons";
import {SettingInput, SettingLabel} from "../../styled-components/S_Setting";

const ForgotPasswordChange = ({t, i18n}) => {
    const queryParams = new URLSearchParams(useLocation().search);
    const id = queryParams.get("id");
    const {
        isFetching,
        isError
    } = useGetForgotPasswordTokenQuery(id, {skip: id === null})

    const [changePassword, {isLoading, isSuccess}] = useChangeForgotPasswordMutation()
    const schema = object({
        password: string()
            .max(50)
            .min(6, 'Password must be at least 6 characters')
            .required('Password is required'),
        confirmPassword: string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Confirm Password is required'),
    });


    useEffect(() => {
        document.title = 'Forgot your password? - Quickg'
    }, []);

    if (isFetching) {
        return (
            <Loading/>
        )
    }

    if (isError || id === null) {
        return (
            <RegistrationContainer>
                <Header hasGrayBackground={true}/>
                <div>
                    <RegisterWrapper>
                        <RegisterHeader>
                            <h1>{t('user.forgotPassword.invalid.header')}</h1>
                        </RegisterHeader>
                        <h4>
                            {t('user.forgotPassword.invalid.content')}
                        </h4>
                    </RegisterWrapper>
                </div>
            </RegistrationContainer>
        )
    }

    if (isSuccess) {
        return (
            <RegistrationContainer>
                <Header hasGrayBackground={true}/>
                <div>
                    <RegisterWrapper>
                        <RegisterHeader>
                            <h4>{t('user.forgotPassword.changed.header')}</h4>
                        </RegisterHeader>
                        <p>{t('user.forgotPassword.changed.content')}</p>
                        <Link to={'/login'}><PrimaryButton className={'mt-2'}>Sign in now!</PrimaryButton></Link>
                    </RegisterWrapper>
                </div>
            </RegistrationContainer>
        )
    }

    return (
        <RegistrationContainer>
            <Header hasGrayBackground={true}/>
            <div>
                <RegisterWrapper>
                    <RegisterHeader>
                        <h4>{t('user.forgotPassword.header')}</h4>
                    </RegisterHeader>
                    <Formik
                        onSubmit={(values, {setSubmitting, resetForm}) => {
                            setSubmitting(true);
                            ReactGA.event({
                                category: 'Forgot Password',
                                action: 'Forgot Password Request'
                            });
                            changePassword(values)
                            setSubmitting(false);
                        }}
                        enableReinitialize={true}
                        validationSchema={schema}
                        initialValues={{
                            token: id,
                            password: '',
                            confirmPassword: '',
                        }}>
                        {({
                              handleSubmit,
                              handleChange,
                              handleReset,
                              handleBlur,
                              values,
                              dirty,
                              touched,
                              isValid,
                              isSubmitting,
                              errors,
                              setFieldValue,
                              setFieldTouched,
                          }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <RegisterInputs>
                                    <InputGroup>
                                        <RegisterInputContainer>
                                            <Form.Group controlId="forgotPassword.password">
                                                <SettingLabel>New password:</SettingLabel>
                                                <SettingInput type="password"
                                                              name="password"
                                                    // placeholder={'User123'}
                                                              value={values.password}
                                                              onChange={handleChange}
                                                              onBlur={handleBlur}
                                                              isValid={touched.password && !errors.password}
                                                              isInvalid={!!errors.password}
                                                />
                                                <ErrorMessage name="password" component="div"
                                                              className="invalid-feedback"/>
                                            </Form.Group>
                                        </RegisterInputContainer>
                                    </InputGroup>
                                </RegisterInputs>
                                <RegisterInputs>
                                    <InputGroup>
                                        <RegisterInputContainer>
                                            <Form.Group controlId="forgotPassword.confirmPassword">
                                                <SettingLabel>Confirm password:</SettingLabel>
                                                <SettingInput type="password"
                                                              name="confirmPassword"
                                                    // placeholder={'User123'}
                                                              value={values.confirmPassword}
                                                              onChange={handleChange}
                                                              onBlur={handleBlur}
                                                              isValid={touched.confirmPassword && !errors.confirmPassword}
                                                              isInvalid={!!errors.confirmPassword}
                                                />
                                                <ErrorMessage name="confirmPassword" component="div"
                                                              className="invalid-feedback"/>
                                            </Form.Group>
                                        </RegisterInputContainer>
                                    </InputGroup>
                                </RegisterInputs>
                                <Row className='d-flex justify-content-between mt-5'>
                                    <PrimaryButton type="submit"
                                                   disabled={isSubmitting || isLoading}>{(isSubmitting || isLoading) &&
                                        <span className="spinner-border spinner-border-sm me-1"/>}
                                        {t('nav.signIn')}
                                    </PrimaryButton>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </RegisterWrapper>
            </div>
        </RegistrationContainer>
    )
}

ForgotPasswordChange.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
};

export default withTranslation()(ForgotPasswordChange);
