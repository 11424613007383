import {Box, Flex, Heading, Stack, Text, useColorModeValue} from "@chakra-ui/react";
import React, {ReactElement} from "react";

interface IntroItemProps {
    heading: string;
    description: string;
    icon: ReactElement;
    href: string;
}

const IntroItem = ({heading, description, icon, href}: IntroItemProps) => {
    return (
        <Box
            maxW={{base: 'full', md: '290px'}}
            w={'full'}
            borderWidth="1px"
            borderRadius="2xl"
            overflow="hidden"
            p={5}>
            <Stack align={'center'} spacing={2}>
                <Flex
                    w={16}
                    h={16}
                    align={'center'}
                    justify={'center'}
                    color={'white'}
                    rounded={'full'}
                    bg={useColorModeValue('#FFF5F9', 'gray.700')}>
                    {icon}
                </Flex>
                <Box mt={2} alignContent={'center'}>
                    <Heading textAlign={'center'} size="lg">{heading}</Heading>
                    <Text mt={1} fontSize={'md'} textAlign={'center'}>
                        {description}
                    </Text>
                </Box>
                {/*<Button variant={'link'} colorScheme={'blue'} size={'sm'}>*/}
                {/*    Learn more*/}
                {/*</Button>*/}
            </Stack>
        </Box>
    );
};

export default IntroItem;
