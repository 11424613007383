import {Box, Button, Flex, Image, Stack, Text,} from "@chakra-ui/react";
import {bounceAnimation} from "../../../../helpers/animation";
import React from "react";
import {useNavigate} from "react-router-dom";

const Content = () => {
  const navigate = useNavigate();

  return (
      <Stack
          direction={{base: "column", lg: "row"}}
          pb={{base: 14, lg: 0}}
          justify="center"
          align="center"
          spacing={{base: 4, md: 8, lg: 12}}
          px="4"
          // todo BOOTSTRAP
          pt="20"
          // mt="20"
          //
      >
        <Image
            height={{base: "240px", lg: "350px", xl: "400px"}}
            src="/icons/hero_image.svg"
        />
        <Stack
            position="relative"
            w={{md: "70%", lg: "50%"}}
            spacing={{base: 2, md: 4, lg: 6}}
        >
          <Box>
            <Image
                display={{base: "none", lg: "flex"}}
                src="/icons/logo_white.svg"
                h={10}
                w="auto"
            />
          </Box>
          <Text
              textAlign={{base: "center", lg: "left"}}
              textStyle="h2"
              color="white"
          >
            Create a bio site and monetize your online presence.
          </Text>
          <Text
              textAlign={{base: "center", lg: "left"}}
              textStyle={{
                "2xl": "h5",
                base: "h6",
              }}
              color="white"
          >
            Accept payments, track analytics, share affiliate links and showcase your skills easily.
            All-in-one social media solution!
          </Text>
          <Text
              textAlign={{base: "center", lg: "left"}}
              textStyle={{
                "2xl": "p1",
                base: "p2",
              }}
              color="white"
          >
            Ready to jumpstart your online potential?
          </Text>

          <Flex justify={{base: "center", lg: "left"}}>
            <Button
                h="55px"
                gap="4"
                mt={{
                  base: 4,
                  lg: 0,
                }}
                fontSize="md"
                fontWeight="normal"
                color="text.300"
                minW={{
                  base: "196px",
                }}
                variant="bright"
                onClick={() => navigate('/register')}
            >
              Let’s Start Now
            </Button>
          </Flex>

          <Box
              display={{base: "none", lg: "block"}}
              position="absolute"
              top={-75}
              right={-65}
              animation={`${bounceAnimation} 5s infinite`}
          >
            <Image w="320px" src="/icons/hero_bg.svg"/>
          </Box>
        </Stack>
      </Stack>
  );
};

export default Content;
