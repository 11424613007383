import {createSlice} from '@reduxjs/toolkit'
import {v4 as uuidv4} from "uuid";
import {contactApi} from "../services/contactService";
import {emailSubscribeApi} from "../services/emailSubscribeService";
import {userApi} from "../services/userService";

const alertsInitialState = {
    alerts: [],
};

// const alertInitialState = {
//     isVisible: true,
// };

// const alertSlice = createSlice({
//     name: 'alert',
//     initialState: alertInitialState,
//     reducers: {
//         success: (state, action) => {
//             console.log(state)
//             console.log(action)
//             return {
//                 ...state,
//                 id: uuidv4(),
//                 date: Date.now(),
//                 title: action.title,
//                 icon: action.icon,
//                 type: action.type,
//                 message: action.message,
//             }
//         },
//         hide: (state, action) => {
//             state.isVisible = false
//         }
//     },
// })
//
// const alertActions = alertSlice.actions

const alertsSlice = createSlice({
    name: 'alerts',
    initialState: alertsInitialState,
    reducers: {
        hideAlert: (state, action) => {
            state.alerts = state.alerts.map(alertItem =>
                alertItem.id === action.payload
                    ? {...alertItem, isVisible: false}
                    : alertItem
            )
        },
        hideAllAlerts: (state, action) => {
            state.alerts = state.alerts.map(alertItem =>
                alertItem.id === action.payload
                    ? {...alertItem, isVisible: false}
                    : alertItem
            )
        },
        successAlert: (state, action) => {
            state.alerts.push(success(action.payload))
        },
        errorAlert: (state, action) => {
            state.alerts.push(error(action.payload))
        },
        infoAlert: (state, action) => {
            state.alerts.push(info(action.payload))
        },
        warningAlert: (state, action) => {
            state.alerts.push(warning(action.payload))
        },
        // createAlert: (state, action) => {
        //     state.alerts.push({
        //         message: action.payload.message,
        //         type: action.payload.type
        //     });
        // }
    },
    extraReducers: (builder) => {
        //action contains payload and all the good stuff
        builder.addMatcher(contactApi.endpoints.createContact.matchFulfilled,
            (state, action) => {
                state.alerts.push(success('alert.contact.success'))
            }
        )
        builder.addMatcher(emailSubscribeApi.endpoints.createEmailSubscribe.matchFulfilled,
            (state, action) => {
                state.alerts.push(success('alert.emailSubscribe.success'))
            }
        )
        builder.addMatcher(userApi.endpoints.changeSocialLinks.matchFulfilled,
            (state, action) => {
                state.alerts.push(success('alert.links.updated'))
            }
        )

        builder.addMatcher(userApi.endpoints.changeForgotPassword.matchFulfilled,
            (state, action) => {
                state.alerts.push(success('alert.forgotPasswordChanged.success'))
            }
        )
        builder.addMatcher(userApi.endpoints.changeForgotPassword.matchRejected,
            (state, action) => {
                state.alerts.push(warning('alert.forgotPasswordChanged.warning'))
            }
        )

        builder.addMatcher(userApi.endpoints.forgotPasswordEmail.matchFulfilled,
            (state, action) => {
                state.alerts.push(success('alert.forgotPasswordEmail.success'))
            }
        )
        builder.addMatcher(userApi.endpoints.forgotPasswordEmail.matchRejected,
            (state, action) => {
                state.alerts.push(warning('alert.forgotPasswordEmail.warning'))
            }
        )

        builder.addMatcher(userApi.endpoints.resendEmailVerificationToken.matchFulfilled,
            (state, action) => {
                state.alerts.push(success('alert.emailVerificationResend.success'))
            }
        )
        builder.addMatcher(userApi.endpoints.resendEmailVerificationToken.matchRejected,
            (state, action) => {
                state.alerts.push(warning('alert.emailVerificationResend.warning'))
            }
        )
    },
})

export const {hideAlert, hideAllAlerts, successAlert, errorAlert, infoAlert, warningAlert} = alertsSlice.actions

export default alertsSlice.reducer


const AlertType = {
    Success: 'success',
    Error: 'error',
    Info: 'info',
    Warning: 'warning'
}

// convenience methods
const success = (message, title = 'company.quickg', icon = 'check') => {
    return alert({message: message, title: title, icon: icon, type: AlertType.Success,});
}

const error = (message = 'alert.error', title = 'company.quickg', icon = 'bug') => {
    return alert({message: message, title: title, icon: icon, type: AlertType.Error,});
}

const info = (message, title = 'company.quickg', icon = 'info') => {
    return alert({message: message, title: title, icon: icon, type: AlertType.Info,});
}

const warning = (message, title = 'company.quickg', icon = 'exclamation') => {
    return alert({message: message, title: title, icon: icon, type: AlertType.Warning,});
}

// core alert method
const alert = (alert) => {
    return {
        id: uuidv4(),
        date: Date.now(),
        title: alert.title,
        icon: alert.icon,
        type: alert.type,
        message: alert.message,
        isVisible: true,
    }
};

