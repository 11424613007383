import {ComponentStyleConfig, defineStyle} from "@chakra-ui/react";

const Checkbox: ComponentStyleConfig = {
    baseStyle: {
        control: defineStyle({
            _checked: {
                bg: "brand.dark",
                borderColor: "brand.dark",
                color: "white",
                _hover: {
                    bg: "brand.dark",
                    borderColor: "brand.dark",
                    opacity: 0.8,
                },
            },
        }),
        icon: defineStyle({
            color: "white",
        }),

        label: defineStyle({
            marginLeft: 2,
            _hover: {
                color: "brand.pink",
            },
        }),
    },

    defaultProps: {
        size: "md",
    },
};

export default Checkbox;
