import {developmentConstants} from "../../constants/action-types";

const addProfilerLink = (link) => {
    console.log(link)
    return (dispatch) => {
        dispatch(success(link));
    };

    function success(link) {
        return {type: developmentConstants.SET_PROFILER, link}
    }
}
export const developmentActions = {
    addProfilerLink,
};
