import store from "../store";
import {isRejectedWithValue} from "@reduxjs/toolkit";
import {errorAlert} from "../slicers/alertsSlice";

export const rtkQueryNotifications = (api) => (next) => (action) => {

    if (!['api/executeQuery/rejected', 'api/executeMutation/rejected'].includes(action.type)) {
        return next(action)
    }

    if (isRejectedWithValue(action)) {
        //todo add array of actions if they should be excluded from 5XX errors
        if (![401, 404].includes(action.payload.originalStatus)) {
            store.dispatch(errorAlert());
        }
    }

    return next(action)
}
