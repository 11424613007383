import {Box, Button, Container, HStack, Icon, Image, Wrap,} from "@chakra-ui/react";
import Heading from "../../../../components/Heading";
import BenefitItem from "./BenefitItem";
import {TbMedal, TbTargetArrow} from "react-icons/tb";
import {HiOutlineStar} from "react-icons/hi";
import {FaCrown} from "react-icons/fa";
import React from "react";

const cta1 = (
    <Wrap
        justify={{
            base: "center",
            sm: "flex-start",
        }}
    >
        <Button
            h="38px"
            fontSize="14px"
            fontWeight="normal"
            padding="12px 10px"
            variant="grad1"
            size={{
                base: "sm",
                lg: "md",
            }}
            leftIcon={<Icon as={TbTargetArrow}/>}
        >
            Product hunter
        </Button>
        <Button
            h="40px"
            fontSize="14px"
            fontWeight="normal"
            padding="12px 10px"
            size={{
                base: "sm",
                lg: "md",
            }}
            variant="grad2"
            leftIcon={<Icon as={HiOutlineStar}/>}
        >
            Standard
        </Button>
        <Button
            h="40px"
            fontSize="14px"
            fontWeight="normal"
            padding="12px 10px"
            size={{
                base: "sm",
                lg: "md",
            }}
            variant="grad3"
            leftIcon={<Icon as={TbMedal}/>}
        >
            Professional
        </Button>
        <Button
            h="40px"
            fontSize="14px"
            fontWeight="normal"
            padding="12px 10px"
            size={{
                base: "sm",
                lg: "md",
            }}
            variant="grad4"
            leftIcon={<Icon as={FaCrown}/>}
        >
            Premium
        </Button>
    </Wrap>
);

const cta2 = (
    <HStack spacing={8}>
        <Box>
            <Image src="/icons/call.svg" height="30px" width="30px"/>
        </Box>
        <Box>
            <Image src="/icons/headset2.svg" height="36px" width="36px"/>
        </Box>
    </HStack>
);

const cta3 = (
    <HStack spacing={8}>
        <Box>
            <Image src="/icons/aggrement.svg" height="30px" width="30px"/>
        </Box>
        <Box>
            <Image src="/icons/link2.svg" height="30px" width="30px"/>
        </Box>
    </HStack>
);

const cta4 = (
    <HStack spacing={8}>
        <Box>
            <Image src="/icons/manager.svg" height="30px" width="30px"/>
        </Box>
        <Box>
            <Image src="/icons/chart.svg" height="30px" width="30px"/>
        </Box>
    </HStack>
);

const cta5 = (
    <HStack spacing={8}>
        <Box>
            <Image src="/icons/paperPlane.svg" height="30px" width="30px"/>
        </Box>
        <Box>
            <Image src="/icons/email.svg" height="30px" width="30px"/>
        </Box>
    </HStack>
);

const benifitArray = [
    {
        img: "/images/desktop.png",
        headingDark: "Page",
        headingColored: "Personal",
        desc: "Craft a digital handshake that leaves a lasting impression. With Quickg, your personal page becomes a narrative of your unique journey.",
        ctaSection: cta1,
        reverse: false,
    },
    {
        img: "/images/supportMe.png",
        headingDark: "Me",
        headingColored: "Support",
        desc: "This feature provides a simple and direct way for your followers to contribute to your journey, fueling your passion and creative endeavors.",
        ctaSection: cta2,
        reverse: true,
    },

    {
        img: "/images/affiliate.png",
        headingDark: "Links",
        headingColored: "Affiliate",
        desc: "Unlock a new revenue stream with Quickg's Affiliate Links/Coupons feature. Seamlessly integrate your affiliate links, turning your digital presence into a profitable venture.",
        ctaSection: cta3,
        reverse: false,
    },

    {
        img: "/images/metrics.png",
        headingDark: "(Dashboard)",
        headingColored: "Metrics",
        desc: "Unlock the power of data with Quickg's Metrics Dashboard. Track engagement, measure performance, and understand your audience better with our Metrics Dashboard.",
        ctaSection: cta4,
        reverse: true,
    },
    {
        img: "/images/emailSending.png",
        headingDark: "Subcriptions",
        headingColored: "Email",
        desc: "Boost your audience engagement with Quickg's Email Subscription feature. Encourage your visitors to subscribe and keep them engaged with regular updates and personalized content. It's your voice, amplified.",
        ctaSection: cta5,
        reverse: false,
    },
];

const BenefitSection = () => {
    return (
        <Box py="120px">
            <Container maxW="1080px">
                <Heading
                    title="Benefits of"
                    titleColored="Quickg"
                    description="Struggling with your online presence? Accelerate your growth effortlessly with Quickg! We simplify, enhance, and empower your online journey in one customizable digital hub."
                />
                <Box mt="20">
                    {benifitArray.map((item, i) => (
                        <BenefitItem
                            key={i}
                            img={item.img}
                            headingDark={item.headingDark}
                            headingColored={item.headingColored}
                            desc={item.desc}
                            ctaSection={item.ctaSection}
                            reverse={item.reverse}
                        />
                    ))}
                </Box>
            </Container>
        </Box>
    );
};

export default BenefitSection;
