import {profilePictureConstants} from "../constants/action-types";
import {profilePictureService} from "../services/profilePictureService";
import {errorAlert, successAlert} from "../slicers/alertsSlice";
import {baseApi} from "../services/apiService";


const create = (id, file) => {
    return (dispatch, getState) => {
        if (getState().profilePicture.isSending === true) {
            return;
        }
        dispatch(request(id));

        profilePictureService.create(id, file)
            .then(
                () => {
                    dispatch(success());
                    dispatch(baseApi.endpoints.getCurrentUser.initiate(undefined, {forceRefetch: true}))
                    dispatch(successAlert('Profile picture uploaded'));
                    dispatch(profilePictureActions.clear())
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(errorAlert(error.toString()));
                }
            );
    };

    function request() {
        return {type: profilePictureConstants.CREATE_REQUEST}
    }

    function success() {
        return {type: profilePictureConstants.CREATE_SUCCESS}
    }

    function failure(error) {
        return {type: profilePictureConstants.CREATE_FAILURE, error}
    }
}

const crop = (crop) => {
    return (dispatch, getState) => {
        if (getState().profilePicture.isCropping === true) {
            return;
        }
        dispatch(request(crop));
        dispatch(success(crop));
    };

    function request(crop) {
        return {type: profilePictureConstants.CROP_REQUEST, crop}
    }

    function success(crop) {
        return {type: profilePictureConstants.CROP_SUCCESS, crop}
    }
}

const cropComplete = (crop) => {
    return (dispatch, getState) => {
        if (getState().profilePicture.isCropped === true) {
            return;
        }
        dispatch(request(crop));
        dispatch(success(crop));
    };

    function request(crop) {
        return {type: profilePictureConstants.CROP_COMPLETE_REQUEST, crop}
    }

    function success(crop) {
        return {type: profilePictureConstants.CROP_COMPLETE_SUCCESS, crop}
    }
}

const clear = () => {
    return dispatch => {
        dispatch(success());
    };

    function success(crop) {
        return {type: profilePictureConstants.CLEAR_SUCCESS, crop}
    }
}

const load = (file) => {
    return (dispatch, getState) => {
        if (getState().profilePicture.isLoading === true) {
            return;
        }
        dispatch(request(file));
        let reader = new FileReader();

        reader.onloadend = () => {
            dispatch(success(file, reader.result));
        };
        reader.onerror = () => {
            dispatch(failure(reader.error.toString()));
            dispatch(errorAlert(reader.error.toString()));
        }
        reader.readAsDataURL(file);
    };

    function request(file) {
        return {type: profilePictureConstants.LOAD_REQUEST, file}
    }

    function success(id, url) {
        return {type: profilePictureConstants.LOAD_SUCCESS, file, url}
    }

    function failure(error) {
        return {type: profilePictureConstants.LOAD_FAILURE, error}
    }
}

export const profilePictureActions = {
    crop,
    cropComplete,
    load,
    create,
    clear,
};
