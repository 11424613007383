import styled from "styled-components";
import {centerMe, colors} from "./styledUtils";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PropTypes, {element, string} from "prop-types";

export function AddButton({
                            children,
                            icon = <FontAwesomeIcon icon={'plus'}/>,
                            action
                          }) {
  return (
      <AddButtonContainer onClick={action} type={'button'}>
          {icon}
          {children}
      </AddButtonContainer>
  );
}

AddButton.propTypes = {
    children: PropTypes.oneOfType([string, element]).isRequired,
    icon: PropTypes.element,
    action: PropTypes.func.isRequired,
};

export const AddButtonContainer = styled.button`
  border: 2px dashed #e8bccc;
  text-transform: capitalize;
  color: ${colors.primary};
  border-radius: 5px;
  background: #fff;
  font-weight: 700;
  padding: 10px 2.7rem;
  font-size: 1rem;
  ${centerMe}
  column-gap: 10px;
  transition: all 0.2s ease;
  :hover {
    background: #fafafa;
  }
  @media (max-width: 802px) {
    width: 100%;
  }
`;

export const PrimaryButton = styled.button`
  outline: none;
  width: 100%;
  border: ${(p) => (p.outline ? `1px solid ${colors.primary}` : "none")};
  color: ${(p) => (p.outline ? colors.primary : "#fff")};
  background: ${(p) =>
    p.outline
        ? "#fff"
        : "linear-gradient(to right, #BC3665 0%, #6446ae 100%);"};
  padding: ${(p) => (p.padding ? "15px" : "10px")};
  border-radius: 50px;
  cursor: pointer;
  font-weight: 700;
  font-size: 1rem;
  @media screen and (max-width: 536px) {
    font-size: 0.9rem !important;
  }
`;
