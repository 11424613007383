import React from "react";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {Button, Col, Form, Row,} from "react-bootstrap";
import * as ReactGA from "react-ga";
import {Formik} from "formik";
import {bool, object, string} from "yup";
import {useDispatch, useSelector} from "react-redux";
import {useCreatePayIntentMutation} from "../../../../services/stripeService";
import {supportMeActions} from "../../../../actions/supportMe";
import {supportMeType} from "../../../../types";
import {useGetCurrentUserQuery} from "../../../../services/userService";
import {isLoggedIn} from "../../../../slicers/authSlice";
import Loading from "../../../General/Loading";

const PayIntentForm = ({t, i18n, supportMe}) => {
    const dispatch = useDispatch()

    const schema = object({
        billingName: string().min(2).max(50).required(),
        email: string().email().min(2).max(50).required(),
        message: string().nullable(),
        anonymously: bool().required(),
        username: string().when('anonymously', {
            is: false,
            then: string()
                .required(),
            otherwise: string(),
        }),
    });

    const [createPayIntent] = useCreatePayIntentMutation({
        fixedCacheKey: `payIntend-amount-billingInfoId`,
    })

    const auth = useSelector(state => state.auth);

    let {
        data,
        isFetching
    } = useGetCurrentUserQuery(undefined, {skip: !isLoggedIn(auth)})

    if (isFetching) {
        return (
            <Loading/>
        )
    }

    data = data !== undefined ? data : {}

    const billingInfo = data !== undefined && data.billing_info !== undefined && data.billing_info !== null ? data.billing_info : {}

    return (
        <Row className={'mb-4'}>
            <Col>
                <Formik
                    validationSchema={schema}
                    validateOnMount={false}
                    onSubmit={(values, {setSubmitting, resetForm}) => {
                        ReactGA.event({
                            category: 'UserSupport',
                            action: 'Support user - amount'
                        });
                        setSubmitting(true);

                        createPayIntent({id: supportMe.billingInfoId, ...values})
                            .unwrap()
                            .then((payload) => {
                                dispatch(supportMeActions.createdPayIntent(
                                    values.billingName,
                                    values.email,
                                    payload
                                ))
                            })
                            .catch(() => null)

                        setSubmitting(false);
                    }}
                    enableReinitialize={true}
                    initialValues={{
                        amount: supportMe.amount,
                        billingName: billingInfo.name ?? '',
                        email: data.email ?? '',
                        anonymously: false,
                        username: data.username ?? '',
                        message: '',
                    }}>
                    {({
                          handleSubmit,
                          handleChange,
                          handleReset,
                          handleBlur,
                          values,
                          dirty,
                          touched,
                          isValid,
                          isSubmitting,
                          errors,
                          setFieldValue,
                          setFieldTouched,
                      }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Row className={'text-center'}>
                                <Col>
                                    <Form.Label htmlFor={'anonymously'}>
                                        {t('supportMe.anonymously')}
                                    </Form.Label>
                                    <Form.Switch
                                        className={'align-middle'}
                                        size={'lg'}
                                        type="switch"
                                        id='anonymously'
                                        // label={t('supportMe.anonymously')}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.anonymously}
                                        isValid={touched.anonymously && !errors.anonymously}
                                        isInvalid={!!errors.anonymously}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} lg={{span: 5, offset: 1}}>
                                    <Form.Label htmlFor={'billingName'}>
                                        {t('supportMe.billingName')}
                                    </Form.Label>
                                    <Form.Control
                                        className="mb-2 me-sm-2"
                                        id={'billingName'}
                                        name={`billingName`}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.billingName}
                                        placeholder={t('supportMe.billingName')}
                                        isValid={touched.billingName && !errors.billingName}
                                        isInvalid={!!errors.billingName}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.billingName}
                                    </Form.Control.Feedback>
                                    <Form.Label htmlFor={'email'}>
                                        {t('user.edit.email')}
                                    </Form.Label>
                                    <Form.Control
                                        className="mb-2 me-sm-2"
                                        id={'email'}
                                        name={`email`}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.email}
                                        placeholder={t('user.edit.email')}
                                        isValid={touched.email && !errors.email}
                                        isInvalid={!!errors.email}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.email}
                                    </Form.Control.Feedback>
                                </Col>
                                <Col md={6} lg={5}>
                                    <Form.Label htmlFor={'message'}>
                                        {t('supportMe.messageFor')}
                                    </Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        className="mb-2 me-sm-2"
                                        id={'message'}
                                        name={`message`}
                                        rows={3}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.message}
                                        placeholder={t('supportMe.messageFor')}
                                        isValid={touched.message && !errors.message}
                                        isInvalid={!!errors.message}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.message}
                                    </Form.Control.Feedback>
                                    {values.anonymously
                                        ? null
                                        : <>
                                            <Form.Label htmlFor={'username'}>
                                                {t('supportMe.username')}
                                            </Form.Label>
                                            <Form.Control
                                                className="mb-2 me-sm-2"
                                                id={'username'}
                                                name={`username`}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.username}
                                                placeholder="@myIg"
                                                isValid={touched.username && !errors.username}
                                                isInvalid={!!errors.username}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.username}
                                            </Form.Control.Feedback>
                                        </>
                                    }
                                </Col>
                            </Row>
                            <Row className={'text-center mt-4'}>
                                <Col>
                                    <Button
                                        type={"submit"}
                                        disabled={isSubmitting}
                                    >{t('supportMe.DebitCreditCard')}</Button>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </Col>
        </Row>
    )
};

PayIntentForm.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
    supportMe: supportMeType,
};

export default withTranslation()(PayIntentForm);
