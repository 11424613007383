import React from "react";
import SocialIconsImg from "../styles/images/social-icons-hero.svg"
import ShapeDividerImg from "../styles/images/hero-shape-divider.png"
import ShapeDividerGrayImg from "../styles/images/hero-shape-divider-gray.png"
import PropTypes from "prop-types";
import {Col, Row} from "react-bootstrap";

const Header = ({header = '', hasGrayBackground = false}) => {

    return (
        <section className="adminHero">
            <div className="container d-flex">
                <div className="social-icons-bg">
                    <img src={SocialIconsImg} alt=""/>
                </div>
                <div className="social-icons-bg social-icons-bg2">
                    <img src={SocialIconsImg} alt=""/>
                </div>
                <Row className='text-center  d-flex align-items-center m-auto'>
                    <Col>
                        <h1 className="display-4 text-white">{header}</h1>
                    </Col>
                </Row>
            </div>
            <div className="hero-shape-divider-top">
                <img src={hasGrayBackground ? ShapeDividerGrayImg : ShapeDividerImg} alt="hero-shape-divider"/>
            </div>
        </section>
    );
};

Header.propTypes = {
    header: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    hasGrayBackground: PropTypes.bool,
};

export default Header;
