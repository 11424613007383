import React, {useEffect} from "react";
import {Form, Row} from "react-bootstrap";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {useForgotPasswordEmailMutation,} from "../../services/userService";
import {object, string} from "yup";
import {ErrorMessage, Formik} from "formik";
import * as ReactGA from "react-ga";
import Header from "../../components/Header";
import {
    InputGroup,
    RegisterHeader,
    RegisterInputContainer,
    RegisterInputs,
    RegisterWrapper,
    RegistrationContainer
} from "../../styled-components/S_Registration";
import {PrimaryButton} from "../../styled-components/Buttons";
import {SettingInput, SettingLabel} from "../../styled-components/S_Setting";

const ForgotPasswordRequest = ({t, i18n}) => {
    const [forgotPasswordEmail, {isLoading, isSuccess}] = useForgotPasswordEmailMutation()

    useEffect(() => {
        document.title = 'Forgot your password? - Quickg'
    });

    const schema = object({
        email: string().min(2).max(50).email('Email is invalid').required('Email is required'),
    });

    if (isSuccess) {
        return (
            <RegistrationContainer>
                <Header hasGrayBackground={true}/>
                <div>
                    <RegisterWrapper>
                        <RegisterHeader>
                            <h1>{t('user.forgotPassword.header')}</h1>
                        </RegisterHeader>
                        <Row className='d-flex justify-content-between mt-5'>
                            <p>{t('user.forgotPassword.success')}</p>
                        </Row>
                    </RegisterWrapper>
                </div>
            </RegistrationContainer>
        )
    }

    return (
        <RegistrationContainer>
            <Header hasGrayBackground={true}/>
            <div>
                <RegisterWrapper>
                    <RegisterHeader>
                        <h1>{t('user.forgotPassword.header')}</h1>
                    </RegisterHeader>
                    <Formik
                        onSubmit={(values, {setSubmitting, resetForm}) => {
                            setSubmitting(true);
                            ReactGA.event({
                                category: 'Forgot Password',
                                action: 'Forgot Password Request'
                            });
                            forgotPasswordEmail(values)
                            setSubmitting(false);
                        }}
                        enableReinitialize={true}
                        validationSchema={schema}
                        initialValues={{
                            email: '',
                        }}>
                        {({
                              handleSubmit,
                              handleChange,
                              handleReset,
                              handleBlur,
                              values,
                              dirty,
                              touched,
                              isValid,
                              isSubmitting,
                              errors,
                              setFieldValue,
                              setFieldTouched,
                          }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <RegisterInputs>
                                    <InputGroup>
                                        <RegisterInputContainer>
                                            <Form.Group controlId="registerForm.email">
                                                <SettingLabel>Email:</SettingLabel>
                                                <SettingInput type="text"
                                                              name="email"
                                                    // placeholder={'User123'}
                                                              value={values.email}
                                                              onChange={handleChange}
                                                              onBlur={handleBlur}
                                                              isValid={touched.email && !errors.email}
                                                              isInvalid={!!errors.email}
                                                />
                                                <ErrorMessage name="email" component="div"
                                                              className="invalid-feedback"/>
                                            </Form.Group>
                                        </RegisterInputContainer>
                                    </InputGroup>
                                </RegisterInputs>
                                <Row className='d-flex justify-content-between mt-5'>
                                    <PrimaryButton type="submit"
                                                   disabled={isSubmitting || isLoading}>
                                        {(isSubmitting || isLoading) &&
                                            <span className="spinner-border spinner-border-sm me-1"/>}
                                        {t('user.forgotPassword.btn')}
                                    </PrimaryButton>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </RegisterWrapper>
            </div>
        </RegistrationContainer>
    )
}

ForgotPasswordRequest.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
};

export default withTranslation()(ForgotPasswordRequest);
