import {css} from "styled-components";

export const centerMe = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const spaceBetween = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const flexStart = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const colors = {
  primary: "#bc3665",
  gray: "#f4f4f4",
  textGray: "#a0a0a0",
};
