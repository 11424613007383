import React from 'react';
import {Dropdown, Nav} from "react-bootstrap";
import UserPhotoPlaceholderImg from '../../styles/images/profile-user.svg'
import PropTypes from "prop-types";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {IMAGE_URL} from "../../constants/API";
import {userActions} from "../../actions/user";
import {useDispatch} from "react-redux";
import {userPrivateType} from "../../types";

const Profile = ({t, i18n, drop, handleShow = () => null, hasUser, user}) => {
    const {pathname} = useLocation();
    const dispatch = useDispatch()
    const navigate = useNavigate();

    return (
        <>
            {hasUser
                ? <Dropdown drop={drop} className="profile-btn">
                    <Dropdown.Toggle variant="link" id="dropdown-basic">
                        <span>Hi, {user.username}</span>
                        <div className={'dp'}>
                            <img src={
                                user.profile_picture?.url
                                    ? IMAGE_URL.concat(user.profile_picture.url)
                                    : UserPhotoPlaceholderImg
                            } alt="user icon"/>
                        </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {/*<Dropdown.Item as={Link} to="/profile">{t('nav.profile')}</Dropdown.Item>*/}
                        <Dropdown.Item as={Link} to="/profile/edit">{t('nav.settings')}</Dropdown.Item>
                        <Dropdown.Item onClick={() => {
                            navigate('/')
                            dispatch(userActions.logout())
                            handleShow()
                        }}>{t('nav.logout')}</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                : <Nav className="profile_btn">
                    <Link onClick={handleShow} className={`nav-link ${pathname === '/login' ? 'active' : ''}`}
                          to="/login">{t('nav.signIn')}</Link>
                    <Link onClick={handleShow} className={`nav-link ${pathname === '/register' ? 'active' : ''}`}
                          to="/register">{t('nav.signUp')}</Link>
                </Nav>
            }
        </>
    )
}

Profile.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
    drop: PropTypes.string.isRequired,
    handleShow: PropTypes.func,
    hasUser: PropTypes.bool.isRequired,
    user: userPrivateType
};

export default withTranslation()(Profile);
