import React from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import NotFound from "./NotFound";
import GDPR from "./GDPR";
import Login from "./Login";
import {useDispatch} from "react-redux";
import UserPublicProfile from "./User/UserPublicProfile";
import {withTranslation} from "react-i18next";
import Privacy from "./Privacy";
import Terms from "./Terms";
import PropTypes from "prop-types";
import Profiler from "../components/dev/Profiler";
import Alerts from "../components/Alerts";
import VerifyEmail from "./User/VerifyEmail";
import ForgotPasswordRequest from "./User/ForgotPasswordRequest";
import ForgotPasswordChange from "./User/ForgotPasswordChange";
import Header from "../components/NavBar/Header";
import Register from "./Register";
import UserPrivateProfile from "./User/UserPrivateProfile";
import Settings from "./Settings";
import EmailSubscribeList from "./EmailSubscribe/EmailSubscribeList";
import UnsubscribeAll from "./EmailSubscribe/UnsubscribeAll";
import UnsubscribeProfile from "./EmailSubscribe/UnsubscribeProfile";
import SupportersList from "./User/SupportersList";
import Discover from "./User/Discover";
import Blogs from "./Blog/Blogs";
import ViewBlog from "./Blog/ViewBlog";
import Seo from "../components/Seo";
import Footer from "../components/Footer";
import {GlobalHistory} from "../helpers/global-history";
import Home from "../pages/Home";
import {PrivateOutlet} from "../helpers/PrivateOutlet";

const Admin = React.lazy(() => import('./Admin'));

const App = ({t, i18n}) => {
    const dispatch = useDispatch();

    // useEffect(() => {
    //     history.listen((location, action) => {
    //         // console.log(reportWebVitals(location))
    //         // clear alert on location change
    //         // dispatch(alertActions.clear());
    //     });
    // }, [dispatch]);

    const isDevelopment = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')

    //react animations
    //https://blog.logrocket.com/improve-your-ui-with-react-transition-group-999fa35f7cae/

    // Redo toasts
    // https://react-hot-toast.com  use this with OAuth (FB/IG/Twitter/Tiktok
    // https://www.toptal.com/react/redux-toolkit-and-rtk-query
    // Nice symfony resource for API
    // https://github.com/the-fast-track/book-5.0-1/tree/26f6458f88cac2fd64555b8dd28a1c1e0fbaaeca/src/Repository
    // https://stackoverflow.com/questions/50840168/how-to-detect-if-the-os-is-in-dark-mode-in-browsers light and dark
    // https://le-gall.bzh/post/form-validation-with-formik/ navod na formik a redux + server validation
    // https://react-bootstrap.github.io/components/forms/#forms-validation-libraries bootstrap, formik, yup
    // https://medium.com/code-divoire/how-to-internationalize-a-yup-validation-schema-in-a-react-formik-and-react-i18next-app-689ff3cd978 multilingual yup validation
    // https://www.yoxutube.com/watch?v=kKpoAhugjUc todo multi step formik
    // https://codesandbox.io/s/github/reduxjs/redux/tree/master/examples/real-world Example
    // https://codesandbox.io/s/github/reduxjs/redux/tree/master/examples/real-world todo Message (Check here)
    // https://reacttraining.com/react-router/web/example/auth-workflow todo CMS MyArea
    // https://reacttraining.com/react-router/web/example/nesting todo CMS

    // https://wanago.io/2021/04/26/generic-confirmation-modal-redux-redux-toolkit-typescript/  interesting modal stuff
    //
    //

    //home page add text from this:
    // https://www.constantcontact.com/blog/website-why-you-need-a-personal-website/

    // - ?? SFS Feature plan (Registration predtym)

    //cool design
    // https://keenthemes.com/metronic/?page=index

    //cool img
    // https://www.freepik.com/free-vector/mobile-application-page-design-with-web-hosting-services-symbols-flat-illustration_6871655.htm
    //Meet US img
    //https://www.freepik.com/free-vector/teamwork-concept-landing-page_4730660.htm#page=3&position=1


    // Use effect in navbar to change color when in chatGPT or dashboard
    // useEffect(()=>
    //     {
    //         if(location.pathname == '/about'){
    //         // Change background color
    //         }
    //     }
    // },[location])

    return (
        <BrowserRouter>
            <GlobalHistory/>
            <Seo
                title={'Quickg - Profile in bio tool, one profile, entire online presence!'}
                description={'Create a custom bio site and monetize your online presence! Accept payments, track analytics and share affiliate links.'}
            />
            <Header/>
            <Routes>
                {/* todo add lazy load for admin stuff! especial for the blog thingy*/}
                <Route exact path='/' element={<Home/>}/>
                {/*<Route exact path='/login' element={<Upload/>}/>*/}
                <Route exact path='/login' element={<Login/>}/>
                <Route exact path='/register' element={<Register/>}/>
                <Route exact path='/forgotPassword' element={<ForgotPasswordRequest/>}/>
                <Route exact path='/changePassword' element={<ForgotPasswordChange/>}/>
                <Route exact path='/verifyEmail' element={<VerifyEmail/>}/>
                {/*<Route exact path='/tags' element={<Tags/>}/>*/}
                <Route exact path='/gdpr' element={<GDPR/>}/>
                <Route exact path='/privacy' element={<Privacy/>}/>
                <Route exact path='/terms' element={<Terms/>}/>
                <Route exact path='/unsubscribe' element={<UnsubscribeAll/>}/>
                <Route exact path='/unsubscribe/:id' element={<UnsubscribeProfile/>}/>

                <Route exact path="/profile" element={<PrivateOutlet/>}>
                    <Route index element={<UserPrivateProfile/>}/>
                </Route>
                <Route exact path="/profile/edit" element={<PrivateOutlet/>}>
                    <Route index element={<Settings/>}/>
                </Route>
                <Route exact path="/emails" element={<PrivateOutlet/>}>
                    <Route index element={<EmailSubscribeList/>}/>
                </Route>
                <Route exact path="/supporters" element={<PrivateOutlet/>}>
                    <Route index element={<SupportersList/>}/>
                </Route>
                <Route exact path="/admin/*" element={<PrivateOutlet/>}>
                    <Route path={'*'} element={<Admin/>}/>
                </Route>
                <Route exact path='/discover' element={<Discover/>}/>
                <Route exact path='/blog' element={<Blogs/>}/>
                <Route exact path='/blog/:blogId' element={<ViewBlog/>}/>
                <Route exact path='/:username' element={<UserPublicProfile/>}/>
                <Route element={<NotFound/>}/>
            </Routes>
            <Alerts/>
            <Footer/>
            {isDevelopment ? <Profiler/> : null}
        </BrowserRouter>
    )
}

App.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
};

export default withTranslation()(App);
