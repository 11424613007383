import React from "react";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {useGetRecentSupportersQuery} from "../../../../services/supportMeService";
import UserPhotoPlaceholderImg from "../../../../styles/images/profile-user.svg";
import Moment from "react-moment";

const RecentSupportMe = ({t, i18n, userId = null}) => {
    const {
        data,
        isFetching,
        isError
    } = useGetRecentSupportersQuery({id: userId, limit: 5})

    if (isError || isFetching) {
        return (<h1>Loading</h1>)
    }

    return (
        <div className="support-wrapper">
            {data.slice(0, 5).map(supporter => (
                <div className="support-card" key={supporter.id}>
                    <img src={UserPhotoPlaceholderImg} alt="supporter"/>
                    <div className="info">
                        <p className='name'>{supporter.username} <span className='date'><Moment
                            date={supporter.created_at} format={'ll'}/></span></p>
                        <p className='bio'>{supporter.message}</p>
                    </div>
                    <h4 className="price">{supporter.amount}</h4>
                </div>
            ))
            }
        </div>
    )
};

RecentSupportMe.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
    userId: PropTypes.string,
};

export default withTranslation()(RecentSupportMe);
