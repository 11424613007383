import React from "react";
import {Nav} from "react-bootstrap";
import {Link, useLocation} from 'react-router-dom';
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import {NavHashLink} from "react-router-hash-link";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const NavMenu = ({t, i18n, handleShow, hasUser, isAdmin}) => {
    const {pathname} = useLocation();

    return (
        <Nav className="me-auto my-2 my-lg-0">
            <Link onClick={handleShow} className={`nav-link ${pathname === '/' ? 'active' : ''}`} to="/">Home</Link>
            {!process.env.NODE_ENV || process.env.NODE_ENV === 'development'
                ? <>|
                    <Link onClick={handleShow} className={`nav-link ${pathname === '/tags' ? 'active' : ''}`}
                          to="/tags">{t('nav.tags')}</Link>
                    |
                </>
                : null
            }

            {hasUser
                ? <>
                    <Link onClick={handleShow} className={`nav-link ${pathname === '/profile' ? 'active' : ''}`}
                          to="/profile">{t('nav.profile')}</Link>
                    <Link onClick={handleShow} className={`nav-link ${pathname === '/emails' ? 'active' : ''}`}
                          to="/emails">{t('nav.email')}</Link>
                    <Link onClick={handleShow} className={`nav-link ${pathname === '/supporters' ? 'active' : ''}`}
                          to="/supporters">{t('nav.supporters')}</Link>
                </>
                : <>
                    <NavHashLink className={`nav-link`} onClick={handleShow} to="/#pricing" activeClassName="active">
                        {t('nav.pricing')}
                    </NavHashLink>
                </>
            }
            <Link onClick={handleShow} className={`nav-link ${pathname === '/discover' ? 'active' : ''}`}
                  to="/discover"><FontAwesomeIcon className={'pe-2'} icon={'compass'}/>{t('nav.discover')}</Link>
            <Link onClick={handleShow} className={`nav-link ${pathname === '/blog' ? 'active' : ''}`}
                  to="/blog">{t('nav.blog')}</Link>
            {isAdmin
                ? <Link onClick={handleShow} className={`nav-link ${pathname === '/admin' ? 'active' : ''}`}
                        to="/admin">{t('nav.admin')}</Link>
                : null
            }
        </Nav>
    );
};

NavMenu.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
    handleShow: PropTypes.func,
    hasUser: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
};

export default withTranslation()(NavMenu);
