import React from "react";
import {AddButton} from "../../../styled-components/Buttons";
import {Hashtags, IconInput, TagContainer,} from "../../../styled-components/S_Setting";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import {userPrivateType} from "../../../types";
import {withTranslation} from "react-i18next";
import {FieldArray, Formik, insert} from "formik";
import {array, object, string} from "yup";
import {Form, InputGroup} from "react-bootstrap";
import {userActions} from "../../../actions/user";
import {useDispatch} from "react-redux";
import Header from "./Header";


const Hashtag = ({t, i18n, user}) => {
    const dispatch = useDispatch();

    const hashtags = user?.profile_settings?.hashtags?.hashtags.map(hashtag => hashtag.charAt(0) === '#'
        ? hashtag.slice(1)
        : hashtag
    ) ?? []

    const schema = object({
        hashtags: array().of(
            string().min(2).required()
        ).max(3, 'Max free limit 3 hashtags.'),
        //    todo adjust based on subscription type
    });

    return (
        <Hashtags>
            <Header>Hashtags</Header>
            <Formik
                validationSchema={schema}
                validateOnMount={true}
                enableReinitialize={true}
                initialValues={{hashtags: hashtags}}
                onSubmit={values => {
                    dispatch(userActions.changeProfileSettings(user, {
                        'hashtags': {
                            ...user.profile_settings?.hashtags,
                            hashtags: values.hashtags.map(hashtag => hashtag.charAt(0) !== '#' ? insert(hashtag, 0, '#').join('') : hashtag) ?? []
                        }
                    }))
                }}
            >
                {({
                      handleSubmit,
                      handleChange,
                      handleReset,
                      handleBlur,
                      values,
                      dirty,
                      touched,
                      isValid,
                      isSubmitting,
                      errors,
                      setFieldValue,
                      setFieldTouched,
                  }) => (<Form>
                        <FieldArray
                            name="hashtags"
                            render={arrayHelpers => (
                                <Hashtags>
                                    <div>
                                        {values.hashtags && values.hashtags.length > 0 ? (
                                            values.hashtags.map((hashtag, index) => (
                                                <TagContainer key={`hashtag.${index}`}>
                                                    <Form.Group controlId={`hashtagsForm.${index}`}>
                                                        <IconInput key={`hashtag.${index}`}
                                                                   hasValue={values[hashtag] !== ''}>
                                                            <div>
                                                                <InputGroup.Text id={`hashtags-${index}-#`}
                                                                                 className={''}>#</InputGroup.Text>
                                                                <Form.Control type="text"
                                                                              name={`hashtags.${index}`}
                                                                              value={values.hashtags[index]}
                                                                              onBlur={e => {
                                                                                  handleBlur(e)
                                                                                  setTimeout(() => {
                                                                                      if (dirty && isValid)
                                                                                          handleSubmit(e)
                                                                                  }, 0);
                                                                              }}
                                                                              onChange={handleChange}
                                                                              isValid={touched?.hashtags?.[index] && !errors?.hashtags?.[index]}
                                                                              isInvalid={!!errors?.hashtags?.[index]}
                                                                />
                                                            </div>
                                                            <FontAwesomeIcon icon={'trash'} onClick={e => {
                                                                arrayHelpers.remove(index)
                                                                setTimeout(() => {
                                                                    if (isValid)
                                                                        handleSubmit(e)
                                                                }, 0);
                                                            }
                                                            }/>
                                                        </IconInput>
                                                        <Form.Control.Feedback
                                                            type="invalid"
                                                            className={'tooltip text-danger'}>
                                                            {typeof errors?.hashtags === 'string' ? errors?.hashtags : errors?.hashtags?.[index]}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </TagContainer>
                                            ))
                                        ) : null}
                                    </div>
                                    <AddButton action={() => arrayHelpers.push('')}>Add Hashtag</AddButton>
                                </Hashtags>
                            )}
                        />
                    </Form> //todo validateFromAPI
                )}
            </Formik>
        </Hashtags>
    );
}

Hashtag.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
    user: userPrivateType,
};

export default withTranslation()(Hashtag);
