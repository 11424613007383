const variants = {
    outline: () => {
        return {
            field: {
                h: "51px",
                border: "1px solid",
                borderColor: "#D8D8D8",
                color: "#AAAAAA",
                _hover: {
                    borderColor: "gray.500",
                },
                _placeholder: {
                    color: "#AAAAAA",
                },
                _focus: {
                    borderColor: "brand.dark",
                    outline: "none",
                    boxShadow: "none",
                    _invalid: {
                        borderColor: "red.400",
                        bg: "red.50",
                    },
                },

                _invalid: {
                    borderColor: "red.400",
                    bg: "red.50",
                },
            },
        };
    },
};

const sizes = {
    lg: {
        field: {
            fontSize: "md",
            borderRadius: "lg",
        },
    },
};

const input = {
    variants,
    sizes,
};

export default input;
