import React, {useEffect} from "react";
import {Card, Col, Container, Row} from "react-bootstrap";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import Header from "../../components/Header";
import {useGetBlogsQuery} from "../../services/blogService";
import Loading from "../../components/General/Loading";
import DoubleShapeTop from "../../components/General/DoubleShapeTop";
import DoubleShapeBottom from "../../components/General/DoubleShapeBottom";
import Moment from "react-moment";
import {Link} from "react-router-dom";

const Blogs = ({t, i18n}) => {
    const {data, isLoading} = useGetBlogsQuery()

    useEffect(() => {
        document.title = 'Quickg - Blog';
    });

    if (data === undefined || isLoading) {
        return <Loading/>
    }

    return (
        <Container fluid>
            <Row className={'mb-5'}>
                <Header header='Blog'/>
            </Row>
            <Row>
                <Col className={'mx-auto'} md={8}>
                    {data.map(blog =>
                        <Card key={blog.id} as={Link} to={`blog/${blog.id}`} className="text-center mb-4">
                            <Card.Header>{blog.title}</Card.Header>
                            <Card.Body>
                                {/*<Card.Title>Special title treatment</Card.Title>*/}
                                <Card.Text>
                                    {blog.description}
                                </Card.Text>
                                {/*<Button variant="primary">Go somewhere</Button>*/}
                            </Card.Body>
                            <Card.Footer className="text-muted"><Moment fromNow>{blog.created_at}</Moment></Card.Footer>
                        </Card>
                    )}
                </Col>
            </Row>
            <DoubleShapeTop/>
            <DoubleShapeBottom/>
        </Container>
    )
}

Blogs.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
};

export default withTranslation()(Blogs);

