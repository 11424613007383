import React, {useEffect} from "react";
import {Form, Row} from "react-bootstrap";
import {Formik} from "formik";
import {withTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import * as ReactGA from "react-ga";
import {userActions} from "../actions/user";
import {object, string} from "yup";
import PropTypes from "prop-types";
import {useLocation} from "react-router-dom";
import {InputGroup, RegisterInputContainer, RegisterInputs} from "../styled-components/S_Registration";
import {IconInput, SettingInput, SettingLabel} from "../styled-components/S_Setting";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {PrimaryButton} from "../styled-components/Buttons";

const LoginForm = ({t, i18n}) => {
    const dispatch = useDispatch();
    let location = useLocation();

    let {from} = location.state || {from: {pathname: "/profile"}};

    useEffect(() => {
        dispatch(userActions.logout());
    }, [dispatch]);

    const schema = object({
        username: string().min(2).max(50).required(),
        password: string().min(2).max(50).required()
    });

    return (
        <Formik
            onSubmit={(values, {setSubmitting, resetForm}) => {
                setSubmitting(true);
                ReactGA.event({
                    category: 'Login',
                    action: 'Login Form'
                });
                dispatch(userActions.login(values.username, values.password, from))
                setSubmitting(false);
            }}
            enableReinitialize={true}
            validationSchema={schema}
            initialValues={{
                username: '',
                password: '',
            }}>
            {({
                  handleSubmit,
                  handleChange,
                  handleReset,
                  handleBlur,
                  values,
                  dirty,
                  touched,
                  isValid,
                  isSubmitting,
                  errors,
                  setFieldValue,
                  setFieldTouched,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <RegisterInputs>
                        <InputGroup>
                            <RegisterInputContainer>
                                <Form.Group controlId="loginForm.username">
                                    <SettingLabel>Username:</SettingLabel>
                                    <IconInput>
                                        <div>
                                            <FontAwesomeIcon icon={'user'}/>
                                            <SettingInput type="text"
                                                          name="username"
                                                          value={values.username}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}
                                                          isValid={touched.username && !errors.username}
                                                          isInvalid={!!errors.username}
                                            />
                                        </div>
                                    </IconInput>
                                </Form.Group>
                            </RegisterInputContainer>
                        </InputGroup>
                    </RegisterInputs>
                    <RegisterInputs>
                        <InputGroup>
                            <RegisterInputContainer>
                                <Form.Group controlId="loginForm.password">
                                    <SettingLabel>Password:</SettingLabel>
                                    <IconInput>
                                        <div>
                                            <FontAwesomeIcon icon={'lock'}/>
                                            <SettingInput type="password"
                                                          name="password"
                                                          value={values.password}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}
                                                          isValid={touched.password && !errors.password}
                                                          isInvalid={!!errors.password}

                                            />
                                        </div>
                                    </IconInput>
                                </Form.Group>
                            </RegisterInputContainer>
                        </InputGroup>
                    </RegisterInputs>
                    <Row className='d-flex justify-content-between mt-5'>
                        <PrimaryButton type="submit" disabled={isSubmitting}>{isSubmitting &&
                            <span className="spinner-border spinner-border-sm me-1"/>}
                            {t('nav.signIn')}
                        </PrimaryButton>
                    </Row>
                </Form>
            )}
        </Formik>
    )
};

LoginForm.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
};

export default withTranslation()(LoginForm);
