import React, {useEffect} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {withTranslation} from "react-i18next";
// import Header from "../components/Header";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import Header from "../components/Header";

const GDPR = ({t, i18n}) => {
    useEffect(() => {
        document.title = 'Quickg - GDPR';
    });

    return (
        <Container fluid>
            <Row className={'mb-5'}>
                <Header header='GDPR Compliance Statement'/>
            </Row>
            <Row>
                <Col>
                    <Container>
                        <Row>
                            <Col>
                                <p>
                                    Quickg (“we”, “us” or “our”) is committed to ensuring the security and protection of
                                    the
                                    personal information that we process, and to provide a compliant and consistent
                                    approach to data
                                    protection.
                                </p>
                                <p>
                                    We have created this GDPR Compliance Statement to explain our approach to
                                    implementing our GDPR
                                    compliance program. It describes the implementation of our data protection roles,
                                    policies,
                                    procedures, controls and measures to ensure ongoing compliance with GDPR.
                                </p>
                                <h4>Our GDPR Principles</h4>
                                <p>
                                    Quickg takes the privacy and security of individuals and their personal information
                                    very
                                    seriously.
                                    Our principles for processing personal information are:
                                </p>
                                <ul>
                                    <li>We will process all personal information fairly and lawfully</li>
                                    <li>We will only process personal information for specified and lawful
                                        purposes
                                    </li>
                                    <li>Where practical, we will keep personal information up to date</li>
                                    <li>We will not keep personal information for longer than is necessary</li>
                                </ul>
                                <h4>Data Subjects Rights under GDPR</h4>
                                <p>
                                    At Quickg, an individual can request information about:
                                </p>
                                <ul>
                                    <li>What personal information we hold about an individual</li>
                                    <li>The categories of personal information we collect from an individual</li>
                                    <li>
                                        The purposes for collecting and processing personal information from an
                                        individual
                                    </li>
                                    <li>How long we plan to keep the personal information</li>
                                    <li>
                                        The process to have incomplete or inaccurate personal information corrected
                                        or completed
                                    </li>
                                    <li>
                                        Where applicable, the process for requesting erasure of the personal
                                        information or for restricting the processing of personal information in
                                        accordance with data protection laws, as well as to object to any direct
                                        marketing from us
                                    </li>
                                    <li>About any automated decision-making that we use</li>
                                </ul>
                                <h4>Our GDPR compliance plan</h4>
                                <p>
                                    Here&apos;s an overview of our steps that we are taking to ensure compliance with
                                    GDPR at
                                    Quickg:
                                </p>

                                <ul>
                                    <li>We conducted a data mapping inventory and analysis of collected personal
                                        information in our systems and records
                                    </li>
                                    <li>We have established procedures and policies to restrict processing of
                                        personal information
                                    </li>
                                    <li>We have updated our procedures for data breaches and incident responses</li>
                                    <li>We have updated our company’s Data Protection Policy, Data Retention Policy,
                                        Information Security Policy, Cookies Policy and Privacy Policy
                                    </li>
                                    <li>We have reviewed all processing activities to identify the legal basis for
                                        processing personal information and to ensure that each basis is appropriate
                                        for the activity it relates to
                                    </li>
                                </ul>
                                <h4>Contact us if you have GDPR related questions</h4>
                                <p>
                                    If you have any questions about this GDPR Compliance Statement, or our privacy or
                                    security practices, please contact us:
                                </p>

                                <ul>
                                    <li>Website: <Link to='/contact'>https://quickg.eu/contact</Link></li>
                                    <li>Email: <a href="mailto:info@quickg.eu?Subject=GDPR"
                                                  target="_top">info@quickg.eu</a></li>
                                </ul>
                                {/*Phone: Phone Number*/}
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Container>
    )
}

GDPR.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
};

export default withTranslation()(GDPR);
