import React from "react";
import PropTypes from "prop-types";
import {LexicalComposer} from "@lexical/react/LexicalComposer";
import {exampleTheme, LoadInitialContent,} from "../../helpers/editorPlugins";
import {RichTextPlugin} from "@lexical/react/LexicalRichTextPlugin";
import {ContentEditable} from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import {HeadingNode, QuoteNode} from "@lexical/rich-text";
import {ListItemNode, ListNode} from "@lexical/list";
import {CodeHighlightNode, CodeNode} from "@lexical/code";
import {TableCellNode, TableNode, TableRowNode} from "@lexical/table";
import {AutoLinkNode, LinkNode} from "@lexical/link";

const RenderBlog = ({post}) => {

    const editorConfig = {
        editable: false,
        theme: exampleTheme,
        onError(error) {
            throw error;
        },
        // Any custom nodes go here
        nodes: [
            HeadingNode,
            ListNode,
            ListItemNode,
            QuoteNode,
            CodeNode,
            CodeHighlightNode,
            TableNode,
            TableCellNode,
            TableRowNode,
            AutoLinkNode,
            LinkNode
        ]
    };


    return (
        <LexicalComposer initialConfig={editorConfig}>
            <LoadInitialContent initialContent={post}/>
            <RichTextPlugin
                contentEditable={<ContentEditable className="editor-input"/>}
                placeholder={'Article'}
                ErrorBoundary={LexicalErrorBoundary}
            />
        </LexicalComposer>
    )
}

RenderBlog.propTypes = {
    post: PropTypes.object.isRequired,
};

export default RenderBlog;


