import store from "../store";
import {isRejectedWithValue} from "@reduxjs/toolkit";
import {developmentActions} from "../actions/dev/development";

export const rtkQueryLogger = (api) => (next) => (action) => {
    if (process.env.NODE_ENV !== 'development') {
        return next(action)
    }

    if (action.type.includes('api/')) {

        if (action?.meta !== undefined) {
            console.log(action.meta?.arg?.endpointName)
        }

        const response = action.meta?.baseQueryMeta?.response
        if (response !== undefined && response.headers.get('x-debug-token-link') !== null) {
            store.dispatch(developmentActions.addProfilerLink({
                url: response.url,
                status: response.status,
                profiler: response.headers.get('x-debug-token-link')
            }))
        }
    }

    if (isRejectedWithValue(action)) {
        console.warn('We got a rejected action!', alert)
    }

    return next(action)
}
