import React, {useEffect} from "react";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {Link, useLocation} from "react-router-dom";
import {useGetEmailVerificationTokenQuery} from "../../services/userService";
import Loading from "../../components/General/Loading";
import {RegisterHeader, RegisterWrapper, RegistrationContainer} from "../../styled-components/S_Registration";
import Header from "../../components/Header";
import {PrimaryButton} from "../../styled-components/Buttons";

const VerifyEmail = ({t, i18n}) => {
    const queryParams = new URLSearchParams(useLocation().search);
    const id = queryParams.get("id");
    const {
        data,
        isFetching,
        isError
    } = useGetEmailVerificationTokenQuery(id, {skip: id === null})

    useEffect(() => {
        document.title = 'Quickg - Email verification'
    });

    if (isFetching) {
        return (
            <Loading text={t('user.verify.verifying')}/>
        )
    }

    if (isError || id === null) {
        return (
            <RegistrationContainer>
                <Header hasGrayBackground={true}/>
                <div>
                    <RegisterWrapper>
                        <RegisterHeader>
                            <h1>{t('user.verify.invalid.header')}</h1>
                        </RegisterHeader>
                        <h4>
                            {t('user.verify.invalid.content')}
                        </h4>
                    </RegisterWrapper>
                </div>
            </RegistrationContainer>
        )
    }

    return (
        <RegistrationContainer>
            <Header hasGrayBackground={true}/>
            <div>
                <RegisterWrapper>
                    <RegisterHeader>
                        <h1>{t('user.verify.valid.header', {username: data.user.username})}</h1>
                    </RegisterHeader>
                    <h4>
                        {t('user.verify.valid.subHeader')}
                    </h4>
                    <h5>
                        {t('user.verify.valid.content')}
                    </h5>
                    <Link to={'/login'}><PrimaryButton className={'mt-2'}>Sign in now!</PrimaryButton></Link>
                </RegisterWrapper>
            </div>
        </RegistrationContainer>
    )
}

VerifyEmail.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
};

export default withTranslation()(VerifyEmail);
