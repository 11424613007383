import {userService} from "../services/userService";
import {userConstants} from "../constants/action-types";
import * as ReactGA from "react-ga";
import {baseApi} from "../services/apiService";
import {logoutNew, setTokens} from "../slicers/authSlice";
import {errorAlert, successAlert, warningAlert} from "../slicers/alertsSlice";
import {globalNavigate} from "../helpers/global-history";

const login = (username, password, from) => {
    return (dispatch, getState) => {
        if (getState().authentication.loggingIn === true) {
            return;
        }
        dispatch(request({username}));

        userService.login(username, password)
            .then(
                response => {
                    dispatch(success(response));
                    dispatch(setTokens(response))
                    globalNavigate(from);
                },
                error => {
                    dispatch(failure(error));
                    dispatch(warningAlert('alert.invalidCredentials'));
                }
            );
    };

    function request(user) {
        return {type: userConstants.LOGIN_REQUEST, user}
    }

    function success(response) {
        return {type: userConstants.LOGIN_SUCCESS, response}
    }

    function failure(error) {
        return {type: userConstants.LOGIN_FAILURE, error}
    }
}

const logout = () => {
    return (dispatch) => {
        userService.logout();
        localStorage.removeItem('user');
        dispatch(logoutNew())
        dispatch(baseApi.util.resetApiState());

        return {type: userConstants.LOGOUT};
    }
}

const update = (id, user) => {
    return (dispatch, getState) => {
        if (getState().authentication.updatingProfile === true) {
            return;
        }
        dispatch(request(user));

        userService.update(id, user)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(baseApi.endpoints.getCurrentUser.initiate(undefined, {forceRefetch: true}))
                    dispatch(successAlert('User profile updated!'));
                },
                error => {
                    if (error.code === 422) {
                        dispatch(warningAlert('alert.invalidForm'));
                    } else {
                        dispatch(errorAlert());
                    }
                    dispatch(failure(error));
                }
            );
    };

    function request(user) {
        return {type: userConstants.UPDATE_REQUEST, user}
    }

    function success(user) {
        return {type: userConstants.UPDATE_SUCCESS, user}
    }

    function failure(error) {
        return {type: userConstants.UPDATE_FAILURE, error}
    }
}

const changeProfileSettings = (user, settings) => {
    return (dispatch, getState) => {
        if (getState().authentication.updatingProfile === true) {
            return;
        }
        dispatch(request(user));
        ReactGA.event({
            category: 'User',
            action: 'Updated user settings'
        });
        //todo merge when more of the hidden parts

        const {data} = baseApi.endpoints.getCurrentUser.select()(getState())

        dispatch(baseApi.endpoints.changeUserSettings.initiate({id: user.id, ...data.profile_settings, ...settings}))
            .unwrap()
            .then((payload) => {
                dispatch(success(payload));
                dispatch(successAlert('User profile setting updated!'));
            })
            .catch((error) => {
                if (error.code === 422) {
                    dispatch(warningAlert('alert.invalidForm'));
                } else {
                    dispatch(errorAlert());
                }
                dispatch(failure(error));
            })
    };

    function request(user) {
        return {type: userConstants.UPDATE_SETTINGS_REQUEST, user}
    }

    function success(profileSettings) {
        return {type: userConstants.UPDATE_SETTINGS_SUCCESS, profileSettings}
    }

    function failure(error) {
        return {type: userConstants.UPDATE_SETTINGS_FAILURE, error}
    }
}

const changePassword = (id, passwords) => {
    return (dispatch, getState) => {
        if (getState().authentication.isChangingPassword === true) {
            return;
        }
        dispatch(request());

        userService.changePassword(id, passwords)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(successAlert('User password updated!'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(errorAlert(error.toString()));
                }
            );
    };

    function request(user) {
        return {type: userConstants.CHANGE_PASSWORD_REQUEST, user}
    }

    function success(user) {
        return {type: userConstants.CHANGE_PASSWORD_SUCCESS, user}
    }

    function failure(error) {
        return {type: userConstants.CHANGE_PASSWORD_FAILURE, error}
    }
}

const register = (user) => {
    return (dispatch, getState) => {
        if (getState().registration.isRegistering === true) {
            return;
        }
        dispatch(request(user));

        userService.register(user)
            .then(
                user => {
                    dispatch(success(user));
                    globalNavigate('/login');
                    dispatch(successAlert('Registration successful'));
                },
                error => {
                    if (error.code === 422) {
                        dispatch(warningAlert('alert.invalidForm'));
                    } else {
                        dispatch(errorAlert());
                    }

                    dispatch(failure(error));
                }
            );
    };

    function request(user) {
        return {type: userConstants.REGISTER_REQUEST, user}
    }

    function success(user) {
        return {type: userConstants.REGISTER_SUCCESS, user}
    }

    function failure(error) {
        return {type: userConstants.REGISTER_FAILURE, error}
    }
}

// prefixed function name with underscore because delete is a reserved word in javascript
const _delete = (id) => {
    return (dispatch, getState) => {
        if (getState().authentication.isDeletingAccount === true) {
            return;
        }
        dispatch(request(id));

        userService.delete(id)
            .then(
                user => {
                    globalNavigate('/')
                    dispatch(success(user))
                },
                error => dispatch(failure(id, error.toString()))
            );
    };

    function request(id) {
        return {type: userConstants.DELETE_REQUEST, id}
    }

    function success(id) {
        return {type: userConstants.DELETE_SUCCESS, id}
    }

    function failure(id, error) {
        return {type: userConstants.DELETE_FAILURE, id, error}
    }
}

const clearFormErrors = () => {
    return dispatch => {
        dispatch(success());
    };

    function success(crop) {
        return {type: userConstants.CLEAR_FORM_ERRORS_SUCCESS, crop}
    }
}

export const userActions = {
    login,
    logout,
    changePassword,
    register,
    update,
    clearFormErrors,
    changeProfileSettings,
    delete: _delete
};
