import React from "react";
import {Manage,} from "../../../styled-components/S_Setting";
import {adultContent, privateProfile, showEmail} from "../../../helpers/userSettingsHelper";
import PropTypes from "prop-types";
import {userPrivateTypeStrict, userPublicType} from "../../../types";
import {userActions} from "../../../actions/user";
import {useDispatch} from "react-redux";
import {withTranslation} from "react-i18next";
import ToggleSwitch from "./ToggleSwitch";
import Header from "./Header";

const ContentManager = ({t, i18n, user}) => {
    const dispatch = useDispatch()

    return (
        <div>
            <Manage>
                <div>
                    <Header
                        danger={!adultContent(user)}
                        status={adultContent(user) ? 'user.profile.active' : 'user.profile.inactive'}
                    >
                        Adult Content
                    </Header>
                    <p>Please mark your profile as Adult if you are promoting or advertising items suitable for 18+ /
                        21+</p>
                </div>
                <ToggleSwitch
                    text={['user.profile.notAdult', 'user.profile.adult',]}
                    value={adultContent(user)}
                    action={() => dispatch(userActions.changeProfileSettings(user, {'adultContent': !adultContent(user)}))}
                />
            </Manage>
            <Manage>
                <div>
                    <Header
                        danger={!privateProfile(user)}
                        status={privateProfile(user) ? 'user.profile.active' : 'user.profile.inactive'}
                    >Private Profile</Header>

                    <p>If your profile is public other people can find you! If you wish to stay hidden please mark it as
                        private.</p>
                </div>
                <ToggleSwitch
                    text={['user.profile.public.public', 'user.profile.private.private']}
                    value={privateProfile(user)}
                    action={() => dispatch(userActions.changeProfileSettings(user, {'privateProfile': !privateProfile(user)}))}
                />
            </Manage>
            <Manage
                style={{
                    borderBottom: "none",
                    marginBottom: "0",
                }}
            >
                <div>
                    <Header
                        danger={!showEmail(user)}
                        status={showEmail(user) ? 'user.profile.active' : 'user.profile.inactive'}
                    >Show Email</Header>

                    <p>Do you wish to show your email on your profile?</p>
                </div>
                <ToggleSwitch
                    text={['user.profile.visible', 'user.profile.hidden']}
                    value={!showEmail(user)}
                    action={() => dispatch(userActions.changeProfileSettings(user, {'showEmail': {enabled: !showEmail(user)}}))}
                />
            </Manage>
            {/*<Message>*/}
            {/*    <div>*/}
            {/*        <div>*/}
            {/*            <Text size="1.4rem">Message</Text>*/}
            {/*            <InputSwitch/>*/}
            {/*        </div>*/}
            {/*        <Text>*/}
            {/*            User can also change this in profile settings. If profile is*/}
            {/*            public other people can find him. If not … Then he is only able to*/}
            {/*            preview his public profile and profile link is disabled.*/}
            {/*        </Text>*/}
            {/*    </div>*/}
            {/*    <InputSwitch/>*/}
            {/*</Message>*/}

            {/*<SettingBtn>SETTINGS</SettingBtn>*/}

        </div>
    );
}

ContentManager.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
    user: PropTypes.oneOfType([userPrivateTypeStrict, userPublicType])
};

export default withTranslation()(ContentManager);
