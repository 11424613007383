import {baseApi} from "./apiService";

export const supportMeApi = baseApi.injectEndpoints({
        overrideExisting: false,
        endpoints: (builder) => ({
            getRecentSupporters: builder.query({
                query: (body) => {
                    const {id, limit} = body
                    return `/supportMe/recent${id === null ? '' : `/${id}`}?limit=${limit}`
                },
                providesTags: (result, errors, id) =>
                    result
                        ? [
                            ...result.map(({id}) => ({type: 'RecentSupportMe', id})),
                            {type: 'RecentSupportMe', id: 'PARTIAL-LIST'},
                            {type: 'RecentSupportMe', id: `user_${id}`},
                        ] : [
                            {type: 'RecentSupportMe', id: 'PARTIAL-LIST'},
                            {type: 'RecentSupportMe', id: `user_${id}`},
                        ]
            }),
        }),
    }
)

export const {
    useGetRecentSupportersQuery,
} = supportMeApi
