import React, {useEffect} from "react";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import Loading from "../../components/General/Loading";
import {useDeleteEmailSubscribeMutation, useGetEmailSubscribersQuery} from "../../services/emailSubscribeService";
import {useGetCurrentUserQuery} from "../../services/userService";
import {Alert, Col, Row, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DoubleShapeTop from "../../components/General/DoubleShapeTop";
import DoubleShapeBottom from "../../components/General/DoubleShapeBottom";
import Header from "../../components/Header";
import {PrimaryButton} from "../../styled-components/Buttons";
import {RegisterHeader, RegisterWrapper, RegistrationContainer} from "../../styled-components/S_Registration";

const EmailSubscribeList = ({t, i18n}) => {

    const emailSubscribers = useGetEmailSubscribersQuery()
    const user = useGetCurrentUserQuery()
    //todo use is loading (some blur or something)
    // const [deleteEmailSubscribe, {isLoading}] = useDeleteEmailSubscribeMutation()
    const [deleteEmailSubscribe] = useDeleteEmailSubscribeMutation()


    useEffect(() => {
        document.title = 'Quickg - '.concat(t('emailSubscribe.list.title'));
    });


    if (emailSubscribers.isLoading || user.isLoading) {
        return (
            <Loading/>
        )
    }

    return (
        <RegistrationContainer>
            <Header hasGrayBackground={true}/>
            <div>
                <RegisterWrapper>
                    <RegisterHeader>
                        <h1>{t('emailSubscribe.list.title')}</h1>
                    </RegisterHeader>
                    {/* todo VALUE BASED ON ROLE */}
                    {emailSubscribers.data.length > 100
                        ? <Row className='d-flex justify-content-between mt-5'>
                            <Col>
                                <Alert variant={'warning'} className={'small'}>
                                    You are slowly approaching limits. Please consider upgrading your account!<br/>
                                    {emailSubscribers.data.length ?? 0}/150 email subscriptions
                                </Alert>
                            </Col>
                        </Row>
                        : null}
                    <Row className='d-flex justify-content-between mt-5'>
                        <Col>
                            <Table striped bordered hover>
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Email</th>
                                    <th/>
                                </tr>
                                </thead>
                                <tbody>
                                {emailSubscribers.data.map((emailSubscriber, i) => (
                                    <tr key={emailSubscriber.id}>
                                        <td>{i + 1}</td>
                                        <td>{emailSubscriber.email}</td>
                                        <td>
                                            <PrimaryButton
                                                outline
                                                onClick={() => deleteEmailSubscribe(emailSubscriber.id)}>
                                                <FontAwesomeIcon icon={'times'}/>
                                            </PrimaryButton>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </RegisterWrapper>
            </div>
            <DoubleShapeTop/>
            <DoubleShapeBottom/>
        </RegistrationContainer>
    )
}

EmailSubscribeList.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
};

export default withTranslation()(EmailSubscribeList);
