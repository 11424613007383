import {Container} from "@chakra-ui/react";
import HeroSection from "./components/HeroSection";
import IntroductionSection from "./components/IntroductionSection";
import BenefitSection from "./components/BenefitSection";
import PlanSection from "./components/PlanSection";
import TestimonialSection from "./components/TestimonialSection";
import React, {useState} from "react";

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
      <Container
          maxW="full"
          px={{
            base: "0",
          }}
      >
        <HeroSection toggleMenu={toggleMenu}/>
        <IntroductionSection/>
        <BenefitSection/>
        <PlanSection/>
        <TestimonialSection/>
        {/*<FeatureSection1 />*/}
        {/*<FeatureSection2 />*/}
        {/*<FeatureSection3 />*/}
        {/*<GetSupport />*/}
        {/*<MobileMenu isOpen={isOpen} toggleMenu={toggleMenu} />*/}
      </Container>
  );
};

export default Home;
