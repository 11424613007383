import styled, {css} from "styled-components";
import {AddButtonContainer, PrimaryButton} from "./Buttons";
import {centerMe, colors, flexStart, spaceBetween} from "./styledUtils";
import {Form} from "react-bootstrap";

const inputCss = css`
  border: 1px solid ${colors.gray};
  outline: none;
  border-radius: 5px;
  padding: 0 10px;
  :focus {
    border: 1px solid ${colors.primary};
  }
`;

export const SettingsContainer = styled.div`
  background: #f5f5f5;
  padding-bottom: 1rem;
  p {
    margin-bottom: 0;
  }
  .trash {
    color: #f05252;
    padding: 15px;
    border-radius: 7px;
    background: #fff7f7;
    box-sizing: content-box;
    font-size: 1.5rem;
    cursor: pointer;
    svg {
      padding: 0;
    }
    p {
      display: none;
      @media (max-width: 1005px) {
        display: inline-block;
      }
    }
  }
`;
export const Text = styled.p`
  font-weight: 700;
  font-size: ${(p) => p.size || ".9rem"};
  color: #626262;
`;
export const UserContainer = styled.div`
  ${centerMe}
  flex-direction: column;
  padding-bottom: 1.5rem;
  h3 {
    margin: 19px;
  }
  .adminHero {
    width: 100%;
  }
`;

export const SettingsWrapper = styled.div`
  max-width: 1525px;
  margin: auto;
  padding: 2rem 10px 0;
  ${centerMe}
  flex-direction: column;
  row-gap: 1.2rem;
  > div {
    width: 100%;
    padding: 1.2rem 2rem;
    background: #fff;
    border-radius: 17px;
    @media (max-width: 645px) {
      padding: 1.2rem 1rem;
    }
  }
`;

export const PageHeader = styled.div`
  > div {
  border-bottom: 2px solid #d6d2d27a;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
    }
`;

export const Controls = styled.div`
  ${centerMe}
  column-gap: .75rem;
  margin: auto;
  flex-wrap: wrap;
  row-gap: 1em;
`;

export const BillingInput = styled.div`
  ${spaceBetween}
  width: 100%;
  column-gap: ${(p) => p.gap};
  margin-bottom: 2rem;
  input {
    min-width: 100%;
  }
  textarea {
    width: 100%;
  }
`;

export const Profile = styled.img`
  border-radius: 50%;
  margin: -7rem auto 0;
  box-shadow: 0 0 0 6px #fff, 0 0 0 10px ${colors.primary};
  position: relative;
`;

export const ControlButton = styled.button`
  padding: 10px 1rem;
  border: 2px solid #f6e4eb;
  font-size: large;
  font-weight: 700;
  background: #fff;
  color: ${colors.primary};
  border-radius: 20rem;
  transition: all 0.2s ease;
  svg {
    font-size: 1.4rem;
    margin-right: 4px;
  }
  :hover {
    background: #f6e4eb;
  }
`;

export const SettingBtn = styled.div`
  cursor: pointer;
  text-decoration: underline;
  color: ${colors.primary};
  font-weight: 700;
  font-size: 18px;
`;

export const Manage = styled.div`
  ${spaceBetween}
  border-bottom: 2px solid #d6d2d27a;
  margin-bottom: 2rem;
  :first-child {
    padding-bottom: 5px;
  }
  @media (max-width: 802px) {
    + ${SettingBtn} {
      text-align: center;
      margin-top: 15px;
    }
  }
  p {
    font-size: 0.9rem;
    font-weight: 700;
    color: #626262;
  }
  @media (max-width: 802px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const SubHeader = styled.p`
    font-size: 0.9rem;
    font-weight: 700;
    color: #626262;
  
`;

export const AddSocialContainer = styled.div`
  ${centerMe}
  align-items: flex-start;
  row-gap: 1rem;
  flex-direction: column;

  form {
      ${centerMe}
      align-items: flex-start;
      row-gap: 1rem;
      flex-direction: column;
      width: 100%;
      
      div {
        width: 100%;
      }
  }
`;

export const SettingHeader = styled.h4`
  margin-bottom: 0;
  font-weight: 700;
  position: relative;
`;

export const IconInput = styled.div`
  ${centerMe}
  column-gap: 1rem;
  font-size: 1rem;
  width: 100%;

  div {
    ${flexStart}
    border-radius: 7px;
    border: 2px solid ${colors.gray};
    flex: 1;
    svg {
      color: ${(p) => (p.hasValue ? colors.primary : "#aaaaaa")};
      box-sizing: content-box;
      padding: 10px 15px;
      font-size: 2rem;
    }
  }
  input {
    outline: none;
    border: none;
    width: 100%;
    font-weight: 600;
    padding: 5px 0;
    ::placeholder {
      color: #aaaaaa;
      font-size: 1rem;
    }
  }
`;

export const Hashtags = styled.div`
  ${flexStart}
  align-items: flex-start;
  flex-direction: column;
  row-gap: 1rem;
  > div {
    ${flexStart}
    gap: 1rem;
    flex-wrap: wrap;
    input {
      ${inputCss}
      font-weight: 700;
      font-size: 1rem;
      padding: 7px 10px;
      :focus {
        box-shadow: 10px 10px 20px -8px #bd67853e;
      }
      ::placeholder {
        color: ${colors.textGray};
      }
      @media (max-width: 802px) {
        width: 100%;
      }
    }
  }
`;

export const TagContainer = styled.div`
  ${spaceBetween}
  column-gap: 2rem;
  border: 2px solid ${colors.gray};
  padding: 3px 10px;
  border-radius: 5px;
  min-width: 12rem;
  p {
    font-size: 1.2rem;
    margin-bottom: 0;
    font-weight: 700;
  }
  svg {
    font-size: 1.3rem;
    color: #f05252;
  }
  @media (max-width: 802px) {
    width: 100%;
  }
`;

export const PaymentName = styled.div`
  display: flex;
  column-gap: 1rem;
  align-items: flex-end;
  svg {
    font-size: 3.5rem;
  }
  p {
    color: #aeacad;
    margin: auto 0;
    font-weight: 700;
  }
  @media (max-width: 828px) {
    justify-content: center;
    column-gap: 0.5rem;
  }
`;

export const Progresses = styled.div`
  ${flexStart}
  column-gap: 20px;
  font-size: 1.3rem;
  margin-top: 1rem;
  flex-wrap: wrap;
  row-gap: 1rem;
  p {
    font-weight: 700;
    font-size: 1.2rem;
  }
  div {
    ${spaceBetween}
    column-gap: 10px;
    padding: 5px 20px;
    border-radius: 50rem;
    border: 2px solid ${colors.gray};
  }
  @media (max-width: 828px) {
    justify-content: center;
    p {
      font-size: 1rem;
    }
    div {
      padding: 5px 15px;
    }
  }
`;

export const ProgressItem = styled.div`
 svg {
  color: ${(p) => (p.disabled ? colors.textGray : p.color ? "#39c965" : "#ff5050")};
 }
`;


export const RecentSupporter = styled.div``;

export const SupportMore = styled.div`
  ${flexStart}
  column-gap: 1.5rem;
  ${Text} {
    color: #898989;
  }

  @media (max-width: 828px) {
    justify-content: center;
  }
  @media (max-width: 616px) {
    flex-direction: column;
    > div {
      width: 100%;
    }
  }
  ${PrimaryButton} {
    margin-top: 32px;
    padding: 10px 17px;
    border-radius: 5px;
    font-size: 0.8rem;
  }
`;

export const SupporterView = styled.div`
  margin-top: 3rem;
  @media (max-width: 800px) {
    display: none;
  }
  > p {
    opacity: 0.5;
  }
  > div {
    padding: 10px 2rem 10px 10px;
    border: 2px solid ${colors.gray};
    border-radius: 10px;
    ${centerMe}
    > p {
      font-size: 2rem;
      font-weight: 700;
      color: ${colors.primary};
    }
  }
  img {
    width: 80px;
    height: 80px;
    background: #e2e2e2;
    margin-right: 1rem;
    border-radius: 10px;
  }
`;

export const Store = styled.div``;

export const StoreFilter = styled.div`
  ${PrimaryButton} {
    font-size: 0.9rem;
    padding: 10px 20px;
    border-radius: 5px;
  }
  ${Text} {
    color: #898989;
  }
  td {
    padding-right: 1rem;
    padding-bottom: 1rem;
  }
  tbody td > p {
    display: none;
  }
  textarea {
    width: 100%;
  }
  ${IconInput} {
    max-width: 600px;
  }
  @media (max-width: 597px) {
    table {
      width: 100%;
    }
    tbody {
      width: 100%;
      tr {
        display: flex;
        flex-direction: column;
        width: 100%;
        td {
          width: 100%;
          padding-right: 0;
          ${PrimaryButton} {
            width: 100%;
          }
        }
      }
    }
    thead {
      display: none;
    }
    tbody td > p {
      display: block;
    }
  }
`;

export const Product = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: 1rem;
  ${Text} {
    color: #898989;
  }
  > div {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    gap: 1rem;
    flex-wrap: wrap;

    > div {
      display: flex;
      justify-content: flex-start;
      align-items: stretch;
      gap: 1rem;
      @media (max-width: 708px) {
        width: 100%;
      }
    }
  }
  ${PrimaryButton} {
    padding: 4px 15px;
    border-radius: 10px;
    font-size: 1rem;
    @media (max-width: 708px) {
      width: 100%;
    }
  }
`;

export const Links = styled.div``;

export const LinkContainer = styled.div`
  ${centerMe}
  align-items: flex-start;
  width: 100%;
  flex-direction: column;
  padding: 0.5 rem 0;
  row-gap: 20px;
`;

export const UnderLineLink = styled.button`
  color: ${(p) => p.color || colors.primary};
  font-weight: 700;
  text-decoration: underline;
  background: transparent;
  ${centerMe}
  column-gap: 5px;
  font-size: 1.2rem;
`;


export const SettingLabel = styled(Form.Label)`
  font-weight: 700;
  font-size: ${(p) => p.size || ".9rem"};
  color: #626262;
`;

export const SettingInput = styled(Form.Control)`
  ${inputCss}
  font-size: 1.1rem;
  font-weight: 700;
  height: 56px;
  min-width: 22rem;
  @media (max-width: 562px) {
    min-width: auto;
  }
`;

export const FormText = styled(Form.Text)`
  font-size: ${(p) => p.size || ".9rem"};
  color: #626262;
`;

export const FormRegularRow = styled.div`
  width: 100%;
  ${Text} {
    font-size: 1rem;
  }
`;

export const LinkRow = styled.div`
  width: 100%;
  ${Text} {
    font-size: 1rem;
  }
  ${SettingInput} {
    background: ${(p) => (p.isPublish !== "showGray" ? "#fff" : "#fafafa")};
  }
  ${IconInput} {
    background: ${(p) => (p.isPublish !== "showGray" ? "#fff" : "#fafafa")};
    input {
      background: ${(p) => (p.isPublish !== "showGray" ? "#fff" : "#fafafa")};
    }
  }
`;

export const LinkInput = styled.div`
  width: 100%;
  ${centerMe}
  column-gap: 1rem;
  align-items: stretch;
  @media (max-width: 1005px) {
    flex-direction: column;
    row-gap: 1rem;
    .trash {
      padding: 10px 15px;
      font-size: 1.2rem;
    }
    svg.trash {
      margin-right: 10px;
      padding: 0;
    }
  }
`;

export const SwitchControl = styled.div`
  ${spaceBetween}
  width: auto;
  p {
    font-size: 0.8rem;
    font-weight: 600;
    color: ${colors.textGray};
  }
  > div {
    :first-child {
      ${centerMe}
      p {
        font-size: 1.1rem;
        font-weight: 700;
        color: #f05252;
      }
    }
    :last-child {
      width: 9rem;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }
`;

export const Affiliate = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  textarea {
    width: 100%;
    resize: none;
    min-height: 6rem;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const Preview = styled.div`
  > div {
    padding: 10px;
    background: #fcf8fa;
    border-radius: 10px;
    ${spaceBetween}
    :not(:last-child) {
      margin-bottom: 1rem;
    }
    @media (max-width: 1168px) {
      flex-direction: column;
    }
  }
  img {
    width: 80px;
    height: 80px;
    background: #e2e2e2;
    margin-right: 1rem;
    border-radius: 10px;
  }
  ${UnderLineLink} {
    margin-right: 3rem;
    @media (max-width: 1168px) {
      margin-right: 0;
      font-size: 0.9rem;
    }
  }
`;

export const PreviewContentContainer = styled.div`
  display: flex;
`;

export const PreviewControls = styled.div`
  ${centerMe}
  div {
    ${centerMe}
    column-gap: 10px;
  }
`;

export const PreviewContent = styled.div`
  flex-grow: 1;
  color: #343434;
  p {
    font-size: 1rem;
    line-height: 24px;
  }
  div {
    font-weight: 700;
    display: flex;
    align-items: flex-end;
    column-gap: 1rem;
    p {
      font-size: 1.1rem;
    }
    span {
      color: #6262629c;
      font-size: 0.8rem;
      line-height: 20px;
    }
  }
`;

export const SwitchContainer = styled.div`
  ${centerMe}
  column-gap: 30px;
  margin-top: -15px;
  hr {
    flex-grow: 1;
    height: 2px;
    background: #d6d2d2;
    @media (max-width: 828px) {
      display: none;
    }
  }
  p {
    cursor: pointer;
    width: 130px;
    text-align: center;
    padding: 5px 0;
    position: relative;
    font-weight: 600;
    color: #fff;
    font-size: 1.2rem;
    :last-child {
      color: ${colors.textGray};
    }
  }
  > div {
    background: ${colors.gray};
    ${centerMe}
    border-radius: 50px;
    position: relative;
    height: 53px;
    ::before {
      transition: all 0.2s ease;
      position: absolute;
      top: 4px;
      left: 4px;
      bottom: 4px;
      width: 50%;
      border-radius: 50px;
      background: ${colors.primary};
      content: "";
    }
    &.active {
      p {
        :first-child {
          color: ${colors.textGray};
        }
        :last-child {
          color: #fff;
        }
      }
      ::before {
        left: calc(50% - 4px);
      }
    }
  }
  .disabled {
    background: ${colors.gray};
    ${centerMe}
    border-radius: 50px;
    position: relative;
    height: 53px;
    ::before {
      transition: all 0.2s ease;
      position: absolute;
      top: 4px;
      left: 4px;
      bottom: 4px;
      width: 50%;
      border-radius: 50px;
      background: ${colors.gray};
      content: "";
    }
    p {
      color: ${colors.textGray};
    }
  }
  @media (max-width: 828px) {
    margin: 1rem auto;
  }
`;

export const Status = styled.span`
  color: ${(p) => (p.disabled ? colors.textGray : p.red ? "#ff5050" : "#42ce7f")};
  background: ${(p) => (p.disabled ? colors.gray : p.red ? "#fff0f0" : "#e5f7eb")};
  font-size: 0.9rem;
  font-weight: 700;
  padding: 1px 20px;
  border-radius: 50px;
`;

export const SettingHeaderContainer = styled.div`
  ${flexStart}
  column-gap: 20px;
`;

export const InputSwitchContainer = styled.div`
  border-radius: 50rem;
  height: 30px;
  width: 50px;
  background: #898989;
  cursor: pointer;
  position: relative;
  display: flex;
  overflow: hidden;
  transition: all 0.3s ease;
  div {
    top: 4px;
    left: 4px;
    bottom: 4px;
    position: absolute;
    width: 21px;
    height: 21px;
    border-radius: 50rem;
    background: #fff;
    transition: all 0.2s ease;
  }
  &.active {
    background: ${colors.primary};
    div {
      left: calc(100% - 4px - 21px);
    }
  }
  @media (max-width: 804px) {
    height: 22px;
    width: 38px;
    > div {
      width: 15px;
    }
    .active {
      left: calc(100% - 4px - 15px);
    }
  }
`;

export const Message = styled.div`
  ${spaceBetween}
  padding-right: 3rem;
  ${Text}:first-child {
    color: #000;
  }
  > div {
    max-width: 50rem;
    ${InputSwitchContainer} {
      display: none;
    }
    div {
      ${spaceBetween}
    }
  }
  @media (max-width: 996px) {
    padding: 0;
    > ${InputSwitchContainer} {
      display: none;
    }
    > div {
      max-width: 100%;
      ${InputSwitchContainer} {
        display: block;
      }
    }
  }
`;

export const DropdownContainer = styled.div`
  ${spaceBetween}
  justify-content: space-between !important;
  border: 2px solid ${colors.gray};
  border-radius: 5px;
  padding: 5px 10px;
  column-gap: 25px;
  p {
    font-size: 1.1rem;
    font-weight: 700;
    color: #000;
  }
  svg {
    font-size: 2rem;
  }
`;

export const Stripe = styled.div`
  ${spaceBetween}
  div {
    display: flex;
    column-gap: 1rem;
    align-items: flex-end;
    span {
      color: #aeacad;
      margin: auto 0;
      font-weight: 700;
    }
  }
  svg {
    font-size: 3.5rem;
    line-height: auto;
  }
  button {
    width: 222px;
  }
  @media (max-width: 1058px) {
    flex-direction: column;
  }
`;

export const EditProfileImg = styled.div`
  ${AddButtonContainer} {
    width: 20rem;
    @media (max-width: 1016px) {
      width: 100% !important;
    }
  }
  > div {
    ${spaceBetween}
    column-gap: 5rem;
    margin-top: 2rem;
    div:nth-child(2) {
      width: 100%;
    }
    @media (max-width: 1016px) {
      > button {
        width: 100% !important;
      }
    }
  }

  @media (max-width: 1016px) {
    > div {
      flex-direction: column;
    }
  }
  @media (max-width: 1248px) {
    ${BillingInput} {
      flex-direction: column;
      row-gap: 1rem;
      margin-bottom: 1rem;
    }
  }
`;

export const ImgEditor = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 100%;
  padding-bottom: 4rem;
  img {
    width: 32rem;
    border-radius: 7px;
  }
  input {
    width: 100%;
    background: linear-gradient(
      to right,
      #dea1b6 0%,
      #dea1b6 ${(p) => p.progress}%,
      #fff ${(p) => p.progress}%,
      #fff 100%
    );
    border-radius: 8px;
    height: 3px;
    outline: none;
    transition: background 450ms ease-in;
    -webkit-appearance: none;
    ::-webkit-slider-thumb {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      -webkit-appearance: none;
      cursor: ew-resize;
      background: #fff;
      border: 4px solid ${colors.primary};
    }
  }
`;

export const FileContainer = styled.div`
  ${flexStart}
  column-gap: 8px;
  border-radius: 10px;
  background: #d4362c14;
  padding: 10px;
  border: 1px solid ${colors.primary};
  margin: 1rem 0;
  max-width: 15rem;
  & > svg {
    font-size: 1.4rem;
    color: ${colors.primary};
  }
`;

export const FileInfo = styled.div`
  ${spaceBetween}
  width: 100%;
  svg {
    font-size: 1.3rem;
    color: ${colors.primary};
  }
  span {
    color: ${colors.primary};
    opacity: 0.5;
    font-weight: 500;
  }
  p {
    font-size: 14px;
    color: #000;
    font-weight: 700;
  }
`;

export const ChangePassword = styled.div`
  h4 {
    margin-bottom: 10px;
  }
  @media (max-width: 1058px) {
    input {
      width: 100%;
    }
  }
`;

export const New = styled.div`
  ${centerMe}
  column-gap: 10px;
  color: ${colors.primary};
  opacity: 0.6;
  margin: 1rem 0;
  p {
    color: ${colors.primary};
  }
  hr {
    width: 100%;
  }
`;

export const OldPassword = styled.div`
  ${centerMe}
  column-gap: 2rem;
  label {
    color: ${colors.primary};
  }
  input {
    width: 100%;
  }
  @media (max-width: 1180px) {
    flex-direction: column;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 3rem;
  column-gap: 1rem;
  button {
    min-width: 200px;
  }
  @media (max-width: 495px) {
    row-gap: 1rem;
    flex-direction: column;
  }
`;

export const StoreInputs = styled.div`
  ${flexStart}
  column-gap: 1rem;
  flex-wrap: wrap;
  margin-top: 1rem;
`;

export const BillingInfo = styled.div`
  @media (max-width: 1180px) {
    ${BillingInput} {
      flex-direction: column;
      row-gap: 1rem;
      margin-bottom: 1rem;
    }
  }
`;

export const EditImgButtons = styled.div`
  ${spaceBetween}
  > button {
    width: 16rem;
    text-transform: capitalize;
    font-weight: 700;
    padding: 5px 0.7rem;
    font-size: 1rem;
    color: #f05252;
    border-radius: 7px;
    background: #fff7f7;
    box-sizing: content-box;
    border-radius: 5px;
    cursor: pointer;
    ${centerMe}
    column-gap: 10px;
    transition: all 0.2s ease;
    justify-self: flex-end;
    svg {
      padding: 15px 0 !important;
    }
    :hover {
      background: #fafafa;
    }
    @media (max-width: 495px) {
      width: 100% !important;
    }
  }
`;
