import React, {useEffect} from "react";
import {withTranslation} from "react-i18next";
import Links from "../../components/User/PublicProfile/Links";
import {useParams} from "react-router-dom";
import NotFound from "../NotFound";
import PropTypes from "prop-types";
import UserInfo from "../../components/User/PublicProfile/UserInfo";
import SupportSection from "../../components/User/PublicProfile/SupportSection";
// import Store from "../../components/User/PublicProfile/Store";
import {useGetUserPublicProfileByUsernameQuery} from "../../services/userService";
import Loading from "../../components/General/Loading";
import {enabledEmailSubscribe, enabledLinks, enabledSupportMe} from "../../helpers/userSettingsHelper";
import EmailSubscribeSection from "../../components/User/PublicProfile/EmailSubscribeSection";
import Header from "../../components/Header";
import {useCreateMetricMutation} from "../../services/metricService";

const UserPublicProfile = ({t, i18n}) => {
    let {username} = useParams();


    const {
        data,
        isFetching,
        isError
    } = useGetUserPublicProfileByUsernameQuery(username)
    const user = data

    const [submitMetric] = useCreateMetricMutation()

    useEffect(() => {
        document.title = 'Quickg - '.concat(username);
        if (user !== undefined && user !== null) {
            submitMetric({entity: 'User', entityId: user.id, userAction: 'view', user: user.id})
        }
    }, [submitMetric, user, username])

    // useMemo(() => submitMetric({entity: 'User', entityId: user.id, userAction: 'view', userId: user.id}), [user])

    if (isFetching) {
        return (
            <Loading/>
        )
    }

    if (isError) {
        return (
            <NotFound message={'User is not registered!'}/>
        )
    }

    return (
        <main className='main'>
            <Header/>
            <UserInfo user={data}/>
            {enabledEmailSubscribe(user) ? <EmailSubscribeSection user={data}/> : null}
            {enabledLinks(user) ? <Links userId={data.id} links={data.links}/> : null}
            {enabledSupportMe(user) ? <SupportSection user={data}/> : null}
            {/*<Store/>*/}

            {/*//todo #gs component*/}
            {/*//todo store component*/}
        </main>
    )
}

UserPublicProfile.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
};

export default withTranslation()(UserPublicProfile);
