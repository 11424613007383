import {baseApi} from "./apiService";

export const metricApi = baseApi.injectEndpoints({
        overrideExisting: false,
        endpoints: (builder) => ({
            createMetric: builder.mutation({
                query(body) {
                    return {
                        url: `/metrics`,
                        method: 'POST',
                        body,
                    }
                },
                // invalidatesTags: (result, error, {id}) => [{type: 'UserPublicProfiles', id}, {type: 'CurrentUser'}],
            }),
            getMetrics: builder.query({
                query: (entityType) => `/metrics/${entityType}`,
                // providesTags: (result) => [{type: 'Metrics', id: entityType}],
            }),
            getMetricsCount: builder.query({
                query: (entityType) => `/metrics/count`,
                // providesTags: (result) => [{type: 'Metrics', id: entityType}],
            }),
        }),
    }
)

export const {
    useCreateMetricMutation,
    useGetMetricsQuery,
    useGetMetricsCountQuery,
} = metricApi

