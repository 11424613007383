import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import Header from "../../components/Header";
import {useGetBlogsQuery} from "../../services/blogService";
import Loading from "../../components/General/Loading";
import RenderBlog from "../../components/Blog/RenderBlog";
import DoubleShapeTop from "../../components/General/DoubleShapeTop";
import DoubleShapeBottom from "../../components/General/DoubleShapeBottom";
import NotFound from "../NotFound";
import {useParams} from "react-router-dom";
import Seo from "../../components/Seo";

const ViewBlog = () => {
    let {blogId} = useParams();

    const {blog, isLoading} = useGetBlogsQuery(undefined, {
        selectFromResult: ({data}) => ({
            blog: data?.find((blog) => {
                return blog.id === blogId
            }),
        }),
    })


    if (blog === undefined || isLoading) {
        return <Loading/>
    }

    if (blog === null && !isLoading) {
        return <NotFound/>
    }

    return (
        <Container fluid>
            <Seo
                title={`Quickg - ${blog.title}`}
                description={blog.description}
                ogType={'article'}
            />
            <Row className={'mb-5'}>
                <Header header={blog.title}/>
            </Row>
            <Row>
                <Col className={'mx-auto'} md={8}>
                    <RenderBlog post={blog.post}/>
                </Col>
            </Row>
            <DoubleShapeTop/>
            <DoubleShapeBottom/>
        </Container>
    )
}

export default ViewBlog;

