import {stripeConstants} from "../constants/action-types";
import {stripeService} from "../services/stripeService";
import {errorAlert, successAlert, warningAlert} from "../slicers/alertsSlice";

const onboard = (id) => {
    return (dispatch, getState) => {
        if (getState().authentication.updatingProfile === true) {
            return;
        }
        dispatch(request(id));

        stripeService.onboard(id)
            .then(
                links => {
                    dispatch(success(links));
                    dispatch(successAlert('alert.stripe.onboardCreated'));
                    window.location.assign(links.url)
                },
                error => {
                    if (error.code === 422) {
                        dispatch(warningAlert('alert.invalidForm'));
                    } else {
                        dispatch(errorAlert());
                    }
                    dispatch(failure(error));
                }
            );
    };

    function request(id) {
        return {type: stripeConstants.ONBOARD_REQUEST, id}
    }

    function success(links) {
        return {type: stripeConstants.ONBOARD_SUCCESS, links}
    }

    function failure(error) {
        return {type: stripeConstants.ONBOARD_FAILURE, error}
    }
}

const removePayIntent = () => {
    return (dispatch, getState) => {
        if (getState().payIntent.isFetching === true || getState().payIntent.id === null) {
            return;
        }

        const id = getState().payIntent.supportMeId
        dispatch(request());

        stripeService.removePayIntent(id)
            .then(
                () => {
                    dispatch(success(id));
                },
                error => {
                    console.log(error)
                    if (error.code === 422) {
                        dispatch(warningAlert('alert.invalidForm'));
                    } else {
                        dispatch(errorAlert());
                    }
                    dispatch(failure(error));
                }
            );
    };

    function request() {
        return {type: stripeConstants.REMOVE_PAY_INTENT_REQUEST}
    }

    function success(id) {
        return {type: stripeConstants.REMOVE_PAY_INTENT_SUCCESS, id}
    }

    function failure(error) {
        return {type: stripeConstants.REMOVE_PAY_INTENT_FAILURE, error}
    }
}

const payRequest = () => {
    return (dispatch, getState) => {
        if (getState().payIntent.isPaying === true) {
            return;
        }
        dispatch({type: stripeConstants.PAY_REQUEST});
    };
}

const payModalShow = () => {
    return (dispatch, getState) => {
        if (getState().payIntent.payModalShow === true) {
            return;
        }

        dispatch({type: stripeConstants.PAY_MODAL_SHOW});
    };
}

const payModalHide = () => {
    return (dispatch, getState) => {
        if (getState().payIntent.payModalShow === false) {
            return;
        }

        dispatch({type: stripeConstants.PAY_MODAL_HIDE});

        dispatch(removePayIntent())
    };
}

const paySuccess = () => {
    return (dispatch, getState) => {
        if (getState().payIntent.isPaying === false) {
            return;
        }

        dispatch(successAlert('alert.stripe.donated'));
        dispatch(stripeActions.payModalHide())
        dispatch({type: stripeConstants.PAY_SUCCESS});
    };
}

const payFailure = (message) => {
    return (dispatch, getState) => {
        if (getState().payIntent.isPaying === false) {
            return;
        }
        dispatch({type: stripeConstants.PAY_FAILURE});
        dispatch(warningAlert(message));
    };
}

const unlink = (id) => {
    return (dispatch, getState) => {
        if (getState().authentication.updatingProfile === true) {
            return;
        }
        dispatch(request(id));

        stripeService.unlink(id)
            .then(
                () => {
                    dispatch(success(id));
                    dispatch(successAlert('alert.stripe.unlinked'));
                },
                error => {
                    if (error.code === 422) {
                        dispatch(warningAlert('alert.invalidForm'));
                    } else {
                        dispatch(errorAlert());
                    }
                    dispatch(failure(error));
                }
            );
    };

    function request(id) {
        return {type: stripeConstants.UNLINK_REQUEST, id}
    }

    function success(id) {
        return {type: stripeConstants.UNLINK_SUCCESS, id}
    }

    function failure(error) {
        return {type: stripeConstants.UNLINK_FAILURE, error}
    }
}

export const stripeActions = {
    onboard,
    unlink,
    removePayIntent,
    payRequest,
    paySuccess,
    payFailure,
    payModalShow,
    payModalHide,
};
