export function adultContent(user) {
    return user.profile_settings?.adultContent ?? false
}

export function privateProfile(user) {
    return user.profile_settings?.privateProfile ?? false
}

export function showEmail(user) {
    return user.profile_settings?.showEmail?.enabled ?? false
}

export function hasStripe(user) {
    return (user.billing_info?.stripe_account !== undefined && user.billing_info?.stripe_account !== null) ?? false
}

export function stripeDetailsSubmitted(user) {
    return hasStripe(user) && user.billing_info?.stripe_account?.details_submitted
}

export function enabledStripeCharges(user) {
    return hasStripe(user) && user.billing_info?.stripe_account?.charges_enabled
}

export function enabledStripePayouts(user) {
    return hasStripe(user) && user.billing_info?.stripe_account?.payouts_enabled
}

export function enabledSupportMe(user) {
    // return false
    return (enabledStripeCharges(user) && (user.profile_settings?.supportMe?.enabled ?? false))
}

export function enabledEmailSubscribe(user) {
    return (user.profile_settings?.emailSubscribe?.enabled ?? true)
}

export function hasEmailSubscribeMessage(user) {
    return (user.profile_settings?.emailSubscribe?.message ?? null)
}

export function showRecentSupportMe(user) {
    return (user.profile_settings?.recentSupportMe?.enabled ?? true)
}

export function countOfRecentSupportMe(user) {
    //todo when ready
    return (user.profile_settings?.recentSupportMe?.count ?? 5)
}

export function enabledContactMe(user) {
    return false
}

export function enabledLinks(user) {
    return user.profile_settings?.link?.enabled ?? true
}

export function hasHashtags(user) {
    return !(user.profile_settings?.hashtags?.hashtags.length === 0) && Array.isArray(user.profile_settings?.hashtags?.hashtags)
}


export const supportedLinks = [
    {'type': 'instagram', 'placeholder': 'username', 'link': 'https://instagram.com/', 'formLink': 'instagram.com/'},
    {'type': 'facebook', 'placeholder': 'username', 'link': 'https://facebook.com/', 'formLink': 'facebook.com/'},
    {'type': 'twitter', 'placeholder': 'username', 'link': 'https://twitter.com/', 'formLink': 'twitter.com/'},
    {'type': 'tiktok', 'placeholder': 'username', 'link': 'https://tiktok.com/@', 'formLink': 'tiktok.com/@'},
    {
        'type': 'linkedin',
        'placeholder': 'username',
        'link': 'https://www.linkedin.com/in/',
        'formLink': 'linkedin.com/in/'
    },
]
