import React, {useEffect, useState} from "react";
import {Container, Navbar, Offcanvas} from "react-bootstrap";
import Logo from "./Logo";
import NavMenu from "./NavMenu";
import Profile from './Profile';
import MenuCloseImg from '../../styles/images/menu-close-icon.svg'
import MenuOpenImg from '../../styles/images/menu-toggle-icon.svg'
import {useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import * as ReactGA from "react-ga";
import {isLoggedIn} from "../../slicers/authSlice";
import {useGetCurrentUserQuery} from "../../services/userService";
import {isSuperAdmin} from "../../helpers/userRoleHelper";

const Header = () => {
    const [show, setShow] = useState(false);
    const handleShow = () => {
        if (show) {
            setShow(false)
        } else {
            setShow(true)
        }
    };

    const {pathname} = useLocation();
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth);

    const {
        data,
        isLoading
    } = useGetCurrentUserQuery(undefined, {skip: !isLoggedIn(auth)})

    const hasUser = isLoggedIn(auth) && data !== undefined

    useEffect(() => {
        ReactGA.set({page: pathname})
        ReactGA.pageview(pathname)
    }, [pathname, dispatch]);

    if (isLoading) {
        return (
            <Navbar collapseOnSelect className="header" expand="lg">
                <Container>
                    <Logo show={show}/>
                    <NavMenu hasUser={false} isAdmin={isSuperAdmin(data)}/>
                    <Offcanvas backdropClassName='mobilenav' show={show} placement='top'>
                        <Offcanvas.Body className='mobile-nav-body'>
                            <NavMenu handleShow={handleShow} hasUser={false} isAdmin={false}/>
                        </Offcanvas.Body>
                    </Offcanvas>
                    <button onClick={handleShow} className="toggle-btn">
                        <img src={show ? MenuCloseImg : MenuOpenImg} alt="toggle icon"/>
                    </button>
                </Container>
            </Navbar>
        )
    }

    return (
        <Navbar collapseOnSelect className="header" expand="lg">
            <Container>
                <Logo show={show}/>
                <NavMenu hasUser={hasUser} isAdmin={isSuperAdmin(data)}/>
                <Offcanvas backdropClassName='mobilenav' show={show} placement='top'>
                    <Offcanvas.Body className='mobile-nav-body'>
                        <NavMenu handleShow={handleShow} hasUser={hasUser} isAdmin={isSuperAdmin(data)}/>
                        <Profile handleShow={handleShow} drop='up' hasUser={hasUser} user={data}/>
                    </Offcanvas.Body>
                </Offcanvas>
                <button onClick={handleShow} className="toggle-btn">
                    <img src={show ? MenuCloseImg : MenuOpenImg} alt="toggle icon"/>
                </button>
                <Profile drop='down' hasUser={hasUser} user={data}/>
                {/*{i18n.language === 'en' ?*/}
                {/*    <Nav.Link onClick={() => i18n.changeLanguage('sk')}>*/}
                {/*        SK*/}
                {/*    </Nav.Link>*/}
                {/*    :*/}
                {/*    <Nav.Link onClick={() => i18n.changeLanguage('en')}>*/}
                {/*        EN*/}
                {/*    </Nav.Link>*/}
                {/*}*/}
            </Container>
        </Navbar>
    );
};

export default Header;
