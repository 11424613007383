import React from "react";
import {AddSocialContainer, IconInput,} from "../../../styled-components/S_Setting";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Header from "./Header";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import {userPrivateType} from "../../../types";
import * as ReactGA from "react-ga";
import {Form, InputGroup} from "react-bootstrap";
import {Formik} from "formik";
import {object, string} from "yup";
import {useChangeSocialLinksMutation} from "../../../services/userService";
import {supportedLinks} from "../../../helpers/userSettingsHelper";

const AddSocial = ({t, i18n, user}) => {

    const socialLinks = user.social_links

    const [changeSocialLink, {isLoading}] = useChangeSocialLinksMutation()


    const schema = object({
        ...supportedLinks.reduce((obj, item) => ({...obj, [item.type]: string()}), {})
    });

    return (
        <AddSocialContainer>
            <Header>Add Social Media</Header>
            <Formik
                validationSchema={schema}
                validateOnMount={true}
                onSubmit={(values, {setSubmitting, resetForm}) => {
                    ReactGA.event({
                        category: 'Profile links',
                        action: 'Edit Links'
                    });
                    setSubmitting(true);
                    //todo extract somewhere (api normalization to send null instead of '')
                    changeSocialLink({
                        id: user.id,
                        ...Object.keys(values).reduce(
                            (item, key) => ({
                                ...item,
                                [key]: values[key] === '' ? null : values[key],
                            }),
                            {}
                        )
                    })
                    setSubmitting(false);
                    resetForm({values: values})
                }}
                enableReinitialize={true}
                initialValues={supportedLinks.reduce((obj, item) => ({
                    ...obj,
                    [item.type]: socialLinks?.[item.type] ?? ''
                }), {})}>
                {({
                      handleSubmit,
                      handleChange,
                      handleReset,
                      handleBlur,
                      values,
                      dirty,
                      touched,
                      isValid,
                      isSubmitting,
                      errors,
                      setFieldValue,
                      setFieldTouched,
                  }) => (<Form>
                        {supportedLinks.map((link, index) =>
                            <Form.Group controlId={`socialLinkForm.${link.type}`} key={index}>
                                <IconInput key={`socialLinks.${link.type}`} hasValue={values[link.type] !== ''}>
                                    <div>
                                        <FontAwesomeIcon icon={['fab', link.type]}/>
                                        <InputGroup.Text id={`socialLinks-${link.type}`}
                                                         className={'me-1 pe-0'}>{link.formLink}</InputGroup.Text>
                                        <Form.Control type="text"
                                                      name={[link.type]}
                                                      value={values[link.type]}
                                                      disabled={isLoading}
                                                      onBlur={e => {
                                                          handleBlur(e)
                                                          setTimeout(() => {
                                                              if (dirty && isValid && !isLoading)
                                                                  handleSubmit(e)
                                                          }, 0);
                                                      }}
                                                      onChange={handleChange}
                                                      isValid={touched[link.type] && !errors[link.type]}
                                                      isInvalid={!!errors[link.type]}
                                        />
                                    </div>
                                    {/*<FontAwesomeIcon icon={'trash'}/>*/}
                                </IconInput>
                                <Form.Control.Feedback type="invalid" className={'tooltip text-danger'}>
                                    {errors[link.type]}
                                </Form.Control.Feedback>
                            </Form.Group>
                        )}
                    </Form>
                )}
            </Formik>
            {/*<AddButton>Add new link</AddButton>*/}
        </AddSocialContainer>
    );
}

AddSocial.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
    user: userPrivateType,
};

export default withTranslation()(AddSocial);
