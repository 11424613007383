import React from 'react';
import BgVector1Img from '../../styles/images/bg-vector1.svg'
import BgVectorImg from '../../styles/images/bg-vector.svg'
import PropTypes from "prop-types";

const DoubleShapeBottom = ({half = false}) => {
    return (
        <div className="double-shape-bottom">
            <img src={BgVectorImg} alt=""/>
            {half ? <></> : <img src={BgVector1Img} alt=""/>}
        </div>
    );
}

DoubleShapeBottom.propTypes = {
    // t: PropTypes.func.isRequired,
    // i18n: PropTypes.object.isRequired,
    half: PropTypes.bool,
};

export default DoubleShapeBottom
