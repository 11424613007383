import {Box, Button, Divider, Flex, IconButton, Image, Stack, Tag, TagLabel, Text, VStack,} from "@chakra-ui/react";
import React, {useState} from "react";
import {MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp,} from "react-icons/md";

interface PlanCardProps {
    planName: string;
    perMonthPrice: string;
    perYearPrice: string;
    title: string;
    desc: string;
    btnText: string;
    showBtnOutline: boolean;
    comingSoon: boolean;
    moreInfo: MoreInfoItemProps[];
    isYearly: boolean;
}

const PlanCard: React.FC<PlanCardProps> = (
    {
        planName,
        perMonthPrice,
        perYearPrice,
        title,
        desc,
        btnText,
        showBtnOutline,
        comingSoon,
        moreInfo,
        isYearly,
    }) => {
    const [openMoreOption, setOpenMoreOption] = useState(false);
    return (
        <Stack
            w="full"
            spacing={8}
            minH="400px"
            bg="white"
            position="relative"
            zIndex={10}
            borderRadius="xl"
            boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
            overflow="hidden"
            maxW="360px"
        >
            <VStack
                py={8}
                spacing={0}
                //TODO BOOTSTRAP
                height="160px"
                // height="185px"
                position="relative"
                bg="linear-gradient(150.61deg, #EC8EAF 6.89%, #762DB9 111.46%)"
                clipPath={{base: "ellipse(100% 100% at 50% 0%)"}}
            >
                {comingSoon && (
                    <Box
                        w="full"
                        bg="rgba(255, 255, 255, 0.3)"
                        position="absolute"
                        top={0}
                        left={0}
                    >
                        <Text
                            textAlign="center"
                            fontSize="xs"
                            fontWeight="bold"
                            color="white"
                            py={1}
                            //TODO BOOTSTRAP
                            mb={0}
                        >
                            Coming soon!
                        </Text>
                    </Box>
                )}
                <Text textStyle="h4" color="white" fontWeight="bold"
                    //TODO BOOTSTRAP
                      lineHeight={1}
                      mb={0}
                >
                    {planName}
                </Text>
                <Text textStyle="h3" color="white" fontWeight="bold"
                    //TODO BOOTSTRAP
                      lineHeight={1}
                      mb={0}
                >
                    {isYearly ? perYearPrice : perMonthPrice}
                </Text>
                <Text
                    textStyle="p3"
                    fontWeight="bold"
                    color="rgba(255, 255, 255, 0.43)"
                    //TODO BOOTSTRAP
                    mb={0}
                >
                    {isYearly ? perYearPrice : perMonthPrice} / {isYearly ? 'Year' : 'Month'}
                </Text>
            </VStack>

            <VStack px={4}>
                <Text
                    textAlign="center"
                    textStyle="h6"
                    lineHeight="110%"
                    fontWeight="bold"
                    color="dark.900"
                >
                    {title}
                </Text>
                <Text textAlign="center" textStyle="p2" color="text.300">
                    {desc}
                </Text>
            </VStack>

            {openMoreOption && (
                <Box>
                    {moreInfo?.map((info: any, i) => (
                        <MoreInfoItem key={i} heading={info.heading} items={info.items}/>
                    ))}
                </Box>
            )}

            <Box w="full" px={4}>
                <Button
                    w="full"
                    minW="100px"
                    h="48px"
                    fontSize="md"
                    variant={showBtnOutline ? "outline" : "primary"}
                >
                    {btnText}
                </Button>
            </Box>

            <Flex mb={4} direction="column" px={4} align="center" justify="center">
                <Divider mb={2}/>

                <Flex align="center">
                    <Text
                        //TODO BOOTSTRAP
                        mb={0}
                        mr={3} textStyle="p2" fontWeight="bold" color="text.500">
                        {openMoreOption ? "Less" : "More"} Information
                    </Text>
                    {openMoreOption ? (
                        <IconButton
                            aria-label="up arrow"
                            size="sm"
                            onClick={() => setOpenMoreOption((prev) => !prev)}
                            bg="rgba(217, 217, 217, 0.19)"
                            icon={<MdOutlineKeyboardArrowUp color="#96999A" size={20}/>}
                        />
                    ) : (
                        <IconButton
                            aria-label="down arrow"
                            size="sm"
                            onClick={() => setOpenMoreOption((prev) => !prev)}
                            bg="rgba(217, 217, 217, 0.19)"
                            icon={<MdOutlineKeyboardArrowDown color="#96999A" size={20}/>}
                        />
                    )}
                </Flex>
            </Flex>
        </Stack>
    );
};

export default PlanCard;

type ItemProps = {
    tick: boolean;
    showComingSoon: boolean;
    showFreeTrial: boolean;
    name: string | React.ReactNode;
};

interface MoreInfoItemProps {
    heading: string;
    items: ItemProps[];
}

const MoreInfoItem: React.FC<MoreInfoItemProps> = ({heading, items}) => {
    return (
        <Stack spacing={0} mt="4">
            <Text px={4} textStyle="h6" fontWeight="bold" color="text.400">
                {heading}
            </Text>
            {items.map((item, index) => (
                <Flex
                    key={index}
                    align="center"
                    justify="space-between"
                    py={2}
                    px={4}
                    bg={index % 2 !== 0 ? "white" : "brand.lightest"}
                >
                    <Flex align="center">
                        <Box>
                            <Text
                                //TODO BOOTSTRAP
                                mb={0}
                                fontSize="sm"
                                color="text.400"
                            >
                                {item.name}
                            </Text>
                        </Box>

                        {item.showComingSoon && (
                            <Tag
                                minW="95px"
                                ml={2}
                                bg="rgba(255, 126, 172, 0.19)"
                                borderRadius="full"
                            >
                                <TagLabel
                                    fontWeight="semibold"
                                    fontSize="xs"
                                    color="rgba(188, 54, 101)"
                                >
                                    Coming soon!
                                </TagLabel>
                            </Tag>
                        )}

                        {item.showFreeTrial && (
                            <Tag
                                minW="70px"
                                ml={2}
                                bg="rgba(44, 190, 129, 0.15)"
                                borderRadius="full"
                            >
                                <TagLabel
                                    fontWeight="semibold"
                                    fontSize="xs"
                                    color="rgba(44, 190, 129)"
                                >
                                    Free trial!
                                </TagLabel>
                            </Tag>
                        )}
                    </Flex>

                    <Box ml={1} w="24px">
                        {item.tick ? (
                            <Image src={"/icons/tick_green.svg"} boxSize={"20px"}/>
                        ) : (
                            <Image src={"/icons/times.svg"} boxSize={"20px"}/>
                        )}
                    </Box>
                </Flex>
            ))}
        </Stack>
    );
};
