import React from "react";
import {Navbar} from "react-bootstrap";
import LogoPinkImg from '../../styles/images/logo-pink.svg'
import QuickgLogoImg from '../../styles/images/quickg-logo.svg'
import PropTypes from "prop-types";

const Logo = ({show}) => {
  return <Navbar.Brand href="/"><img src={show ? LogoPinkImg : QuickgLogoImg} alt="Quickg logo"/></Navbar.Brand>;
};

Logo.propTypes = {
  // t: PropTypes.func.isRequired,
  // i18n: PropTypes.object.isRequired,
  show: PropTypes.bool,
};

export default Logo;
