import {
    developmentConstants,
    linkConstants,
    profilePictureConstants,
    stripeConstants,
    supportMeConstants,
    userConstants,
} from "../constants/action-types";
import {combineReducers} from "redux";
import {baseApi} from "../services/apiService";
import authSlice from "../slicers/authSlice";
import alertsSlice from "../slicers/alertsSlice";
import contactOffcanvasSlice from "../slicers/contactOffcanvasSlice";
import reportOffcanvasSlice from "../slicers/reportOffcanvasSlice";

const profilePictureDefaultState = {
    isSending: false,
    isLoading: false,
    isCropping: false,
    isCropped: false,
    originalFile: {file: undefined, url: undefined},
    file: undefined,
    crop: {
        unit: "px",
        width: 300,
        aspect: 1
    },
    completedCrop: null

}
const profilePicture = (state = profilePictureDefaultState, action) => {
    switch (action.type) {
        case profilePictureConstants.LOAD_REQUEST:
            return {
                ...state,
                isLoading: true,
            }
        case profilePictureConstants.LOAD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                originalFile: {file: action.file, url: action.url}
            }
        case profilePictureConstants.LOAD_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case profilePictureConstants.CROP_REQUEST:
            return {
                ...state,
                isCropping: true,
            }
        case profilePictureConstants.CROP_SUCCESS:
            return {
                ...state,
                crop: action.crop,
                isCropping: false,
            }
        case profilePictureConstants.CROP_COMPLETE_REQUEST:
            return {
                ...state,
                crop: action.crop,
                isCropped: true,
            }
        case profilePictureConstants.CROP_COMPLETE_SUCCESS:
            return {
                ...state,
                completedCrop: action.crop,
                isCropped: false,

            }
        case profilePictureConstants.CREATE_REQUEST:
        case profilePictureConstants.DELETE_REQUEST:
            return {
                ...state,
                isSending: true,
            }
        case profilePictureConstants.CREATE_SUCCESS:
        case profilePictureConstants.CREATE_FAILURE:
        case profilePictureConstants.DELETE_SUCCESS:
        case profilePictureConstants.DELETE_FAILURE:
            return {
                ...state,
                isSending: false,
            }
        case profilePictureConstants.CLEAR_SUCCESS:
            return profilePictureDefaultState
        default:
            return state
    }
};

const links = (state = {
    isSending: false,
}, action) => {
    switch (action.type) {
        case linkConstants.CREATE_REQUEST:
        case linkConstants.UPDATE_REQUEST:
        case linkConstants.DELETE_REQUEST:
            return {
                ...state,
                isSending: true,
            }
        case linkConstants.CREATE_SUCCESS:
        case linkConstants.CREATE_FAILURE:
        case linkConstants.UPDATE_FAILURE:
        case linkConstants.UPDATE_SUCCESS:
        case linkConstants.DELETE_SUCCESS:
        case linkConstants.DELETE_FAILURE:
            return {
                ...state,
                isSending: false,
            }
        default:
            return state
    }
};

let token = JSON.parse(localStorage.getItem('user'));
const authenticationInitialState = token ? {
    loggedIn: true,
    token: token.token,
    refreshToken: token.refresh_token,
    gettingCurrent: false,
    updatingProfile: false,
    isChangingPassword: false,
    isDeletingAccount: false,
    user: {},
    formErrors: {},
} : {
    loggedIn: false,
    token: null,
    refreshToken: null,
    gettingCurrent: false,
    updatingProfile: false,
    isChangingPassword: false,
    isDeletingAccount: false,
    user: {},
    formErrors: {},
};

export function authentication(state = authenticationInitialState, action) {
    switch (action.type) {
        case userConstants.LOGIN_REQUEST:
            return {
                ...state,
                loggingIn: true,
                user: action.user
            };
        case userConstants.LOGIN_SUCCESS:
            return {
                ...state,
                loggedIn: true,
                loggingIn: false,
                gettingCurrent: false,
                token: action.response.token,
                refreshToken: action.response.refresh_token,
            };
        case userConstants.LOGIN_FAILURE:
        case userConstants.LOGOUT:
            return authenticationInitialState
        case userConstants.DELETE_REQUEST:
            return {
                ...state,
                isDeletingAccount: true,
            };
        case userConstants.DELETE_SUCCESS:
            return {};
        case userConstants.DELETE_FAILURE:
            return {
                ...state,
                isDeletingAccount: false,
            };
        case userConstants.UPDATE_REQUEST:
        case stripeConstants.ONBOARD_REQUEST:
        case userConstants.UPDATE_SETTINGS_REQUEST:
        case userConstants.UPDATE_BILLING_INFO_REQUEST:
            return {
                ...state,
                updatingProfile: true,
                formErrors: {},
            }
        case userConstants.UPDATE_SUCCESS:
            return {
                ...state,
                updatingProfile: false,
                user: action.user,
                formErrors: {},
            }
        case stripeConstants.ONBOARD_SUCCESS:
            return {
                ...state,
                updatingProfile: false,
            }
        case userConstants.UPDATE_SETTINGS_SUCCESS:
            return {
                ...state,
                updatingProfile: false,
                user: {...state.user, profile_settings: action.profileSettings},
                formErrors: {},
            }
        case userConstants.UPDATE_BILLING_INFO_SUCCESS:
            return {
                ...state,
                updatingProfile: false,
                user: {...state.user, billing_info: action.billingInfo},
                formErrors: {},
            }
        case userConstants.UPDATE_FAILURE:
        case stripeConstants.ONBOARD_FAILURE:
        case userConstants.UPDATE_SETTINGS_FAILURE:
        case userConstants.UPDATE_BILLING_INFO_FAILURE:
            return {
                ...state,
                updatingProfile: false,
                formErrors: action.error,
            }
        case userConstants.CHANGE_PASSWORD_REQUEST:
            return {
                ...state,
                isChangingPassword: true,
            }
        case userConstants.CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                isChangingPassword: false,
            }
        case userConstants.CHANGE_PASSWORD_FAILURE:
            return {
                ...state,
                isChangingPassword: false,
            }
        case userConstants.CLEAR_FORM_ERRORS_SUCCESS:
            return {
                ...state,
                formErrors: {},
            }
        default:
            return state
    }
}

const developmentInitialState = {
    links: []
}

const development = (state = developmentInitialState, action) => {
    switch (action.type) {
        case developmentConstants.SET_PROFILER:
            return {
                ...state,
                links: [...state.links, action.link]
            };
        default:
            return state
    }
}

export function registration(state = {
    isRegistering: false,
    errors: {},
}, action) {
    switch (action.type) {
        case userConstants.REGISTER_REQUEST:
            return {
                ...state,
                errors: {},
                isRegistering: true,
            }
        case userConstants.REGISTER_SUCCESS:
            return {
                ...state,
                errors: {},
                isRegistering: false,
            }
        case userConstants.REGISTER_FAILURE:
            return {
                ...state,
                isRegistering: false,
                errors: action.error,
            }
        default:
            return state
    }
}

const supportMeInitialState = {
    step: 0,
    amount: '',
    userId: null,
    billingInfoId: null,
    email: null,
    billingName: null,
    payIntent: {
        support_me_id: null,
        client_secret: null,
    },
    showOffcanvas: false
}

const supportMe = (state = supportMeInitialState, action) => {
    switch (action.type) {
        case supportMeConstants.SHOW_OFFCANVAS:
            return {
                ...state,
                showOffcanvas: true,
                userId: action.userId,
                billingInfoId: action.billingInfoId,
                amount: action.amount,
            }
        case supportMeConstants.HIDE_OFFCANVAS:
            return {
                ...state,
                showOffcanvas: false,
                billingInfoId: null,
            }
        case supportMeConstants.CHANGE_STEP:
            return {
                ...state,
                step: action.step,
            }
        case supportMeConstants.CREATED_PAY_INTENT:
            return {
                ...state,
                step: 1,
                billingName: action.billingName,
                email: action.email,
                payIntent: {
                    ...action.payIntent
                },
            }
        case supportMeConstants.CLEAR:
            return supportMeInitialState
        default:
            return state
    }
}

const payIntentInitialState = {
    id: null,
    supportMeId: null,
    amount: null,
    payModalShow: false,
    isFetching: false,
    isPaying: false,
    secret: null,
}

const payIntent = (state = payIntentInitialState, action) => {
    switch (action.type) {
        case stripeConstants.CREATE_PAY_INTENT_REQUEST:
        case stripeConstants.REMOVE_PAY_INTENT_REQUEST:
            return {
                ...state,
                isFetching: true,
            }
        case stripeConstants.CREATE_PAY_INTENT_SUCCESS:
            return {
                ...state,
                isFetching: false,
                id: action.id,
                amount: action.amount,
                secret: action.secret,
                supportMeId: action.supportMeId
            }
        case stripeConstants.PAY_MODAL_SHOW:
            return {
                ...state,
                payModalShow: true,
            }
        case stripeConstants.PAY_MODAL_HIDE:
            return {
                ...state,
                payModalShow: false,
            }
        case stripeConstants.CREATE_PAY_INTENT_FAILURE:
        case stripeConstants.REMOVE_PAY_INTENT_FAILURE:
            return {
                ...state,
                isFetching: false,
            }
        case stripeConstants.PAY_REQUEST:
            return {
                ...state,
                isPaying: true,
            }
        case stripeConstants.PAY_SUCCESS:
        case stripeConstants.REMOVE_PAY_INTENT_SUCCESS:
            return payIntentInitialState
        case stripeConstants.PAY_FAILURE:
            return {
                ...state,
                isPaying: false,
            }
        default:
            return state
    }
}

const rootReducer = combineReducers({
    [baseApi.reducerPath]: baseApi.reducer,
    development,
    authentication,
    payIntent,
    registration,
    links,
    profilePicture,
    supportMe,
    auth: authSlice,
    alerts: alertsSlice,
    contactOffcanvas: contactOffcanvasSlice,
    reportOffcanvas: reportOffcanvasSlice,
})

export default rootReducer;
