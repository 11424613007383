// import config from 'config';
import {authHeader} from "../helpers/authHeader";
import {URL} from "../constants/API";
import {handleDelete, handleResponse} from "../helpers/handleResponse";
import {baseApi} from "./apiService";

export const stripeService = {
    onboard,
    unlink,
    removePayIntent,
};
const config = {
    apiUrl: URL.concat('/api')
}


function onboard(id) {
    const requestOptions = {
        method: 'GET',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
    };

    return fetch(`${config.apiUrl}/stripe/onboard/${id}`, requestOptions).then(handleResponse);
}

function unlink(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
    };

    return fetch(`${config.apiUrl}/stripe/accounts/${id}`, requestOptions).then(handleResponse);
}

function removePayIntent(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
    };

    return fetch(`${config.apiUrl}/stripe/pay/${id}/pay_intent`, requestOptions).then(handleDelete);
}

export const stripeApi = baseApi.injectEndpoints({
        overrideExisting: false,
        endpoints: (builder) => ({
            createPayIntent: builder.mutation({
                query(data) {
                    const {id, ...body} = data
                    return {
                        url: `/stripe/pay/${id}/pay_intent`,
                        method: 'POST',
                        body,
                    }
                },
                // invalidatesTags: (result, error, {id}) => [{type: 'Projects', id}],
            }),
        }),
    }
)

export const {
    useCreatePayIntentMutation,
} = stripeApi
